import type { ItemDropResult } from '.';
import type { PageNavCategoryProps, PageNavItemProps } from '..';
import type { DragSourceMonitor } from 'react-dnd';

import { useCallback, useEffect } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

/**
 * Dragged item object containing pertinent info about the category or item
 * being dragged. Additional meta properties are optional and contain info that
 * is specific to the item type, e.g. categories vs items.
 */
export interface ItemDragObject {
  categoryId: PageNavCategoryProps['id'];
  deepestChildLevel?: number;
  dndProviderId: string;
  hasChildren: boolean;
  id: string | undefined;
  meta: Partial<PageNavCategoryProps> | Partial<PageNavItemProps>;
  parentId: PageNavCategoryProps['id'] | PageNavItemProps['id'];
  position: number;
}

/**
 * Custom object passed to the `end` handler whenever a successful drop occurs.
 * Contains all information necessary to perform a move operation on the drag
 * source to the drop target's location.
 */
export interface ItemDragEndResult {
  /**
   * Source item that was dragged and dropped.
   */
  source: ItemDropResult;
  /**
   * Target item where the drop occurred on.
   */
  target: ItemDropResult;
  type: useItemDragProps['type'];
}

/**
 * Properties collected and updated by the drag source monitor whenever a drag
 * begins and ends.
 */
export interface ItemDragCollected {
  isDragging: boolean;
}

export type ItemDragMonitor = DragSourceMonitor<ItemDragObject, ItemDropResult>;

export interface useItemDragProps {
  /**
   * Determines whether dragging is allowed on this item.
   */
  canDrag?: (args: ItemDragMonitor) => boolean;
  /**
   * Registers this element as a drag source.
   */
  elementRef: React.RefObject<HTMLElement>;
  /**
   * Called when dragging ends with a successful drop. A custom result object is
   * passed containing info about the drag source and drop target.
   */
  end: (result: ItemDragEndResult) => void;
  /**
   * Object containing information about the dragging item.
   */
  item: ItemDragObject | (() => ItemDragObject);
  /**
   * Drag type allows only drop targets with matching types to be active.
   */
  type: 'category' | 'item';
}

/**
 * Helper hook that abstracts away some of the common operations we need to
 * perform on both Category and Item drag operations. Mainly, it fires the drag
 * "end" event with a consistent payload that contains necessary information
 * about the dragged item and its new position, parent and/or category.
 */
export default function useItemDrag({
  canDrag: handleCanDrag = () => true,
  elementRef,
  end: onEnd,
  item: dragItem,
  type,
}: useItemDragProps) {
  const handleEnd = useCallback(
    (item: ItemDragObject, monitor: ItemDragMonitor) => {
      const dropped = monitor.getDropResult();
      if (dropped && item.id) {
        // When reordering items, we always want the target position to reflect
        // the final position it will be in. For example, when moving A in [A,
        // B, C, D] to the end, the final position should be 3 to reflect the
        // final state of the list being [B, C, D, A]. To make this true, we
        // need to offset the final target position when dragging an item under
        // the same parent to a higher position to account for the deletion of
        // the dragged item.
        const targetPosition =
          item.parentId === dropped.parentId && dropped.position > item.position
            ? dropped.position - 1
            : dropped.position;

        // Drop result contains "target" information about where we're moving
        // to. Combine this with some "source" info to fulfill a reorder.
        onEnd?.({
          source: {
            categoryId: item.categoryId,
            id: item.id,
            parentId: item.parentId,
            position: item.position,
          },
          target: {
            categoryId: dropped.categoryId,
            id: dropped.id,
            parentId: dropped.parentId,
            position: targetPosition,
          },
          type: monitor.getItemType() as useItemDragProps['type'],
        });
      }
    },
    [onEnd],
  );

  const [collected, connect, preview] = useDrag<ItemDragObject, ItemDropResult, ItemDragCollected>(
    () => ({
      canDrag: handleCanDrag,
      type,
      item: dragItem,
      end: handleEnd,
      collect: monitor => {
        return {
          // NOTE: Be very careful NOT to return additional properties to ensure
          // this hook runs optimally. For context, this hook is rendered by
          // every category and item. When dragging begins and ends, these return
          // values will trigger updates and may cause every category and item
          // to re-render, causing significant lag when dragging begins and
          // ends. To prevent this, we must only collect and return properties
          // that change for the singular category or item being dragged.
          isDragging: monitor.isDragging(),
        };
      },
    }),
    [dragItem, handleCanDrag, handleEnd, type],
  );
  connect(elementRef);

  // Disable native HTML5 drag previews. Even though PageNav uses DndProvider
  // with a touch backend, other DndProvider instances with HTML5 backends will
  // sometimes cause the browser to render HTML5 drag previews. So we explicitly
  // disable it always.
  useEffect(() => {
    preview(getEmptyImage());
  }, [preview]);

  return collected;
}
