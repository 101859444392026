import type { GitSidebarPage } from '@readme/api/src/routes/sidebar/operations/getSidebar';
import type { NextPageLink } from '@readme/backend/models/page/types';

import type { SuperHubGuideReferencePage } from '@core/store';

/**
 * Recursive function to flatten the nested collection of git sidebar pages into
 * a single list with normalization to mongo's "next" page data. Used to
 * translate superhub sidebar data into "next" page data to represent all pages
 * available in the `@ui/WhatsNext` component.
 */
export function flattenGitSidebarPagesToMongo(pages: GitSidebarPage[], categoryTitle: string): NextPageLink[] {
  return pages.reduce<NextPageLink[]>((result, page) => {
    return [
      ...result,
      {
        category: categoryTitle,
        name: page.title,
        type: page.type === 'link' ? 'url' : page.type === 'endpoint' ? 'ref' : 'doc',
        slug: page.slug,
      },
      ...(page.pages?.length ? flattenGitSidebarPagesToMongo(page.pages, categoryTitle) : []),
    ];
  }, []);
}

/**
 * Normalizes the list of mongo "next" pages to git "next" page data. Used to
 * translate data coming out of `@ui/WhatsNext` to our superhub form.
 */
export function normalizeMongoPagesToGit(mongoPages: NextPageLink[]) {
  return mongoPages.reduce<SuperHubGuideReferencePage['content']['next']['pages']>((result, page) => {
    return [
      ...result,
      {
        title: page.name ?? null,
        ...(page.type === 'url'
          ? {
              type: 'link',
              url: page.value ?? '',
            }
          : {
              slug: page.slug ?? '',
              type: page.type === 'ref' ? 'endpoint' : 'basic',
            }),
      },
    ];
  }, []);
}

/**
 * Normalizes the list of git "next" pages to mongo's "next" page data. Used to
 * feed superhub data into the mongo-based `@ui/WhatsNext` component.
 * @todo Do we need git "next" pages to include category titles? If so, request
 * from the API team to add them.
 */
export function normalizeGitPagesToMongo(gitPages: SuperHubGuideReferencePage['content']['next']['pages']) {
  return gitPages.reduce<NextPageLink[]>((result, page) => {
    return [
      ...result,
      {
        category: '',
        name: page.title ?? undefined,
        ...(page.type === 'link'
          ? {
              type: 'url',
              value: page.url,
            }
          : {
              slug: page.slug,
              type: page.type === 'endpoint' ? 'ref' : 'doc',
            }),
      },
    ];
  }, []);
}
