import type { HistoryDiffReadType } from '@readme/api/src/mappings/history/diff/types';

import React, { useContext, useEffect, useRef } from 'react';

import type { VersionContextValue } from '@core/context';
import { VersionContext } from '@core/context';
import useClassy from '@core/hooks/useClassy';
import { useReadmeApiNext } from '@core/hooks/useReadmeApi';

import ErrorState from '@ui/ErrorState';
import Spinner from '@ui/Spinner';

import classes from './index.module.scss';

interface GitHistoryDiffProps {
  className?: string;
  /**
   * Git commit hash to generate diffs against.
   */
  commitHash?: string;
  /**
   * Initial diff content to render, mainly to support SSR when loading this
   * diff route directly.
   */
  initialData?: HistoryDiffReadType['data'];
  /**
   * Slug of the resource to fetch the diff for.
   */
  slug?: string;
}

/**
 * Renders diff content for the provided git commit hash.
 */
function GitHistoryDiff({ className, commitHash, initialData, slug }: GitHistoryDiffProps) {
  const bem = useClassy(classes, 'GitHistoryDiff');
  const { version } = useContext(VersionContext) as VersionContextValue;

  /**
   * Indicates whether we have finished rendering and validating the initial
   * commit hash diff data from our API. We need this to preload our component
   * with initial diff data only the first time it's rendered and never again.
   */
  const isInitializedRef = useRef(false);

  const {
    data = !!initialData && !isInitializedRef.current ? { data: initialData } : undefined,
    error,
    isLoading,
  } = useReadmeApiNext<HistoryDiffReadType>(
    commitHash ? `/versions/${version}/history/diff/${commitHash}?slug=${slug}` : null,
    {
      swr: {
        revalidateIfStale: false,
      },
    },
  );

  useEffect(() => {
    if (!isLoading) {
      // Indicates that we have now validated and loaded initial data.
      isInitializedRef.current = true;
    }
  }, [isLoading]);

  // When no commit hash is yet selected, display nothing.
  if (!commitHash) return null;

  // When error occurs, render an error state.
  if (error && !initialData) {
    return (
      <div className={bem('&', '_error', className)}>
        <ErrorState />
      </div>
    );
  }

  if (!data || (isLoading && data.data !== initialData)) {
    return (
      <div className={bem('&', className, '_loading')}>
        <Spinner size="xxl" />
      </div>
    );
  }

  return (
    <pre
      aria-label={`Diff of the selected commit "${commitHash}"`}
      className={bem('&', className, '_ready')}
      dangerouslySetInnerHTML={{ __html: data?.data.content.html || '' }}
      role="figure"
    />
  );
}

export default GitHistoryDiff;
