import { useContext } from 'react';

import { CollapsedStateContext } from './Context';

export function useCollapsedStateContext() {
  const value = useContext(CollapsedStateContext);
  if (!value) {
    throw new Error('CollapsedStateProvider is missing.');
  }
  return value;
}

export * from './Provider';
