import React from 'react';
import { HTML5Backend } from 'react-dnd-html5-backend';

import type { DragAndDropProviderProps } from '@core/hooks/useDragAndDropContext';
import { DragAndDropProvider } from '@core/hooks/useDragAndDropContext';

import DragDropPreview from './Preview';

export default function DragDropProvider({
  children,
  ...props
}: Omit<DragAndDropProviderProps, 'backend' | 'options'>) {
  return (
    <DragAndDropProvider backend={HTML5Backend} {...props}>
      <DragDropPreview />
      {children}
    </DragAndDropProvider>
  );
}

export * from './ItemDrop';
export * from './ParentDrop';
export * from './Preview';
export * from './useIsDragItemHovered';
export * from './useItemDrag';
export { DragDropPreview };
export { default as ItemDrop } from './ItemDrop';
export { default as ParentDrop } from './ParentDrop';
export { default as useIsDragItemHovered } from './useIsDragItemHovered';
export { default as useItemDrag } from './useItemDrag';
