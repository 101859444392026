import type { HomeRouteProps } from '../..';

import { README_CONFIG, type HubResponseProps } from '@readme/iso';
import React from 'react';
import { Link } from 'react-router-dom';

import classy from '@core/utils/classy';

import APIMethod from '@ui/API/Method';
import SmartLink from '@ui/SmartLink';

import styles from '../style.module.scss';

interface DocsBlockProps extends HubResponseProps<HomeRouteProps> {
  className?: string;
  pageType: string;
}

function DocsBlock({ className, guideCategories, pageType, referenceCategories }: DocsBlockProps) {
  const max = 3;
  const category = pageType === 'Reference' ? referenceCategories : guideCategories;
  const basePath = pageType === 'Reference' ? 'reference' : 'docs';

  if (!category || !category.length) return null;

  return (
    <div className={classy(styles.LandingBlock, styles['LandingBlock-Docs'], className)}>
      {category
        .filter(({ pages }) => !!pages.length)
        .map(({ title: categoryTitle, pages }) => (
          <div key={`LP-DocsBlock:${categoryTitle}`} className="markdown-body">
            <h3>{categoryTitle.replace(README_CONFIG, 'API Reference')}</h3>
            <ul>
              {pages.slice(0, max).map(page => {
                const apiMethod = page.type === 'endpoint' ? page.api_method : undefined;
                const url = page.type === 'link' ? page.link_url || '' : `/${basePath}/${page.slug}`;
                return (
                  <li key={`LP-DocsBlock:${page.slug}`}>
                    <SmartLink
                      href={url}
                      target={page.type === 'link' && page.link_external ? '_blank' : undefined}
                      to={url}
                    >
                      {page.title}
                    </SmartLink>
                    {!!apiMethod && <APIMethod fixedWidth type={apiMethod as Uppercase<typeof apiMethod>} />}
                  </li>
                );
              })}
              {pages.length > max && (
                <li>
                  <Link className={classy(styles['LandingBlock-link_viewAll'])} to={`/${basePath}/${pages[0].slug}`}>
                    View More…
                  </Link>
                </li>
              )}
            </ul>
          </div>
        ))}
    </div>
  );
}

export default DocsBlock;
