import type { HistoryDiffReadType } from '@readme/api/src/mappings/history/diff/types';
import type { GitSidebarCategory } from '@readme/api/src/routes/sidebar/operations/getSidebar';
import type { HubResponseProps } from '@readme/iso';

import React, { useCallback, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import useClassy from '@core/hooks/useClassy';

import type { SuperHubRouteParams } from '@routes/SuperHub/types';

import ErrorState from '@ui/ErrorState';
import Flex from '@ui/Flex';

import SidebarNav from '../Editor/SidebarNav';
import AsidePanel from '../Layout/AsidePanel';

import GitHistoryDiff from './Diff';
import styles from './index.module.scss';
import GitHistoryList from './List';

/**
 * Shape of route specific data for page history diffs.
 */
export interface HistoryRouteProps {
  diffContent?: HistoryDiffReadType['data'];
  sidebar: GitSidebarCategory[];
}

/**
 * Entry point for viewing page history diffs against a specific commit hash.
 */
export default function SuperHubGitHistory({ diffContent: initialDiffContent }: HubResponseProps<HistoryRouteProps>) {
  const bem = useClassy(styles, 'SuperHubGitHistory');
  const { commitHash, section, slug } = useParams<SuperHubRouteParams>();
  const browserRouterHistory = useHistory();

  const handleSelectCommit = useCallback(
    (hash: string) => {
      browserRouterHistory.push(`/compare/${section}/${slug}/${hash}`);
    },
    [browserRouterHistory, section, slug],
  );

  /** History list resource path based on the current route. */
  const resourcePath = useMemo(() => {
    switch (section) {
      case 'docs':
        return 'guides';
      case 'reference':
        return 'reference';
      default:
        return null;
    }
  }, [section]);

  return (
    <>
      <main className={bem()}>
        <Flex className="rm-Container" gap={0} justify="start">
          <SidebarNav className={bem('-sidebar')} />
          <GitHistoryDiff
            className={bem('-diff')}
            commitHash={commitHash}
            initialData={initialDiffContent}
            slug={slug}
          />
        </Flex>
      </main>
      <AsidePanel className={bem('-list')} label="Page history list">
        {resourcePath ? (
          <GitHistoryList
            onSelect={handleSelectCommit}
            resourcePath={resourcePath}
            selectedCommitHash={commitHash}
            slug={slug}
          />
        ) : (
          <ErrorState message="Page history is not yet supported for this section but is coming soon!" />
        )}
      </AsidePanel>
    </>
  );
}
