import type { NextPageLink } from '@readme/backend/models/page/types';

import React, { useMemo } from 'react';

import useUniqueId from '@core/hooks/useUniqueId';
import { useProjectStore, useSuperHubStore } from '@core/store';

import { RHFGroup } from '@ui/RHF';
import WhatsNext from '@ui/WhatsNext';

import { useSuperHubEditorFormContext } from '../../Context';

import { flattenGitSidebarPagesToMongo, normalizeMongoPagesToGit, normalizeGitPagesToMongo } from './util';

export default function WhatsNextEditor() {
  const uid = useUniqueId('WhatsNextEditor');
  const whatsNextLabel = useProjectStore(s => s.data.appearance.whats_next_label);
  const categories = useSuperHubStore(s => s.sidebar.data);
  const { control } = useSuperHubEditorFormContext();

  /**
   * Flat list of all guides AND reference pages to allow users to pick pages
   * from both sections to link next to.
   * TODO: Update this to include API reference pages once that's ready.
   */
  const internalPages = useMemo(
    () =>
      categories.reduce<NextPageLink[]>((result, category) => {
        return [...result, ...flattenGitSidebarPagesToMongo(category.pages, category.title)];
      }, []),
    [categories],
  );

  return (
    <RHFGroup control={control} id={uid('content-next')} name="content.next">
      {({ field }) => (
        <WhatsNext
          description={field.value?.description ?? undefined}
          internalPages={internalPages}
          isEditable
          label={whatsNextLabel ?? undefined}
          onChange={event => {
            field.onChange({
              ...field.value,
              description: event.target.value,
            });
          }}
          onPagesUpdate={nextPages => {
            field.onChange({
              ...field.value,
              pages: normalizeMongoPagesToGit(nextPages),
            });
          }}
          pages={normalizeGitPagesToMongo(field.value?.pages ?? [])}
        />
      )}
    </RHFGroup>
  );
}

export * from './util';
