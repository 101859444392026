import type { PageClientSide } from '@readme/backend/models/page/types';
import type { HTTPMethod, SuggestedEndpoint, SuggestedEndpointsResponse } from '@readme/iso';

import { HTTP_METHOD } from '@readme/iso';
import React, { useContext, useMemo, useState, useCallback } from 'react';

import { ProjectContext, VersionContext } from '@core/context';
import useClassy from '@core/hooks/useClassy';
import { fetcher } from '@core/hooks/useReadmeApi';

import APIMethod from '@ui/API/Method';
import Flex from '@ui/Flex';
import { MenuDivider, MenuHeader, MenuItem } from '@ui/Menu';
import { notify, ErrorNotification } from '@ui/Notification';
import Skeleton from '@ui/Skeleton';
import Spinner from '@ui/Spinner';
import Tooltip from '@ui/Tooltip';

import classes from './style.module.scss';

interface EndpointPickerProps {
  doc: PageClientSide;
  endpoints: SuggestedEndpointsResponse;
  initialEndpoint?: SuggestedEndpoint;
  onSave?: (endpoint: SuggestedEndpoint) => void;
}

interface MenuListProps {
  endpoints: SuggestedEndpoint[];
  isLoading: boolean;
  onClick: (endpoint: SuggestedEndpoint) => void;
  selectedEndpoint: SuggestedEndpoint | null;
}

interface MenuItemInnerProps {
  endpoint: SuggestedEndpoint;
}

const MenuItemInner = ({ endpoint }: MenuItemInnerProps) => {
  const bem = useClassy(classes, 'APIConfigOnboarding');
  return (
    <Flex align="center" className={bem('-endpoint-picker-item-inner')} gap="sm" justify="start">
      <APIMethod fixedWidth type={endpoint.api.method?.toLowerCase() as HTTPMethod} />
      <Tooltip content={endpoint.title} delay={[300, null]}>
        {endpoint.category?.title ? (
          <Flex className={bem('-endpoint-picker-truncate')} gap="3px" inline="true" layout="col">
            <div className={bem('-endpoint-picker-category')}>{endpoint.category?.title}</div>
            <div className={bem('-endpoint-picker-page')}>{endpoint.title}</div>
          </Flex>
        ) : (
          <div className={bem('-endpoint-picker-truncate')}>{endpoint.title}</div>
        )}
      </Tooltip>
    </Flex>
  );
};

const MenuList = ({ endpoints, isLoading, onClick, selectedEndpoint }: MenuListProps) => {
  const bem = useClassy(classes, 'APIConfigOnboarding');

  return (
    <>
      {endpoints.map((endpoint, i) => {
        const isSelected = endpoint === selectedEndpoint;
        const shouldSpin = isSelected && isLoading;
        return (
          <MenuItem key={i} active={!!isSelected && !isLoading} onClick={() => onClick(endpoint)}>
            <MenuItemInner endpoint={endpoint} />
            {!!shouldSpin && <Spinner className={bem('-menu-spinner')} />}
          </MenuItem>
        );
      })}
    </>
  );
};

const EmptyMenuItem = () => {
  const bem = useClassy(classes, 'APIConfigOnboarding');

  return (
    <Flex align="center" className={bem('-empty-menu-item')} gap="sm" justify="start">
      <APIMethod fixedWidth type={HTTP_METHOD.GET} />
      <Skeleton width={'100px'} />
    </Flex>
  );
};

const EmptyMenu = () => {
  return (
    <>
      {new Array(5).fill(null).map((_, index) => (
        <EmptyMenuItem key={index} />
      ))}
    </>
  );
};

const EndpointPicker = ({ doc, endpoints, onSave, initialEndpoint }: EndpointPickerProps) => {
  const [selectedEndpoint, setSelectedEndpoint] = useState<SuggestedEndpoint | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const { version } = useContext(VersionContext);
  const { project } = useContext(ProjectContext);
  const { flags, subdomain } = project;
  const isSuperHub = flags.superHub;

  const hasEndpoints = endpoints?.recommended?.length || endpoints?.all?.length;
  const originalEndpoint = useMemo(() => {
    if (initialEndpoint) {
      setSelectedEndpoint(initialEndpoint);
      return initialEndpoint;
    }

    const docPath = doc?.swagger?.path;
    const docMethod = doc.api?.method;

    if (docPath && docMethod && hasEndpoints) {
      const everyEndpoint = endpoints.recommended.concat(endpoints.all);

      const origEndpoint = everyEndpoint.find(
        endpoint => endpoint.api.url === docPath && endpoint.api.method === docMethod,
      );

      if (origEndpoint) {
        setSelectedEndpoint(origEndpoint);
      }

      return origEndpoint;
    }

    return null;
  }, [initialEndpoint, doc, hasEndpoints, endpoints]);

  const handleSelectEndpoint = useCallback(
    async (endpoint: SuggestedEndpoint) => {
      const docUpdatePath = isSuperHub
        ? `/${subdomain}/api-next/v2/versions/${version}/api_config`
        : `/api/projects/${project.subdomain}/v${version}/realtime/endpoint`;

      let payload = {};

      // Format of payload differs for SuperHub
      if (isSuperHub) {
        payload = { operationId: endpoint.api.operationId, file: endpoint.api.file };
      } else {
        payload = { page: endpoint.page, api: endpoint.api };
      }

      try {
        setIsLoading(true);
        setSelectedEndpoint(endpoint);

        await fetcher(docUpdatePath, {
          method: 'PATCH',
          body: JSON.stringify(payload),
        });

        // Call onSave only after successfully saving endpoint
        onSave?.(endpoint);
      } catch (err) {
        notify(<ErrorNotification>There was an error saving your endpoint.</ErrorNotification>);

        if (originalEndpoint) setSelectedEndpoint(originalEndpoint);
      } finally {
        setIsLoading(false);
      }
    },
    [isSuperHub, subdomain, version, project, originalEndpoint, onSave],
  );

  return hasEndpoints ? (
    <>
      {!!endpoints.recommended.length && (
        <>
          <MenuHeader>Recommended Endpoints</MenuHeader>
          <MenuList
            endpoints={endpoints.recommended}
            isLoading={isLoading}
            onClick={handleSelectEndpoint}
            selectedEndpoint={selectedEndpoint}
          />
          {/* only render the divider if we have both sections */}
          {!!endpoints.all.length && <MenuDivider />}
        </>
      )}
      {!!endpoints.all.length && (
        <>
          <MenuHeader>API Endpoints</MenuHeader>
          <MenuList
            endpoints={endpoints.all}
            isLoading={isLoading}
            onClick={handleSelectEndpoint}
            selectedEndpoint={selectedEndpoint}
          />
        </>
      )}
    </>
  ) : (
    <EmptyMenu />
  );
};

export default EndpointPicker;
