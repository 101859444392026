import React, { useMemo, useRef } from 'react';

import useClassy from '@core/hooks/useClassy';
import useUniqueId from '@core/hooks/useUniqueId';
import {
  isChangelog,
  isCustomPage,
  isGuidesPage,
  isReferencePage,
  isAPIConfigPage,
} from '@core/store/SuperHub/Document/util';

import PublishMenu from '@routes/Dash/Project/DocsEditor/PublishMenu';

import Button from '@ui/Button';
import Flex from '@ui/Flex';
import type Modal from '@ui/Modal';
import { RHFGroup } from '@ui/RHF';

import { useSuperHubEditorFormContext } from '../Context';

import CustomPageMenu from './CustomPageMenu';
import styles from './index.module.scss';
import MetadataModal from './MetadataModal';
import PageMenu from './PageMenu';
import TypeSelector from './TypeSelector';

interface LayoutProps {
  /**
   * Component(s) to render in the main content area of the form layout
   */
  children?: React.ReactNode;
  /**
   * Component(s) to render in a sidebar layout to the right side of `children` using grid layout
   * Primarily used for Realtime Config pages ('api_config' type)
   */
  sidebar?: React.ReactNode;
}

/**
 * Helper component that handles rendering the main content area of the form layout
 * If a sidebar is provided, it will render the main content area in a <div>
 * alongside the sidebar using CSS grid
 */
const LayoutContent = ({ children, sidebar }: LayoutProps) => {
  const bem = useClassy(styles, 'SuperHubEditorFormLayout');

  if (sidebar) {
    return (
      <div className={bem('-content', '-content_with-sidebar')}>
        <div className={bem('-main')}>{children}</div>
        <div className={bem('-sidebar')}>{sidebar}</div>
      </div>
    );
  }

  return <div className={bem('-content')}>{children}</div>;
};

/**
 * Renders the main `<form>` along with its action controls, submit handlers, and common form fields for the editor.
 * Accepts children to render form fields unique to the form's current page type.
 */
function Layout({ children, sidebar }: LayoutProps) {
  const bem = useClassy(styles, 'SuperHubEditorFormLayout');
  const uid = useUniqueId('SuperHubEditorFormLayout');
  const metadataModalRef = useRef<Modal>(null);

  const {
    control,
    formState: { isDirty, isSubmitting, defaultValues },
  } = useSuperHubEditorFormContext();

  const showTypeSelector = useMemo(() => {
    // Hide type selector for Realtime config pages ('api_config' type)
    if (isAPIConfigPage(defaultValues)) return false;

    return isGuidesPage(defaultValues) || isReferencePage(defaultValues) || isChangelog(defaultValues);
  }, [defaultValues]);

  const showPageMenu = useMemo(() => {
    // Hide page menu for Realtime config pages ('api_config' type)
    return !isAPIConfigPage(defaultValues);
  }, [defaultValues]);

  return (
    <Flex align="stretch" className={bem('&')} gap={0} layout="col">
      <LayoutContent sidebar={sidebar}>
        <Flex align="center" className={bem('-controls')} gap="sm" justify="start">
          {!!showTypeSelector && (
            <RHFGroup control={control} id={uid('type')} name="type">
              {({ field }) => <TypeSelector {...field} />}
            </RHFGroup>
          )}

          {isCustomPage(defaultValues) && <CustomPageMenu />}

          <RHFGroup control={control} id={uid('privacy-view')} name="privacy.view">
            {({ field }) => (
              <PublishMenu
                isHidden={field.value !== 'public'}
                updateFormData={(_, isHidden) => {
                  field.onChange(isHidden ? 'anyone_with_link' : 'public');
                }}
              />
            )}
          </RHFGroup>

          {!!showPageMenu && <PageMenu onOpenMetadataModal={() => metadataModalRef.current?.toggle(true)} />}
        </Flex>

        {children}

        <MetadataModal ref={metadataModalRef} />
      </LayoutContent>

      <div className={bem('-actions')}>
        <Button disabled={!isDirty} loading={isSubmitting} type="submit">
          Save
        </Button>
      </div>
    </Flex>
  );
}

export { default as LayoutBody } from './Body';
export { default as LayoutHeader } from './Header';
export { default as LayoutTitle } from './Title';
export default Layout;
