import React from 'react';

import useClassy from '@core/hooks/useClassy';

import APISectionHeader from '@ui/API/SectionHeader';
import Box from '@ui/Box';
import Button from '@ui/Button';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Skeleton from '@ui/Skeleton';

import classes from './style.module.scss';

function Authentication() {
  const bem = useClassy(classes, 'ConfigBlockImage');

  return (
    <Box className={bem('&')} kind="rule">
      <APISectionHeader heading="Credentials" />
      <Box kind="rule">
        <Flex align="stretch" gap="sm" layout="col">
          <Skeleton width="120px" />
          <Box kind="rule">
            <Skeleton width="120px" />
          </Box>
          <hr className={bem('-rule')} />
          <Button fullWidth size="sm">
            Log in for API Keys <Icon name="arrow-up-right" />
          </Button>
        </Flex>
      </Box>
    </Box>
  );
}

export default Authentication;
