import React from 'react';

import useClassy from '@core/hooks/useClassy';

import Button from '@ui/Button';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';

import styles from './index.module.scss';

/** Hash of empty state content based on section. */
const template = {
  changelog: {
    actionLabel: 'New Post',
    description: 'Try creating a new post!',
    icon: 'megaphone',
    title: 'No Changelogs',
  },
  customPage: {
    actionLabel: 'New Page',
    description: 'Create your first custom page!',
    icon: 'custom-pages',
    title: 'No Custom Pages',
  },
  discussion: {
    actionLabel: 'New Question',
    description: "Nobody's asked a question yet. Be the first!",
    icon: 'message-circle',
    title: 'No Discussions',
  },
  guide: {
    actionLabel: 'New Page',
    description: 'Create your first guides page!',
    icon: 'book-open',
    title: 'No Guides',
  },
  history: {
    actionLabel: '',
    description: '',
    icon: 'clock',
    title: 'No History',
  },
  recipe: {
    actionLabel: 'New Recipe',
    description: 'Recipes are task-driven guidance for your API. Try creating a new Recipe for your users!',
    icon: 'recipes',
    title: 'No Recipes',
  },
  reference: {
    actionLabel: 'New API Definition',
    description: 'ReadMe can generate your API reference from a specification file.',
    icon: 'code',
    title: 'No API Endpoints',
  },
};

const urlRegex = /^https?:\/\//i;

export interface EmptyStateProps {
  /**
   * When provided, renders a CTA below the title and description as a button
   * link that navigates users to the absolute URL or relative path provided.
   */
  action?: string;
  className?: string;
  /**
   * When provided, renders a CTA whose clicks are handled by this callback.
   */
  onAction?: React.MouseEventHandler<HTMLAnchorElement | HTMLButtonElement>;
  /** Required field determines the content and copy to display to users. */
  section: keyof typeof template;
}

/**
 * Renders an empty state for our hub sections. An optional CTA is rendered when
 * the `action` prop is provided with a truthy value. Use it to render a
 * clickable link or button that can then be handled with the `onAction` prop.
 * @example
 * ```tsx
 * <EmptyState section='changelog' action='/create/changelog' />
 * ```
 */
export default function EmptyState({ action, className, onAction, section }: EmptyStateProps) {
  const bem = useClassy(styles, 'EmptyState');
  const { title, description, icon, actionLabel } = template[section];

  /** Whether this empty state should be actionable or not. */
  const isActionable = (!!action || !!onAction) && !!actionLabel;
  const isAbsoluteUrl = !!action && urlRegex.test(action);
  const isRelativePath = !!action && !isAbsoluteUrl;

  return (
    <Flex align="center" className={bem('&', className)} justify="center">
      <Flex align="center" className={bem('-content')} gap="md" layout="col">
        <Icon color="blue" name={icon} size={40} />

        <Flex align="center" gap="xs" layout="col">
          <h2 className={bem('-title')}>{title}</h2>
          {!!isActionable && <p className={bem('-description')}>{description}</p>}
        </Flex>

        {!!isActionable && (
          <Flex className={bem('-actions')} gap="sm">
            {section === 'recipe' && (
              <Button
                href="https://docs.readme.com/recipes/publish-all-docs-in-category"
                kind="secondary"
                size="sm"
                target="_blank"
              >
                Try Example
                <Icon name="arrow-up-right" />
              </Button>
            )}

            <Button
              href={isAbsoluteUrl ? action : undefined}
              kind="primary"
              onClick={onAction}
              size="sm"
              to={isRelativePath ? action : undefined}
            >
              <Icon name="plus" />
              {actionLabel}
            </Button>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
}
