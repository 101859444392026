import type { CollectionWrapperType, CursorCollectionWrapperType } from '@readme/api/src/core/mapper';
import type InfiniteLoader from 'react-window-infinite-loader';

import { useCallback, useEffect, useMemo, useState } from 'react';
import { mutate as globalMutate } from 'swr';
import { unstable_serialize as unstableSerialize } from 'swr/infinite';

import { useReadmeApiInfinite } from '@core/hooks/useReadmeApi';
import type { ReadmeInfiniteKeyLoader } from '@core/hooks/useReadmeApi/infinite';

import { flattenPaginatedCollection } from './utils';

export interface UseInfiniteLoaderDataOptions {
  /**
   * Determines the strategy by which paginated collections are fetched. Default
   * is `index` which uses index-based pagination, e.g. `?page=1`. Use `cursor`
   * for pointer-based pagination, e.g. `?from=pointer_sha`.
   */
  paginationType?: 'cursor' | 'index';
  /**
   * Number of items to fetch per page. Defaults to `20`.
   */
  perPage?: number;
  /**
   * Number of rows remaining before triggering data loading on scroll. Defaults
   * to `5`, which means data will start loading when a user scrolls within 5
   * rows of the last loaded data.
   */
  threshold?: number;
}

/**
 * Fetches paginated data from the ReadMe APIv2 and prepares it for use with react-window-infinite-loader
 */
function useInfiniteLoaderData<
  CollectionItem,
  Options extends UseInfiniteLoaderDataOptions = UseInfiniteLoaderDataOptions,
  CollectionData extends
    | CollectionWrapperType<CollectionItem>
    | CursorCollectionWrapperType<CollectionItem> = Options extends { paginationType: 'index' }
    ? CollectionWrapperType<CollectionItem>
    : CursorCollectionWrapperType<CollectionItem>,
>(url: string, options?: Options) {
  const { paginationType = 'index', perPage = 20, threshold = 5 } = options || {};
  const [isReady, setIsReady] = useState(false);
  const [baseUrl, queryString = ''] = url.split('?');

  if (queryString && (queryString.includes('page') || queryString.includes('per_page'))) {
    // eslint-disable-next-line no-console
    console.warn(
      '`page` and `per_page` query parameters are managed by useInfiniteLoaderData hook. Remove them from your url parameter.',
    );
  }

  /** Cursor-based pagination key generator for SWR. */
  const getIndexKey = useCallback<ReadmeInfiniteKeyLoader<CollectionData>>(
    (pageIndex, previousPageData) => {
      // Reached the end of the list
      if (previousPageData?.paging?.next === null) return null;

      const nextSearchParams = new URLSearchParams(
        [`page=${pageIndex + 1}`, `per_page=${perPage}`, queryString].join('&'),
      );
      return `${baseUrl}?${nextSearchParams.toString()}`;
    },
    [baseUrl, perPage, queryString],
  );

  /** Cursor-based pagination key generator for SWR. */
  const getCursorKey = useCallback<ReadmeInfiniteKeyLoader<CollectionData>>(
    (_, previousPageData) => {
      // Reached the end of the list
      if (previousPageData?.paging.next === null) return null;

      const nextUrl = previousPageData?.paging.next || '';
      const [, nextQueryString = `per_page=${perPage}`] = nextUrl.split('?');
      const nextSearchParams = new URLSearchParams([nextQueryString, queryString].join('&'));

      return `${baseUrl}?${nextSearchParams.toString()}`;
    },
    [baseUrl, perPage, queryString],
  );

  const getKey = paginationType === 'cursor' ? getCursorKey : getIndexKey;
  const { data, size, setSize, isLoading, isValidating, mutate } = useReadmeApiInfinite<CollectionData>(getKey);

  // Set the ready state once the first page of data is loaded
  useEffect(() => {
    if (!isLoading && !isReady) {
      setIsReady(true);
    }
  }, [isLoading, isReady]);

  // API data is returned as an array of pages which must be flattened to display in a list
  const items = useMemo(() => (data ? flattenPaginatedCollection<CollectionItem>(data) : []), [data]);

  const hasNextPage = useMemo(() => {
    if (!data) return false;
    const lastPage = data[data.length - 1];
    return lastPage.paging.next !== null;
  }, [data]);

  const isItemLoaded = useCallback(
    (index: number) => !hasNextPage || index < items.length,
    [hasNextPage, items.length],
  );

  const loadMoreItems = isValidating ? () => {} : () => setSize(size + 1);
  // If there are more items to load, add one to the item count to hold a loading indicator
  const itemCount = hasNextPage ? items.length + 1 : items.length;

  const infiniteLoaderProps: Omit<InfiniteLoader['props'], 'children'> = {
    itemCount,
    isItemLoaded,
    loadMoreItems,
    threshold,
  };

  const reset = useCallback(() => {
    // Using the bound mutate function does not seem to work here,
    // so using the global mutate function instead
    globalMutate(unstableSerialize(getKey), undefined, { revalidate: false });
  }, [getKey]);

  return { data, items, isLoading, isReady, infiniteLoaderProps, mutate, reset };
}

export default useInfiniteLoaderData;
