import type { GitSidebarPage } from '@readme/api/src/routes/sidebar/operations/getSidebar';

import React from 'react';

import { SidebarNavPage } from './Page';

export interface SidebarNavPagesProps {
  pages?: GitSidebarPage[];
}

export const SidebarNavPages = React.memo(function SidebarNavPages({ pages }: SidebarNavPagesProps) {
  if (!pages?.length) return null;
  return (
    <>
      {pages.map((page, index) => (
        <SidebarNavPage key={page.uri} index={index} page={page}></SidebarNavPage>
      ))}
    </>
  );
});
