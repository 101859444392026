import type { ReadmeEditor, Props } from '../types';
import type { $TSFixMe } from '@readme/iso';

import React, { useCallback, useEffect, useState, useRef } from 'react';

import useEventListener from '@core/hooks/useEventListener';
import useSentry from '@core/hooks/useSentry';
import dispatch from '@core/utils/nativeDispatch';

import MarkdownEditor from '@ui/MarkdownEditor';
import type Modal from '@ui/Modal';
import Notification, { NotificationToaster, notify } from '@ui/Notification';

import { deserialize } from '../editor';

import ErrorModal from './ErrorModal';
import FallbackMessage from './FallbackMessage';
import HtmlMode from './HtmlMode';
import RawMode from './RawMode';
import classes from './style.module.scss';

type DashEditorContainerProps = JSX.IntrinsicAttributes &
  Omit<Props, 'doc' | 'onChange' | 'onInit'> & {
    doc?: { dirty?: boolean; value: string };
    html?: string;
    htmlMode?: boolean;
    onChange?: (proxyValue: EditorValueProxy) => void;
    onHtmlChange?: (html: string) => void;
    onInit?: (proxyValue: EditorValueProxy) => void;
    onRawChange?: (value: string) => void;
    rawMode?: boolean;
    setRawMode?: (rawMode: boolean) => void;
  };

export type DashEditorProps = DashEditorContainerProps & {
  rawMode: NonNullable<DashEditorContainerProps['rawMode']>;
  sentry: ReturnType<typeof useSentry>;
  setRawMode: NonNullable<DashEditorContainerProps['setRawMode']>;
};
/*
 * The typical way to serialize the MarkdownEditor is to grab the `editor`
 * object during `onInit` or `onChange` and call `editor.toString()`. However,
 * the `DashEditor` also contains raw mode, which doesn't have an editor
 * object. So we provide this proxy value which presents a similar interface.
 */
export interface EditorValueProxy {
  dirty: boolean;
  toJSON: () => string;
  toString: () => string;
}

export const fromParams = (flag: string) =>
  typeof window !== 'undefined' ? !!new URLSearchParams(window.location.search).get(flag) : false;

/*
 * @todo: Remove Angular, then we can remove/refactor the event
 * emitters/listeners.
 */
export const DashEditor = ({
  doc = { value: '' },
  html,
  htmlMode,
  rawMode,
  setRawMode,
  sentry,
  clickableAreaHeight = 4,
  onChange,
  onInit,
  onHtmlChange,
  onRawChange,
  ...props
}: DashEditorProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const modal = useRef<typeof Modal>(null);
  const [md, setMd] = useState(doc.value);
  // @note: editor is mutable, it should always have the most recent changes,
  // so we only have to set it on init
  const [editor, setEditor] = useState<ReadmeEditor | null>(null);
  const [largeDoc, setLargeDoc] = useState(editor?.children.length || false);
  const proxyValue = useRef<EditorValueProxy>({
    toJSON: () => doc.value,
    toString: () => doc.value,
    dirty: false,
  });

  const toggleRawMode = useCallback(() => {
    const raw = !rawMode;

    if (raw && editor) {
      setMd(editor.toString());
    } else if (!raw) {
      try {
        // @note: You might assume that you could rely on `MarkdownEditor`
        // throwing when it does this same call itself, but you'd be wrong. It
        // will do the `deserialize` in a `useEffect`, so the error can't be
        // caught.
        deserialize(md);
      } catch (e) {
        // @ts-ignore
        modal?.current?.toggle?.(true);

        return;
      }
    }

    dispatch({ target: ref.current, value: { type: 'editor:raw', raw } });
    setRawMode(raw);
  }, [editor, md, rawMode, setRawMode]);

  const setDirty = useCallback(() => {
    proxyValue.current.dirty = true;
    dispatch({
      target: ref.current,
      value: { type: 'editor:dirty' },
    });
  }, []);

  const docSizeWarning = () => (
    <Notification className={classes['DashEditor-Notify']} dismissible>
      <span>
        We recommend keeping individual pages below 400 lines. Use smaller pages for an&nbsp;
        <a href="https://docs.readme.com/main/docs/editor-best-practices" rel="noreferrer" target="_blank">
          optimal editing experience
        </a>
        , or continue at your own risk.
      </span>
    </Notification>
  );

  const editorOnChange = useCallback(() => {
    setDirty();
    onChange?.(proxyValue.current);

    if (editor) setLargeDoc(editor.children.length > 400);
  }, [editor, onChange, setDirty]);

  useEffect(() => {
    if (largeDoc) window.requestAnimationFrame(() => notify(docSizeWarning()));
  }, [largeDoc]);

  const rawOnChange = useCallback(
    value => {
      setDirty();
      setMd(value);
      onChange?.(proxyValue.current);
      onRawChange?.(value);
    },
    [onChange, onRawChange, setDirty],
  );

  // We have to update the editor proxy value during:
  // - editor onInit
  // - raw mode onInit
  // - raw mode onChange
  useEffect(() => {
    proxyValue.current.toJSON = () => (rawMode ? md : editor?.toString() || '');
    proxyValue.current.toString = () => proxyValue.current.toJSON();
  }, [editor, md, rawMode]);

  // When we switch between raw and markdown mode, we update the proxyValue.
  // Perhaps it's a little counter-intuitive to call onInit at the same time as
  // onChange, but it means a parent component can subscribe to only
  // `onChange`.
  useEffect(() => {
    onInit?.(proxyValue.current);
    onChange?.(proxyValue.current);
    dispatch({
      target: ref.current,
      value: { type: 'editor:init', getValue: () => proxyValue.current.toString() },
    });
  }, [onChange, onInit]);

  // Reset dirty if we update the doc. We don't want to fully reset the
  // MarkdownEditor, because we want to avoid normalizing in progress work.
  useEffect(() => {
    setMd(doc.value);
    proxyValue.current.dirty = doc.dirty ?? false;
  }, [doc]);

  const onInitEditor = useCallback(
    e => {
      setEditor(e);
      if (editor) setLargeDoc(editor.children.length > 400);
    },
    [editor],
  );

  useEventListener('error', sentry);
  useEventListener('editor:toggleRaw', toggleRawMode);

  return (
    <div ref={ref} className={classes.DashEditor}>
      {!rawMode && !htmlMode ? (
        <FallbackMessage sentry={sentry}>
          <MarkdownEditor
            {...props}
            clickableAreaHeight={clickableAreaHeight}
            doc={md}
            onChange={editorOnChange}
            onInit={onInitEditor}
          />
        </FallbackMessage>
      ) : (
        <>
          {/* DashEditor supports both Raw mode (pages) and HTML mode (custom pages) */}
          {htmlMode ? (
            <HtmlMode onChange={onHtmlChange} value={html} />
          ) : (
            <RawMode onBlur={props.onBlur as $TSFixMe} onChange={rawOnChange} value={md} />
          )}
          <ErrorModal modal={modal as $TSFixMe} />
        </>
      )}
      <NotificationToaster />
    </div>
  );
};

const DashEditorContainer = ({ ...props }: DashEditorContainerProps) => {
  const sentry = useSentry('markdownEditor');
  useEventListener('error', sentry);

  const [rawMode, setRawMode] = useState(fromParams('raw'));

  useEffect(() => {
    if (!window) return;

    const params = new URLSearchParams(window.location.search);
    const url = new URL(window.location.toString());

    if (rawMode) {
      params.set('raw', 'true');
    } else {
      params.delete('raw');
    }

    url.search = params.toString();
    window.history.replaceState('', '', url);
  }, [rawMode]);

  return <DashEditor rawMode={rawMode} sentry={sentry} setRawMode={setRawMode} {...props} />;
};

export default DashEditorContainer;
