import type { ReadChangelogType } from '@readme/api/src/mappings/changelog/types';
import type { CustomPageReadType } from '@readme/api/src/mappings/custompage/types';
import type { ReadGuideType } from '@readme/api/src/mappings/page/guide/types';

/**
 * Empty guide page to be used when creating a new page.
 * @todo (daniels) move all these defaults into the store and have it loaded
 * into the document slice whenever navigating to a `/create` route so our store
 * always contains the source of truth beneath the form UI.
 * @link https://linear.app/readme-io/issue/RM-11423/move-create-document-stubs-into-the-store
 */
export const defaultGuidePage: Partial<ReadGuideType['data']> = {
  allow_crawlers: 'enabled',
  content: {
    body: null,
    excerpt: null,
    link: {
      new_tab: null,
      url: null,
    },
    next: {
      description: null,
      pages: [],
    },
  },
  metadata: {
    description: null,
    image: {
      uri: null,
      url: null,
    },
    keywords: null,
    title: null,
  },
  privacy: { view: 'public' },
  renderable: {
    status: true,
  },
  slug: '',
  state: 'current',
  title: '',
  type: 'basic',
};

/**
 * Empty reference page to be used when creating a new page.
 * @todo Update these defaults once reference page types are available. Until
 * then, we are using the same defaults as guide pages.
 */
export const defaultReferencePage = { ...defaultGuidePage };

/**
 * Empty custom page to be used when creating a new page.
 */
export const defaultCustomPage: Partial<CustomPageReadType['data']> = {
  appearance: {
    fullscreen: false,
  },
  content: {
    body: null,
    type: 'markdown',
  },
  metadata: {
    description: null,
    image: {
      uri: null,
      url: null,
    },
    keywords: null,
    title: null,
  },
  privacy: { view: 'public' },
  slug: '',
  title: '',
};

/**
 * Empty changelog to be used when creating a new post.
 */
export const defaultChangelog: Partial<ReadChangelogType['data']> = {
  created_at: new Date().toISOString(),
  content: {
    body: null,
  },
  metadata: {
    description: null,
    image: {
      uri: null,
      url: null,
    },
    keywords: null,
    title: null,
  },
  privacy: { view: 'public' },
  slug: '',
  title: '',
  type: 'none',
};
