import type { HubResponseProps } from '@readme/iso';

import React from 'react';

import useRoute from '@core/hooks/useRoute';
import RedirectWithHash from '@core/utils/RedirectWithHash';

interface ConnectRouteProps<Props> {
  /**
   * React component is rendered after connecting to the route endpoint. All
   * state fields returned from `useRoute()` is merged and passed along as props
   * to this component.
   */
  next: React.FC<Props>;

  /**
   * Route props are used to initialize the `useRoute()` hook and connect to the
   * route endpoint. These should typically be set to the react app's SSR props.
   */
  props: Props;
}

/**
 * Middleware that connects to the location route endpoint via `useRoute()` and
 * forwards along any returned state fields to the component provided.
 *
 * Our controllers respond to these requests with additional meta and redirect
 * data about the current route. These are critical to revalidate as the user
 * navigates between pages in order to update the window title and follow
 * redirects issued by the backend for the current route.
 * @example
 * ```tsx
 * <ConnectRoute next={Content} props={props} />
 * ```
 */
export default function ConnectRoute<Props extends HubResponseProps>({ next: Next, props }: ConnectRouteProps<Props>) {
  const { redirected, redirectedPath, state } = useRoute(props);

  // When route data returns with a redirect, then follow the redirect path.
  if (redirected && redirectedPath) {
    return <RedirectWithHash to={redirectedPath} />;
  }

  return <Next {...props} {...state} />;
}
