/**
 * Mock file upload handler that acts as the default loader that emits warnings
 * to replace this with your own.
 */
export function mockUpload<T>() {
  // eslint-disable-next-line no-console
  console.warn('[FileUploader] Using mock upload handler');
  return new Promise<T>(resolve => setTimeout(resolve.bind(null, { mocking: 'bird' } as T), 1000));
}

/**
 * Returns the icon name based on the file extension.
 */
export function getIconNameByFile(fileName: string) {
  const extension = fileName.split('.').slice(-1).join('');

  switch (extension) {
    case 'har':
    case 'js':
    case 'json':
      return 'file-js';
    case 'css':
    case 'sass':
    case 'scss':
      return 'file-css';
    case 'pdf':
      return 'file-pdf';
    case 'html':
    case 'xml':
      return 'file-html';
    case 'md':
    case 'mdx':
    case 'txt':
      return 'file-text';
    default:
      return 'file';
  }
}
