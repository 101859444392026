import React, { useContext } from 'react';

import { PLAN_UPGRADE_URL } from '@core/constants/urls';
import type { ConfigContextValue } from '@core/context';
import { BaseUrlContext, ConfigContext } from '@core/context';
import useClassy from '@core/hooks/useClassy';

import Button from '@ui/Button';
import Flex from '@ui/Flex';

import styles from './style.module.scss';

export default function MyRequestsBanner() {
  const bem = useClassy(styles, 'MyRequestsPreview');
  const baseUrl = useContext(BaseUrlContext);
  const { name } = useContext(ConfigContext) as ConfigContextValue;

  const isHub = name === 'Hub';

  return (
    <Flex align="center" className={bem('-banner')} gap="sm" justify="start">
      My Requests shows 24 hours of request history and detailed logs. Add Developer Dashboard for 30 days of history
      and logs.
      <Button
        kind="secondary"
        size="xs"
        {...(isHub
          ? { href: PLAN_UPGRADE_URL }
          : {
              to: `${baseUrl}/plans`,
            })}
      >
        Manage Plan
      </Button>
    </Flex>
  );
}
