import type { HubResponseProps } from '@readme/iso';

import React from 'react';

import useClassy from '@core/hooks/useClassy';

import SectionGate from '@routes/SuperHub/Layout/PlanAccess/SectionGate';
import Recipes from '@routes/Tutorials';

import styles from './index.module.scss';

export default function SuperHubRecipes(props: HubResponseProps) {
  const bem = useClassy(styles, 'SuperHubRecipes');

  return (
    <SectionGate contentClassName={bem('-gate-content')} section="recipes">
      <Recipes {...props} />
    </SectionGate>
  );
}
