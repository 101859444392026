import type { PageHistoryReadType } from '@readme/api/src/mappings/page/history/types';

import produce from 'immer';
import React, { useCallback, useRef, useState } from 'react';

import useClassy from '@core/hooks/useClassy';
import { fetcher } from '@core/hooks/useReadmeApi';
import { useSuperHubStore } from '@core/store';

import { useSuperHubContext } from '@routes/SuperHub/Layout/Context';
import EmptyState from '@routes/SuperHub/Layout/EmptyState';

import Button from '@ui/Button';
import Icon from '@ui/Icon';
import type { InfiniteLoaderListRef } from '@ui/InfiniteLoaderList';
import InfiniteLoaderList from '@ui/InfiniteLoaderList';
import Notification, { notify } from '@ui/Notification';

import classes from './index.module.scss';
import ListItem from './ListItem';

// Height (in px) of each item in the virtual list
const ITEM_HEIGHT = 28;

interface GitHistoryListProps {
  /**
   * Callback when a commit is selected
   */
  onSelect?: (itemHash: string) => void;
  /**
   * The API section segment that is used to construct the endpoint URL to
   * retrieve the git history collection of commits.
   */
  resourcePath: 'custom_blocks' | 'guides' | 'reference';
  /**
   * The hash of the selected commit. Falls back to the first item in the list if not provided.
   */
  selectedCommitHash?: string;
  /**
   * The slug of the API resource
   */
  slug?: string;
}

/**
 * Displays a list of commits for an API resource that is stored in git
 */
function GitHistoryList({
  resourcePath,
  slug,
  onSelect,
  selectedCommitHash: selectedCommitHashProp,
}: GitHistoryListProps) {
  const infiniteLoaderListRef = useRef<InfiniteLoaderListRef<PageHistoryReadType['data']>>(null);
  const bem = useClassy(classes, 'GitHistoryList');
  const { browserRouterHistory } = useSuperHubContext();
  const [routeSection, apiBaseUrl] = useSuperHubStore(store => [store.routeSection, store.apiBaseUrl]);
  const [isReady, setIsReady] = useState(false);
  const [isRestoring, setIsRestoring] = useState(false);

  // The first item in the API response data is the HEAD of the working branch -- ie. the most recent commit
  const [firstCommitHash, setFirstCommitHash] = useState<string | null>(null);

  // Use the selectedCommitHash prop if provided, otherwise fall back to the
  // first item in the list
  const selectedCommitHash = selectedCommitHashProp || firstCommitHash || null;

  const isMostRecentCommitSelected = selectedCommitHash ? selectedCommitHash === firstCommitHash : false;

  const handleRestore = useCallback(async () => {
    setIsRestoring(true);
    try {
      const { commit } = await fetcher<{
        commit: PageHistoryReadType;
      }>(`${apiBaseUrl}/${resourcePath}/${slug}/restore/${selectedCommitHash}`, {
        method: 'PUT',
        body: JSON.stringify({}),
      });

      const newData = produce(infiniteLoaderListRef.current?.data, draft => {
        if (draft?.[0].data) {
          draft[0].data = [commit.data, ...draft[0].data];
        }
      });
      infiniteLoaderListRef.current?.mutate(newData);
      browserRouterHistory.push(`/compare/${routeSection}/${slug}/${commit.data.commit_hash}`);
    } catch (error) {
      notify(
        <Notification kind="error" theme="dark">
          Failed to restore commit.
        </Notification>,
      );
    } finally {
      setIsRestoring(false);
    }
  }, [apiBaseUrl, browserRouterHistory, resourcePath, routeSection, selectedCommitHash, slug]);

  return (
    <section className={bem('&')}>
      <header className={bem('-header')}>
        <h2 className={bem('-title')}>
          <Icon name="clock" />
          History
        </h2>
        <Button
          circular
          ghost
          kind="minimum"
          onClick={() => {
            browserRouterHistory.push(`/update/${routeSection}/${slug}`);
          }}
          size="sm"
        >
          <Icon name="x" />
        </Button>
      </header>
      <InfiniteLoaderList<PageHistoryReadType['data']>
        ref={infiniteLoaderListRef}
        className={bem('-list', isRestoring && '-list_disabled')}
        endpoint={`/${resourcePath}/${slug}/history`}
        itemHeight={ITEM_HEIGHT}
        onLoad={items => {
          if (items[0] && isReady) {
            // Redirect to first commit hash on the list if none exists.
            if (!selectedCommitHash) {
              browserRouterHistory.replace(`${browserRouterHistory.location.pathname}/${items[0].commit_hash}`);
            }
            setFirstCommitHash(items[0].commit_hash);
          }
        }}
        onReady={() => setIsReady(true)}
        paddingBlock={10}
        paginationType="cursor"
        perPage={50}
        renderOnEmpty={<EmptyState section="history" />}
        // This component uses the first commit in the list to set as the initial "selected" item if no `selectedCommitHash`
        // is provided. So we need to clear the list's SWR cache when the component unmounts with the `resetBeforeUnmount`
        // prop, so we're sure to always select the latest commit when the data loads.
        resetBeforeUnmount={true}
        threshold={10}
      >
        {({ item }) => <ListItem isActive={selectedCommitHash === item?.commit_hash} item={item} onSelect={onSelect} />}
      </InfiniteLoaderList>

      <footer className={bem('-footer')}>
        <Button
          circular
          disabled={!isReady || !selectedCommitHash || isMostRecentCommitSelected || isRestoring}
          fullWidth
          loading={isRestoring}
          onClick={() => {
            if (!selectedCommitHash) {
              throw new Error('No commit hash is selected. How can this be?');
            }
            handleRestore();
          }}
          size="sm"
        >
          Restore Version
        </Button>
      </footer>
    </section>
  );
}

export default GitHistoryList;
