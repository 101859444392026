import type { ReadGuideType } from '@readme/api/src/mappings/page/guide/types';

import lodashGet from 'lodash/get';

import { fetcher } from '@core/hooks/useReadmeApi';
import { superHubStore } from '@core/store';
import { safelyStringifyJSON } from '@core/utils/json';

/**
 * Returns an array of dot notation paths to the leaf nodes of an object.
 *
 * @example
 * const obj = {
 *  foo: {
 *   bar: true,
 *   qux: {
 *    quux: true,
 *   },
 * };
 *
 * getPropertyPaths(obj);
 * // ['foo.bar', 'foo.qux.quux']
 */
export function getLeafNodePaths(obj: object, parentKey = ''): string[] {
  return Object.entries(obj).reduce<string[]>((acc, [key, value]) => {
    const currentKey = parentKey ? `${parentKey}.${key}` : key;

    if (typeof value === 'object' && value !== null) {
      return [...acc, ...getLeafNodePaths(value, currentKey)];
    }

    return [...acc, currentKey];
  }, []);
}

/**
 * Makes an API request for a fresh copy of the current document and checks
 * whether it has been changed since the last time we fetched it.
 */
export async function compareDocumentStateToRemote(dirtyFields: Record<string, unknown>) {
  const {
    getApiEndpoint,
    document: { data },
  } = superHubStore.getState();

  try {
    // Fetch the latest version of the document from the API.
    const { data: remoteData } = await fetcher<ReadGuideType>(`${getApiEndpoint(data?.slug)}`, {
      method: 'GET',
    });

    // Short-circuit if the remote document has not changed.
    const hasRemoteChanged = remoteData.updated_at !== data?.updated_at;
    if (!hasRemoteChanged) return 'unchanged';

    // For each dirty field, compare the local default value to the remote value.
    // If they are different, then we know there's a conflict.
    const dirtyFieldPaths = getLeafNodePaths(dirtyFields);
    const hasFieldConflict = dirtyFieldPaths.some(path => {
      const defaultValue = lodashGet(data, path);
      const remoteValue = lodashGet(remoteData, path);

      if (typeof defaultValue === 'object') {
        if (safelyStringifyJSON(defaultValue) !== safelyStringifyJSON(remoteValue)) {
          return true;
        }
      } else if (defaultValue !== remoteValue) {
        return true;
      }

      return false;
    });

    if (hasFieldConflict) return 'changed';
  } catch (error) {
    // If the document no longer exists on the remote, we can assume it has
    // been deleted or it's slug has been renamed.
    if (error.status === 404) return 'not-found';
  }

  return 'unchanged';
}
