import type { APIDefinitionsReadType } from '@readme/api/src/mappings/apis/types';

import React from 'react';

import useClassy from '@core/hooks/useClassy';

import Flex from '@ui/Flex';
import Graphic from '@ui/Graphic';
import Icon from '@ui/Icon';

import classes from './index.module.scss';

const typeMap: Record<APIDefinitionsReadType['type'], string> = {
  openapi: 'OpenAPI Spec',
  postman: 'Postman Collection',
  swagger: 'Swagger Spec',
  unknown: 'Unknown Spec',
} as const;

/**
 * Renders metadata about an API definition, including the type and source of
 * the imported OAS.
 */
const ApiDefinitionType: React.FC<Pick<APIDefinitionsReadType, 'type'>> = ({ type }) => {
  const bem = useClassy(classes, 'ApiDefinitionType');

  return (
    <Flex align="center" className={bem('&')} gap="xs" justify="start">
      {type === 'unknown' ? <Icon name="oas" size="md" /> : <Graphic name={type} size="md" />}
      <span className={bem('-title')}>{typeMap[type]}</span>
    </Flex>
  );
};

export default ApiDefinitionType;
