import React from 'react';
import { useWatch } from 'react-hook-form';

import APIConfigPage from './APIConfigPage';
import BasicPage from './BasicPage';
import Changelog from './Changelog';
import ConnectFormToSuperHubEditorStore from './ConnectFormToSuperHubEditorStore';
import SuperHubEditorFormContext, { useSuperHubEditorFormContext } from './Context';
import CustomPage from './CustomPage';
import EndpointPage from './EndpointPage';
import LinkPage from './LinkPage';
import FormRoot from './Root';

function Content() {
  const { control } = useSuperHubEditorFormContext();
  const pageType = useWatch({ control, name: 'type' });

  switch (pageType) {
    // Guides + reference page types
    case 'basic':
      return <BasicPage />;
    case 'link':
      return <LinkPage />;

    // Reference page types
    case 'endpoint':
      // case 'webhook':
      return <EndpointPage />;
    case 'api_config':
      return <APIConfigPage />;

    // Changelog types
    case 'added':
    case 'deprecated':
    case 'fixed':
    case 'improved':
    case 'none':
    case 'removed':
      return <Changelog />;

    // Custom pages have no type field.
    default:
      return <CustomPage />;
  }
}

export default function SuperHubEditorForm() {
  return (
    <SuperHubEditorFormContext>
      <ConnectFormToSuperHubEditorStore>
        <FormRoot>
          <Content />
        </FormRoot>
      </ConnectFormToSuperHubEditorStore>
    </SuperHubEditorFormContext>
  );
}
