import type { PageHistoryReadType } from '@readme/api/src/mappings/page/history/types';

import { format } from 'date-fns';
import React from 'react';

import useClassy from '@core/hooks/useClassy';

import Timestamp from '@ui/Timestamp';

import classes from './index.module.scss';

interface GitHistoryListItemProps {
  isActive: boolean;
  item: PageHistoryReadType['data'];
  onSelect?: (itemId: string) => void;
}

function GitHistoryListItem({ isActive, item, onSelect }: GitHistoryListItemProps) {
  const bem = useClassy(classes, 'GitHistoryListItem');

  return (
    <div className={bem('&')}>
      <button
        className={bem('-button', isActive && '-button_active')}
        onClick={() => onSelect?.(item.commit_hash)}
        type="button"
      >
        <div className={bem('-createdAt')}>
          <Timestamp formatter={date => format(date, 'MM/dd h:mm aaa')} value={item.created_at} />
        </div>
        <div className={bem('-author')}>{item.author.email}</div>
      </button>
    </div>
  );
}

export default GitHistoryListItem;
