import type { APIDefinitionsReadType } from '@readme/api/src/mappings/apis/types';

import React from 'react';

import useClassy from '@core/hooks/useClassy';
import { useSuperHubStore } from '@core/store';

import Button from '@ui/Button';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';

import styles from './index.module.scss';
import ApiDefinitionItem from './Item';

interface ApiDefinitionListProps {
  className?: string;

  /**
   * Invoked when users click to "add" a new definition.
   */
  onCreate?: () => void;

  /**
   * Invoked when users click to "replace" an existing definition.
   */
  onReplace?: (definition: APIDefinitionsReadType) => void;
}

/**
 * Renders a list of API definitions that have been previously added. Allows
 * users to manage existing API definitions to update or delete them.
 */
function ApiDefinitionList({ className, onCreate, onReplace }: ApiDefinitionListProps) {
  const bem = useClassy(styles, 'ApiDefinitionList');
  const apiDefinitions = useSuperHubStore(s => s.apiDefinitions.data);

  return (
    <Flex align="stretch" className={bem('&', className)} gap={30} justify="center" layout="col">
      <Flex align="center" tag="header">
        <h3 className={bem('-title')}>Your API Definitions</h3>
        <Button onClick={() => onCreate?.()} size="sm">
          <Icon name="plus" />
          Add Definition
        </Button>
      </Flex>

      <Flex align="stretch" className={bem('-list')} gap="sm" layout="col" tag="ul">
        {apiDefinitions?.map(definition => (
          <ApiDefinitionItem key={definition.uri} definition={definition} onReplace={onReplace} />
        ))}
      </Flex>
    </Flex>
  );
}

export default ApiDefinitionList;
