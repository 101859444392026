import type { GitSidebarCategory } from '@readme/api/src/routes/sidebar/operations/getSidebar';

import React, { useCallback, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { useSuperHubStore } from '@core/store';

import type { PageNavCategoryProps } from '@ui/Dash/PageNav';
import PageNav, { PageNavCreateNew, PageNavCategory, PageNavDivider } from '@ui/Dash/PageNav';

import { useDisabledStateContext } from '../DisabledStateContext';

export interface SidebarNavRootProps {
  children?: React.ReactNode;
  className?: string;
}

export const SidebarNavRoot = React.memo(function SidebarNavRoot({ className, children }: SidebarNavRootProps) {
  const history = useHistory();
  const { pathname: currentPath } = useLocation();
  const [categories, createCategory, findSidebarCategoryByTitle, routeSection] = useSuperHubStore(s => [
    s.sidebar.data,
    s.sidebar.createCategory,
    s.sidebar.findSidebarCategoryByTitle,
    s.routeSection,
  ]);
  const [ephemeralCategory, setEphemeralCategory] = useState<Partial<GitSidebarCategory>>();
  const isSidebarDisabled = useDisabledStateContext();

  const handleCategoryEdit = useCallback<NonNullable<PageNavCategoryProps['onEdit']>>(
    ({ label: newTitle, isValid }) => {
      // Exit editing mode WITHOUT submitting if ESC key is pressed or title is
      // an empty string.
      if (!isValid) {
        setEphemeralCategory(undefined);
        return undefined;
      }

      // Validate category name to prevent duplicate titles.
      const isDuplicateTitle = !!findSidebarCategoryByTitle(newTitle);
      if (isDuplicateTitle) {
        return 'Category title is already taken.';
      }

      setEphemeralCategory(undefined);
      createCategory(newTitle);
      return undefined;
    },
    [createCategory, findSidebarCategoryByTitle],
  );

  // Memoize the footer prop to PageNav to prevent unnecessary re-renders.
  const memoFooter = useMemo(() => {
    switch (routeSection) {
      case 'changelog':
      case 'page': {
        const createType = routeSection;
        const createPath = `/create/${routeSection}`;
        return (
          <PageNavCreateNew
            disabled={currentPath === createPath || isSidebarDisabled}
            onClick={() => {
              history.push(createPath);
            }}
            type={createType}
          />
        );
      }
      default:
        return (
          <PageNavCreateNew
            disabled={!!ephemeralCategory || isSidebarDisabled}
            onClick={() => setEphemeralCategory({ title: '' })}
            type="category"
          />
        );
    }
  }, [currentPath, ephemeralCategory, history, isSidebarDisabled, routeSection]);

  // Memoize the children prop to PageNav to prevent unnecessary re-renders.
  const memoContent = useMemo(
    () => (
      <>
        {children}
        {!!ephemeralCategory && (
          <>
            <PageNavCategory
              editable={true}
              label={ephemeralCategory.title ?? ''}
              onEdit={handleCategoryEdit}
              showActions={false}
            />
            <PageNavDivider collapsible={false} />
          </>
        )}
      </>
    ),
    [children, ephemeralCategory, handleCategoryEdit],
  );

  return (
    <PageNav
      categories={categories.map(c => ({
        _id: c.uri,
        title: c.title,
      }))}
      className={className}
      footer={memoFooter}
      type={routeSection === 'reference' ? 'reference' : 'guide'}
    >
      {memoContent}
    </PageNav>
  );
});
