import type { HttpMethods } from 'oas/types';

import React, { useMemo } from 'react';

import useUniqueId from '@core/hooks/useUniqueId';
import { InitializeAPIDesignerStore, useSuperHubStore } from '@core/store';
import { ConnectFormToAPIDesignerStore } from '@core/store/APIDesigner/ConnectFormToAPIDesignerStore';

import APIDesigner from '@ui/APIDesigner';
import { RHFGroup } from '@ui/RHF';

import { useSuperHubEditorFormContext } from '../Context';
import Layout, { LayoutBody, LayoutHeader, LayoutTitle } from '../Layout';
import HeaderInput from '../shared/HeaderInput';
import MarkdownEditor from '../shared/MarkdownEditor';
import WhatsNextEditor from '../shared/WhatsNext';
import useFormSetFocus from '../useFormSetFocus';

import EndpointBanner from './EndpointBanner';

/**
 * Form fields specific to the `endpoint` page type.
 */
export default function EndpointPage() {
  const uid = useUniqueId('SuperHubEditorFormEndpointPage');
  const [isSuperHubDevelopment] = useSuperHubStore(s => [s.isSuperHubDevelopment]);
  const {
    control,
    formState: { defaultValues },
  } = useSuperHubEditorFormContext();
  const apiFormDefaults = defaultValues && 'api' in defaultValues ? defaultValues.api : undefined;

  const apiSchema = useMemo(() => apiFormDefaults?.schema, [apiFormDefaults?.schema]);
  const apiSource = apiFormDefaults?.source as Exclude<NonNullable<typeof apiFormDefaults>['source'], undefined>;
  const initialOperation = useMemo(
    () => ({ path: apiFormDefaults?.path as string, method: apiFormDefaults?.method as HttpMethods }),
    [apiFormDefaults?.method, apiFormDefaults?.path],
  );

  useFormSetFocus('title');

  return (
    <Layout>
      <LayoutHeader>
        <LayoutTitle>
          <RHFGroup control={control} id={uid('title')} name="title" required>
            {({ field }) => <HeaderInput {...field} />}
          </RHFGroup>
        </LayoutTitle>
        <RHFGroup control={control} id={uid('content-excerpt')} name="content.excerpt">
          {({ field }) => <HeaderInput {...field} value={field.value || ''} />}
        </RHFGroup>
      </LayoutHeader>
      <LayoutBody>
        {!isSuperHubDevelopment && <EndpointBanner source={apiSource} />}
        {!!isSuperHubDevelopment && (
          <InitializeAPIDesignerStore oas={apiSchema} operation={initialOperation}>
            <ConnectFormToAPIDesignerStore>
              <APIDesigner />
            </ConnectFormToAPIDesignerStore>
          </InitializeAPIDesignerStore>
        )}
        <MarkdownEditor />
        <WhatsNextEditor />
      </LayoutBody>
    </Layout>
  );
}
