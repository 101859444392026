import React from 'react';

import useUniqueId from '@core/hooks/useUniqueId';

import { RHFGroup } from '@ui/RHF';

import { useSuperHubEditorFormContext } from '../Context';
import Layout, { LayoutBody, LayoutHeader, LayoutTitle } from '../Layout';
import HeaderInput from '../shared/HeaderInput';
import MarkdownEditor from '../shared/MarkdownEditor';
import useFormSetFocus from '../useFormSetFocus';

/**
 * Form fields specific to custom pages.
 */
export default function CustomPage() {
  const uid = useUniqueId('SuperHubEditorFormCustomPage');
  const { control } = useSuperHubEditorFormContext();

  useFormSetFocus('title');

  return (
    <Layout>
      <LayoutHeader>
        <LayoutTitle>
          <RHFGroup control={control} id={uid('title')} name="title" required>
            {({ field }) => <HeaderInput {...field} />}
          </RHFGroup>
        </LayoutTitle>
      </LayoutHeader>
      <LayoutBody>
        <MarkdownEditor />
      </LayoutBody>
    </Layout>
  );
}
