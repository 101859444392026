import React from 'react';
import { useParams } from 'react-router-dom';

import useClassy from '@core/hooks/useClassy';
import useProjectPlan from '@core/hooks/useProjectPlan';
import { useProjectStore } from '@core/store';

import { SectionGateBadge } from '@routes/SuperHub/Layout/PlanAccess/SectionGate';
import type { SuperHubRouteParams } from '@routes/SuperHub/types';

import Flex from '@ui/Flex';
import Segment, { SegmentItem } from '@ui/Segment';

import DisplaySettingsForm from './DisplaySettingsForm';
import classes from './index.module.scss';

const navItems = [
  {
    label: 'Guides',
    section: 'docs',
  },
  {
    label: 'Recipes',
    section: 'recipes',
  },
  {
    label: 'API Reference',
    section: 'reference',
  },
  {
    label: 'Changelog',
    section: 'changelog',
  },
  {
    label: 'Custom Pages',
    section: 'page',
  },
] as const satisfies {
  label: string;
  section: NonNullable<SuperHubRouteParams['section']>;
}[];

interface SectionNavProps {
  className?: string;
}

/**
 * Renders links to navigate between sections while in edit mode.
 */
function SectionNav({ className }: SectionNavProps) {
  const bem = useClassy(classes, 'SectionNav');
  const projectNavLinks = useProjectStore(s => s.data.appearance.navigation.links);
  const { planFeatures } = useProjectPlan();
  const hasDisplaySettingsAccess = planFeatures.usability;

  const { section: routeSection } = useParams<SuperHubRouteParams>();
  const selectedIndex = navItems.findIndex(({ section }) => section === routeSection);

  return (
    <Flex align="center" className={bem('&', className)} justify="center" tag="nav">
      <Segment circular ghost kind="primary" selectedIndex={selectedIndex} size="sm">
        {navItems.map(({ label, section }, index) => {
          const navlink = projectNavLinks[section === 'docs' ? 'guides' : section];
          const isActive = selectedIndex === index;
          const isCustomPages = section === 'page';
          const isEnabled = isCustomPages || navlink?.visibility === 'enabled';

          return (
            <SegmentItem
              key={section}
              afterSlot={
                !isCustomPages &&
                !!hasDisplaySettingsAccess && <DisplaySettingsForm isActive={isActive} section={section} />
              }
              className={bem('-item')}
              to={`/update/${section}`}
            >
              <SectionGateBadge isEnabled={isEnabled} section={section} />
              <Flex align="center" gap={0}>
                {navlink?.alias || label}
              </Flex>
            </SegmentItem>
          );
        })}
      </Segment>
    </Flex>
  );
}

export default SectionNav;
