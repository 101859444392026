import type { TypeIconClasses } from '.';
import type { ReadChangelogType } from '@readme/api/src/mappings/changelog/types';

import { mdast, md } from '@readme/markdown';
import React from 'react';
import { Link } from 'react-router-dom';

import { useProjectStore } from '@core/store';

import DateLine from '@ui/DateLine';
import RDMD from '@ui/RDMD';

import classes from './style.module.scss';

function useExcerptedRDMD(body: string) {
  try {
    const node = mdast(body);
    node.children = [node.children[0]];
    return md(node);
  } catch (e) {
    return '';
  }
}

interface ChangelogExcerptProps {
  changelog: ReadChangelogType['data'];
  exactDate: boolean;
  typeIconClasses: TypeIconClasses;
}

export default function ChangelogExcerpt({ changelog, exactDate, typeIconClasses }: ChangelogExcerptProps) {
  const showAuthor = useProjectStore(s => !!s.data.appearance.changelog.show_author);
  const excerpt = useExcerptedRDMD(changelog.content.body || '');

  return (
    <div className={`${classes.ChangelogPost} ${classes.ChangelogPostExcerpt}`}>
      {!!changelog.type && (
        <div className={classes.ChangelogPost_type}>
          {!!typeIconClasses[changelog.type] && (
            <span className={`${typeIconClasses[changelog.type]} ${classes.ChangelogIcon}`} />
          )}
          {changelog.type}
        </div>
      )}
      <h1 className={`${classes.ChangelogPost_title} ${classes.ChangelogPost_titleexcerpt}`}>
        <Link
          to={{
            pathname: `/changelog/${changelog.slug}`,
            state: { fromChangelogList: true },
          }}
        >
          {changelog.title}
        </Link>
      </h1>
      <DateLine
        className={`${classes.ChangelogPost_date} ${classes.ChangelogPost_noicon}`}
        icon={exactDate ? '' : 'icon-clock'}
        suffix={showAuthor && changelog.author.name ? `by ${changelog.author.name}` : undefined}
        time={changelog.created_at}
        {...(exactDate ? { fnsFormat: 'PPP' } : {})}
      />
      <RDMD className={classes.ChangelogPost_text} Tag="section">
        {excerpt}
      </RDMD>
    </div>
  );
}
