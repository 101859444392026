export default function nativeDispatch({ target, ...event }, callback, value) {
  const detail = value || ('value' in event && event.value) || target.value;
  const e = new CustomEvent('react', {
    bubbles: true,
    detail,
  });

  /* istanbul ignore else */
  if (callback && typeof callback === 'function') callback(detail, { target, ...event });

  return target.dispatchEvent(e);
}
