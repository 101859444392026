import React, { forwardRef, useContext } from 'react';

import classes from './index.module.scss';

import { InfiniteLoaderListContext } from '.';

/**
 * The inner element to be used by the react-window VirtualList
 * that adds padding to the top and bottom of the list.
 * @link https://github.com/bvaughn/react-window?tab=readme-ov-file#can-i-add-padding-to-the-top-and-bottom-of-a-list
 */
const PaddedInnerElement = forwardRef(function InnerElement(
  {
    style,
    ...rest
  }: {
    [key: string]: unknown;
    style: React.CSSProperties;
  },
  ref: React.Ref<HTMLUListElement>,
) {
  const { paddingBlock } = useContext(InfiniteLoaderListContext);

  return (
    <ul
      ref={ref}
      className={classes.PaddedInnerElement}
      style={{
        ...style,
        height: `calc(${style.height}px + ${paddingBlock * 2}px)`,
      }}
      {...rest}
    />
  );
});

export default PaddedInnerElement;
