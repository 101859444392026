import type { APIDefinitionsReadType } from '@readme/api/src/mappings/apis/types';

import React from 'react';

import useClassy from '@core/hooks/useClassy';

import Icon from '@ui/Icon';

import classes from './index.module.scss';

const ApiDefinitionStatusBadge: React.FC<{ status: APIDefinitionsReadType['upload']['status'] }> = ({ status }) => {
  const bem = useClassy(classes, 'ApiDefinitionStatusBadge');

  if (['pending', 'pending_update'].includes(status)) {
    return null;
  }

  const isErrored = ['failed', 'failed_update'].includes(status);
  return (
    <div className={bem('&', isErrored && '_error')}>
      {isErrored ? (
        <>
          <Icon name="x" /> Error
        </>
      ) : (
        <Icon name="check" />
      )}
    </div>
  );
};

export default ApiDefinitionStatusBadge;
