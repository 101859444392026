import type { PageNavProps } from '..';
import type { PageNavItemProps } from '../Item';

import React, { forwardRef, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import useClassy from '@core/hooks/useClassy';
import useEnvInfo from '@core/hooks/useEnvInfo';

import type { DropdownRef } from '@ui/Dropdown';
import Dropdown from '@ui/Dropdown';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Menu, { MenuItem, MenuDivider } from '@ui/Menu';
import Timestamp from '@ui/Timestamp';

import { PageNavCategoryContext, type PageNavCategoryProps } from '../Category';
import { PageNavCategoriesContext, PageNavContext } from '../Context';
import Tooltip from '../Tooltip';

import styles from './index.module.scss';

/**
 * Unique action identifier being performed.
 */
export type PageNavMetaControlsAction = 'add' | 'delete' | 'duplicate' | 'move-toggle' | 'move' | 'rename';

export type PageNavMetaControlsActionData = {
  icon: string;
  label: string;
  name: PageNavMetaControlsAction;
} | null;

/**
 * Configuration of menu actions based on the specified type.
 */
const actions: Record<NonNullable<PageNavMetaControlsProps['type']>, PageNavMetaControlsActionData[]> = {
  category: [
    { icon: 'edit-2', name: 'rename', label: 'Rename' },
    { icon: 'corner-up-left', name: 'move-toggle', label: 'Move to' },
    null,
    { icon: 'trash', name: 'delete', label: 'Delete' },
  ],
  item: [
    { icon: 'copy', name: 'duplicate', label: 'Duplicate' },
    { icon: 'corner-up-right', name: 'move', label: 'Move to' },
    null,
    { icon: 'trash', name: 'delete', label: 'Delete' },
  ],
};

export interface PageNavMetaControlsProps {
  className?: string;

  /**
   * Allows additional configuration of meta action states. For example, you can
   * use this to disable certain actions or provide additional descriptions for
   * these actions. Expects an object hash of action names to configurators.
   */
  configureActions?: Partial<
    Record<
      PageNavMetaControlsAction,
      {
        description?: string;
        disabled?: boolean;
        hidden?: boolean;
      }
    >
  >;

  contextMenuRef?: React.RefObject<HTMLElement>;

  /**
   * Optional timestamp to display in the meta actions dropdown. We prefer modified if it exists.
   */
  created?: PageNavCategoryProps['created'];

  /**
   * Hidden means these actions are removed from screen readers and unfocusable.
   */
  hidden?: boolean;

  /**
   * Optional timestamp to display in the meta actions dropdown.
   */
  modified?: PageNavItemProps['modified'];

  onAction?: (action: PageNavMetaControlsAction, category?: NonNullable<PageNavProps['categories']>[number]) => void;
  onBlur?: React.FocusEventHandler<HTMLButtonElement>;
  onFocus?: React.FocusEventHandler<HTMLButtonElement>;
  type: 'category' | 'item';

  /**
   * Visible means they are simply hidden from view but are still accessible to
   * screen readers and focusable.
   */
  visible?: boolean;
}

/**
 * Renders additional controls that can be performed for categories or pages.
 */
function PageNavMetaControls(
  {
    className,
    configureActions,
    contextMenuRef,
    created,
    hidden,
    modified,
    onAction,
    onBlur,
    onFocus,
    type,
    visible,
  }: PageNavMetaControlsProps,
  ref: React.Ref<HTMLElement>,
) {
  const { isClient } = useEnvInfo();
  const bem = useClassy(styles, 'PageNavMetaControls');
  const { type: navType } = useContext(PageNavContext);
  const categories = useContext(PageNavCategoriesContext);
  const { id: categoryId } = useContext(PageNavCategoryContext);

  const menuDropdownRef = useRef<DropdownRef>(null);
  const [showMenu, setShowMenu] = useState(false);

  // Filtered list of categories that are valid "move" targets.
  const targetCategories = useMemo(() => {
    if (!categories || type !== 'item') return [];
    if (!categoryId) return categories;
    return categories.filter(c => categoryId !== c._id);
  }, [categories, categoryId, type]);

  // Make "showMenu" our source of truth for dropdown's open state.
  useEffect(() => {
    menuDropdownRef.current?.toggle(showMenu);
  }, [showMenu]);

  // Let users close an opened menu by hitting "Escape".
  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setShowMenu(false);
      }
    };

    if (showMenu) {
      document.addEventListener('keydown', handleEscape);
    } else {
      document.removeEventListener('keydown', handleEscape);
    }
    return () => document.removeEventListener('keydown', handleEscape);
  }, [showMenu]);

  // Enables right-click interactions to open the actions menu.
  useEffect(() => {
    const handleContextMouseUp = (e: MouseEvent | PointerEvent) => {
      e.preventDefault();

      const isRightClick = e.type === 'mouseup' && e.button === 2;
      const isCtrlClick = e.type === 'mouseup' && e.button === 0 && e.ctrlKey;
      if (!isRightClick && !isCtrlClick) return () => {};

      // We update state in an async thread to ensure the Dropdown's click
      // handlers can run its course *before* the menu is toggled on. Otherwise,
      // the Dropdown handlers will immediately close the menu after it opens.
      const showTimeoutId = setTimeout(() => {
        setShowMenu(true);
      }, 0);

      return () => clearTimeout(showTimeoutId);
    };
    const el = contextMenuRef?.current;

    el?.addEventListener('mouseup', handleContextMouseUp);
    el?.addEventListener('contextmenu', handleContextMouseUp);
    return () => {
      el?.removeEventListener('mouseup', handleContextMouseUp);
      el?.removeEventListener('contextmenu', handleContextMouseUp);
    };
  }, [contextMenuRef]);

  const handleAction = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>, category?) => {
      e.preventDefault();
      e.stopPropagation();

      const name = e.currentTarget.name as PageNavMetaControlsAction;
      if (category) {
        onAction?.(name, category);
      } else {
        onAction?.(name);
      }
      setShowMenu(false);
    },
    [onAction],
  );

  return (
    <Flex
      ref={ref}
      className={bem('&', className, (visible || showMenu) && '_visible')}
      data-testid="page-nav-meta-controls"
      gap="0"
      hidden={hidden}
    >
      <Tooltip content="More">
        <button
          aria-label="More"
          className={bem('-control')}
          name="more"
          onBlur={onBlur}
          onClick={() => setShowMenu(true)}
          onFocus={onFocus}
        >
          <Icon name="more-vertical" size="md" title="" />
        </button>
      </Tooltip>
      <Dropdown
        ref={menuDropdownRef}
        appendTo={isClient ? document.body : undefined}
        justify="start"
        lazyMountContent
        onBlur={() => setShowMenu(false)}
        open={showMenu}
        sticky
      >
        {/* Despite the fact that we're triggering the menu programmatically,
         * this element has to be visible for the dropdown to attach to it.
         */}
        <span />
        <Menu>
          {actions[type].map(action =>
            action
              ? !configureActions?.[action.name]?.hidden && (
                  <MenuItem
                    key={action.name}
                    aria-label={configureActions?.[action.name]?.description}
                    className={bem('-menu-control')}
                    disabled={
                      configureActions?.[action.name]?.disabled || (action.name === 'move' && !targetCategories.length)
                    }
                    icon={<Icon name={action.icon} title={action.label} />}
                    name={action.name}
                    onClick={handleAction}
                    TagName="button"
                  >
                    {/* Intentionally rendering non-singleton tooltips here */}
                    <Tooltip
                      arrow={false}
                      content={configureActions?.[action.name]?.description || action.label}
                      delay={[800, 200]}
                      disabled={!configureActions?.[action.name]?.description}
                      offset={[0, 5]}
                    >
                      <span>
                        {action.name === 'move-toggle'
                          ? `${action.label} ${navType === 'guide' ? 'API Reference' : 'Guides'}`
                          : action.label}
                      </span>
                    </Tooltip>
                    {action.name === 'move' && !!targetCategories.length && (
                      <Menu>
                        {targetCategories.map(category => (
                          <MenuItem
                            key={category._id}
                            className={bem('-menu-control', '-menu-control_category-target')}
                            disabled={!!categoryId && categoryId === category._id}
                            name={action.name}
                            onClick={e => handleAction(e, category)}
                            TagName="button"
                          >
                            {category.title}
                          </MenuItem>
                        ))}
                      </Menu>
                    )}
                  </MenuItem>
                )
              : null,
          )}
          {(!!modified || !!created) && (
            <>
              <MenuDivider />
              <MenuItem
                description={
                  <span className={bem('-timestamp')}>
                    {modified ? 'Updated ' : 'Created '}
                    <Timestamp value={new Date((modified as Date) || (created as Date))} />
                  </span>
                }
                focusable={false}
              />
            </>
          )}
        </Menu>
      </Dropdown>
      {!configureActions?.add?.hidden && (
        <Tooltip content={configureActions?.add?.description || 'Add'}>
          <button
            aria-label={configureActions?.add?.description || 'Add'}
            className={bem('-control')}
            disabled={configureActions?.add?.disabled}
            name="add"
            onBlur={onBlur}
            onClick={handleAction}
            onFocus={onFocus}
          >
            <Icon name="plus" size="md" title="" />
          </button>
        </Tooltip>
      )}
    </Flex>
  );
}

export default React.memo(forwardRef(PageNavMetaControls));
