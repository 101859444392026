import React from 'react';

import useClassy from '@core/hooks/useClassy';

import type { ButtonProps } from '@ui/Button';
import Button from '@ui/Button';
import Icon from '@ui/Icon';

import styles from './index.module.scss';

export interface PageNavCreateNewProps
  extends Omit<
    ButtonProps,
    | 'bem'
    | 'circular'
    | 'dropdown'
    | 'fullWidth'
    | 'ghost'
    | 'href'
    | 'is'
    | 'kind'
    | 'link'
    | 'outline'
    | 'size'
    | 'text'
    | 'to'
    | 'type'
  > {
  className?: string;
  /**
   * Page type that is to be created from this CTA. Default is `category`.
   */
  type?: 'category' | 'changelog' | 'page';
}

/**
 * Hash of button details segmented by `type`.
 */
const details = {
  category: {
    icon: 'folder-plus',
    text: 'New Category',
  },
  changelog: {
    icon: 'plus',
    text: 'New Post',
  },
  page: {
    icon: 'file-plus',
    text: 'New Page',
  },
} as const;

/**
 * Renders a single button to create a new element (category or page).
 * Defaults to category.
 */
const PageNavCreateNew: React.FC<PageNavCreateNewProps> = ({ className, type = 'category', ...props }) => {
  const bem = useClassy(styles, 'PageNavCreateNew');
  const { icon, text } = details[type];

  return (
    <Button {...props} className={bem('&', className)} fullWidth kind="secondary" size="sm" text>
      <Icon name={icon} size="md" title="" />
      {text}
    </Button>
  );
};

export default React.memo(PageNavCreateNew);
