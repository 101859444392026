import type { GitSidebarCategory } from '@readme/api/src/routes/sidebar/operations/getSidebar';

import React from 'react';

import { PageNavDivider } from '@ui/Dash/PageNav';

import { SidebarNavCategory } from './Category';

export interface SidebarNavCategoriesProps {
  categories?: GitSidebarCategory[];
}

export const SidebarNavCategories = React.memo(function SidebarNavCategories({
  categories,
}: SidebarNavCategoriesProps) {
  if (!categories?.length) return null;
  return (
    <>
      {categories.map((category, index) => (
        <React.Fragment key={category.uri}>
          <SidebarNavCategory category={category} index={index} />
          <PageNavDivider collapsible={index < categories.length - 1} />
        </React.Fragment>
      ))}
    </>
  );
});
