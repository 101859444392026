import React from 'react';

import { useAPIDesignerStore } from '@core/store';

import OperationEditor from '@ui/APIDesigner/OperationEditor';
import ParameterGroup from '@ui/APIDesigner/ParameterGroup';
import RequestBodyEditor from '@ui/APIDesigner/RequestBodyEditor';
import Flex from '@ui/Flex';

import classes from './style.module.scss';

const APIDesigner = () => {
  const [oas, operation, setOas, setOperation] = useAPIDesignerStore(s => [
    s.oas,
    s.operation,
    s.setOas,
    s.setOperation,
  ]);

  if (!oas || !operation) {
    return null;
  }

  return (
    <Flex align="stretch" gap="md" layout="col">
      <Flex align="stretch" className={classes.APIDesigner} gap="md" layout="col" tag="section">
        <Flex align="stretch" gap="0" justify="start" layout="col">
          <OperationEditor
            currentOperation={operation}
            oasJSON={oas}
            setCurrentOperation={setOperation}
            setOasJSON={setOas}
          />
          <ParameterGroup currentOperation={operation} in="path" oasJSON={oas} setOasJSON={setOas} />
          <ParameterGroup currentOperation={operation} in="query" oasJSON={oas} setOasJSON={setOas} />
          <ParameterGroup currentOperation={operation} in="header" oasJSON={oas} setOasJSON={setOas} />
          <RequestBodyEditor currentOperation={operation} oasJSON={oas} setOasJSON={setOas} />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default APIDesigner;
