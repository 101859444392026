import type { ReadAdminCollectionType } from '@readme/api/src/mappings/project/admins/types';
import type { ForwardedRef } from 'react';

import React, { forwardRef, useMemo } from 'react';

import useReadmeApi from '@core/hooks/useReadmeApi';
import { useProjectStore } from '@core/store';

import type { SelectProps } from '@ui/Select';
import Select from '@ui/Select';

interface SelectAdminProps extends SelectProps {
  isActive: boolean;
}

/**
 * Renders a select form field with a list of project admins.
 */
function SelectAdmin({ isActive, ...props }: SelectAdminProps, ref: ForwardedRef<HTMLSelectElement>) {
  const subdomain = useProjectStore(s => s.data.subdomain);

  const apiUrl = isActive ? `/${subdomain}/api-next/v2/projects/me/admins` : null;
  const { data, isLoading } = useReadmeApi<ReadAdminCollectionType>(apiUrl, {
    swr: {
      revalidateOnFocus: false,
      shouldRetryOnError: true,
    },
  });

  const admins = useMemo(() => data?.data || [], [data?.data]);

  const options = useMemo(
    () => [
      { label: '--', value: '' },
      ...admins.map(admin => ({
        label: admin.name,
        value: admin.id,
      })),
    ],
    [admins],
  );

  return <Select {...props} ref={ref} disabled={!!isLoading && !admins.length} options={options} />;
}

export default React.memo(forwardRef(SelectAdmin));
