import type { PageNavItemProps } from '.';
import type { HTTPMethod } from '@readme/iso';

import React from 'react';

import useClassy from '@core/hooks/useClassy';

import APIMethod from '@ui/API/Method';

import styles from './EndpointBadge.module.scss';

export interface EndpointBadgeProps {
  className?: string;
  endpoint: PageNavItemProps['endpoint'];
}

function PageNavItemEndpointBadge({ className, endpoint }: EndpointBadgeProps) {
  const bem = useClassy(styles, 'PageNavItemEndpointBadge');

  return endpoint ? <APIMethod className={bem('&', className)} fixedWidth type={endpoint as HTTPMethod} /> : null;
}

export default React.memo(PageNavItemEndpointBadge);
