import type { BoundConfirmationDialogProps, ConfirmationDialogRef } from '.';

import React, { useCallback, useRef, useState } from 'react';

import ConfirmationDialog from '.';

interface ConfirmOptions extends BoundConfirmationDialogProps {
  onConfirmAction?: () => Promise<void>;
  open?: boolean;
}

/**
 * Hook that provides a function to open a confirmation dialog and await the user's response,
 * as well as the component needed to render the dialog.
 */
export default function useConfirmationDialog() {
  const dialogRef = useRef<ConfirmationDialogRef>(null);
  const confirmResolverRef = useRef<(value: PromiseLike<boolean> | boolean) => void>();
  const confirmActionRef = useRef<() => Promise<void>>();
  const [confirmOptionsProps, setConfirmOptionsProps] = useState<BoundConfirmationDialogProps>({});

  // Function that opens the confirmation dialog and returns a promise that resolves to the user's response.
  const confirm = useCallback((options?: ConfirmOptions) => {
    // Skip opening the dialog if it is already open.
    if (dialogRef.current?.isOpen) {
      return Promise.resolve(false);
    }

    const { onConfirmAction, ...props } = options || {};
    // Store the onConfirmAction callback a in ref so it can be
    // bound to the ConfirmationDialog's onConfirm callback.
    confirmActionRef.current = onConfirmAction;

    // Update the dialog props and open the modal.
    setConfirmOptionsProps({ ...props, initialOpen: true });

    return new Promise<boolean>(resolve => {
      // Store the resolver in a ref so that it can be called from the ConfirmationDialog's
      // onCancel and onConfirm callbacks.
      confirmResolverRef.current = resolve;
    });
  }, []);

  // A memoized version of the ConfirmationDialog component that has the confirmResolver and
  // onConfirmAction bound to the ConfirmationDialog's onConfirm callback
  const MemoizedConfirmationDialog = useCallback(
    (instanceProps: BoundConfirmationDialogProps) => {
      return (
        <ConfirmationDialog
          ref={dialogRef}
          onCancel={async () => {
            confirmResolverRef.current?.(false);
          }}
          onConfirm={async () => {
            await confirmActionRef.current?.();
            confirmResolverRef.current?.(true);
          }}
          {...instanceProps}
          {...confirmOptionsProps}
        />
      );
    },
    [confirmOptionsProps],
  );

  return {
    confirm,
    ConfirmationDialog: MemoizedConfirmationDialog,
  };
}
