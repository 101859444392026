import type { SuperHubRouteParams } from '../types';

import React from 'react';
import { useParams } from 'react-router-dom';

import EmptyChangelog from '../Changelog/Empty';
import EmptyCustomPage from '../CustomPage/Empty';
import EmptyGuide from '../Doc/Empty';
import EmptyReference from '../Reference/Empty';

export default function EmptyEditor() {
  const { section } = useParams<SuperHubRouteParams>();

  switch (section) {
    case 'changelog':
      return <EmptyChangelog />;
    case 'docs':
      return <EmptyGuide />;
    case 'page':
      return <EmptyCustomPage />;
    case 'reference':
      return <EmptyReference />;
    default:
      return null;
  }
}
