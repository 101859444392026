import React from 'react';

import type { TooltipProps } from '@ui/Tooltip';
import Tooltip from '@ui/Tooltip';

/**
 * Wrapper around our `<Tooltip>` component with specific options to ensure all
 * tooltips within `PageNav` have a consistent UI and interaction. All
 * subcomponents of `PageNav` should import and use this instead.
 *
 * ⚠️  This is NOT meant to be used by anywhere outside of `PageNav`.
 *
 * @example
 * import Tooltip from '../Tooltip';
 *
 * function PageNavSubComponent() {
 *   return (
 *     <Tooltip content='Useful info'>
 *       <span>Info</span>
 *     </Tooltip>
 *   )
 * }
 */
export default React.memo(function PageNavTooltip(props: TooltipProps) {
  return <Tooltip {...props} arrow={false} delay={[800, 200]} offset={[0, 5]} />;
});
