import type { HomeRouteProps } from '../..';
import type { ProjectContextValue } from '../../../../../core/context/types';

import { type HubResponseProps } from '@readme/iso';
import pluralize from 'pluralize';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { ProjectContext } from '@core/context';
import useFeatureOptions from '@core/hooks/useFeatureOptions';
import classy from '@core/utils/classy';

import styles from '../style.module.scss';

interface LinksBlockProps extends HubResponseProps<HomeRouteProps> {
  className?: string;
}

function LinksBlock({ changelogs, className, discuss, guideCategories }: LinksBlockProps) {
  const {
    project: { modules },
  } = useContext(ProjectContext) as ProjectContextValue;
  const features = useFeatureOptions();

  const lists = {
    docs: guideCategories,
    changelog: changelogs,
    discuss,
  };

  const nullcases = {
    discuss: (
      <>
        No {pluralize.singular(features.discuss.toLowerCase())} posts found.{' '}
        <Link to="/discuss-new">
          <b>Ask a question</b>
        </Link>
        .
      </>
    ),
  };

  return (
    <div className={classy(styles.LandingBlock, styles['LandingBlock-Links'], className)}>
      {(Object.keys(lists) as unknown as (keyof typeof lists)[]).map(section => {
        if (!modules[section]) return null;

        const max = 4;
        const feat = features?.[section] || section;
        const list = lists[section];

        return (
          <div key={`LP-LinksBlock:${section}`} className="markdown-body">
            <h3>{feat}</h3>
            <ul>
              {!list.length ? (
                nullcases[section] || `No ${pluralize.plural(feat.toLowerCase())} found.`
              ) : (
                <>
                  {list.slice(0, max).map(item => {
                    const slug = 'pages' in item ? item.pages[0]?.slug || '' : 'slug' in item ? item.slug : item._id;
                    const path = `/${section}/${slug}`;
                    return (
                      <li key={path}>
                        <Link to={path}>{item.title}</Link>
                      </li>
                    );
                  })}
                  <li>
                    <Link className={classy(styles['LandingBlock-link_viewAll'])} to={`/${section}`}>
                      View All…
                    </Link>
                  </li>
                </>
              )}
            </ul>
          </div>
        );
      })}
    </div>
  );
}

export default LinksBlock;
