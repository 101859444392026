import type { ChangelogRouteProps } from './Changelog';
import type { CustomPageRouteProps } from './CustomPage';
import type { DocRouteProps } from './Doc';
import type { HistoryRouteProps } from './GitHistory';
import type { HomeRouteProps } from './Home';
import type { ReferenceRouteProps } from './Reference';
import type { HubResponseProps } from '@readme/iso';

import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { InitializeSuperHubSidebar, useSuperHubStore } from '@core/store';
import ScrollTop from '@core/utils/ScrollTop';

import PageNotFound from '@routes/PageNotFound';

import SuperHubChangelog from './Changelog';
import SuperHubCustomPage from './CustomPage';
import SuperHubDiscussions from './Discussions';
import SuperHubDoc from './Doc';
import SuperHubEditor from './Editor';
import SuperHubGitHistory from './GitHistory';
import SuperHubHome from './Home';
import SuperHubRecipes from './Recipes';
import SuperHubReference from './Reference';
import { SuperHubRoutePaths } from './types';

type RoutePropsType = HubResponseProps<
  ChangelogRouteProps & CustomPageRouteProps & DocRouteProps & HistoryRouteProps & HomeRouteProps & ReferenceRouteProps
>;

/**
 * Top-level browser router for SuperHub.
 */
export default function SuperHubRoutes(props: RoutePropsType) {
  const isSuperHubAdmin = useSuperHubStore(s => s.isSuperHubAdmin);
  const { sidebar: initialSidebar } = props;

  return (
    <InitializeSuperHubSidebar sidebar={initialSidebar}>
      <Switch>
        {/* View routes */}
        <Route exact path="/">
          <SuperHubHome {...props} />
        </Route>
        <Route path={SuperHubRoutePaths.viewGuidePage}>
          <SuperHubDoc {...props} />
        </Route>
        <Route path={SuperHubRoutePaths.viewReferencePage}>
          <SuperHubReference {...props} />
        </Route>
        <Route path={SuperHubRoutePaths.viewCustomPage}>
          <SuperHubCustomPage {...props} />
        </Route>
        <Route path={SuperHubRoutePaths.viewChangelog}>
          <SuperHubChangelog {...props} />
        </Route>
        <Route path={SuperHubRoutePaths.viewRecipes}>
          <SuperHubRecipes {...props} />
        </Route>
        <Route path={SuperHubRoutePaths.viewDiscussions}>
          <SuperHubDiscussions {...props} />
        </Route>

        {!!isSuperHubAdmin && (
          <Switch>
            {/* Create & Edit routes */}
            <Route
              path={[
                SuperHubRoutePaths.editPage,
                SuperHubRoutePaths.createGuidePage,
                SuperHubRoutePaths.createReferencePage,
                SuperHubRoutePaths.createCustomPage,
                SuperHubRoutePaths.createChangelog,
              ]}
            >
              <ScrollTop smooth />
              <SuperHubEditor {...props} />
            </Route>

            {/* Compare page history commit diffs */}
            <Route path={SuperHubRoutePaths.comparePage}>
              <SuperHubGitHistory {...props} />
            </Route>
          </Switch>
        )}

        <Route>
          <PageNotFound {...props} />
        </Route>
      </Switch>
    </InitializeSuperHubSidebar>
  );
}
