import type { TypeIconClasses } from '.';
import type { ReadChangelogCollectionType } from '@readme/api/src/mappings/changelog/types';

import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { useReadmeApiNext } from '@core/hooks/useReadmeApi';
import { useProjectStore } from '@core/store';

import PaginationControls from '@ui/PaginationControls';

import EmptyChangelog from './Empty';
import ChangelogExcerpt from './Excerpt';
import ChangelogPost from './Post';
import classes from './style.module.scss';

interface ChangelogListProps {
  initialCollection?: ReadChangelogCollectionType;
  typeIconClasses: TypeIconClasses;
}

export default function ChangelogList({ initialCollection, typeIconClasses }: ChangelogListProps) {
  const [layoutExpanded, showExactDate] = useProjectStore(s => [
    s.data.appearance.changelog.layout === 'continuous',
    !!s.data.appearance.changelog.show_exact_date,
  ]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pageParam = searchParams.get('page') || 1;

  /**
   * Contains previously fetched collection data so we can overlay our loading
   * state on top of existing data instead of a blank screen.
   * @todo Replace this with SWR option "keepPreviousData" when we upgrade SWR
   * to a newer version that includes this feature.
   * @link https://swr.vercel.app/docs/advanced/understanding#return-previous-data-for-better-ux
   */
  const previousCollection = useRef<ReadChangelogCollectionType | null>(initialCollection || null);

  const { data: collection = previousCollection.current, isLoading: isCollectionLoading } =
    useReadmeApiNext<ReadChangelogCollectionType>(`/changelogs?page=${pageParam}`, {
      swr: { revalidateOnFocus: false },
    });
  const isLoading = isCollectionLoading && collection !== initialCollection;

  useEffect(() => {
    previousCollection.current = collection;
  }, [collection]);

  const { data: changelogs = [], page = 1, per_page: perPage = 10, total = 0 } = collection || {};
  const totalPages = Math.ceil(total / perPage);
  const prevPage = page > 1 ? { queryParam: `page=${page - 1}` } : undefined;
  const nextPage = page < totalPages ? { queryParam: `page=${page + 1}` } : undefined;

  if (!isLoading && !changelogs.length) {
    return <EmptyChangelog />;
  }

  return (
    <main className="rm-Changelog" id="content">
      <div className="rm-Container rm-ContainerNarrow">
        <div className={isLoading ? 'rm-Changelog_loading' : ''}>
          {layoutExpanded ? (
            <>
              {changelogs.map(changelog => (
                <ChangelogPost
                  key={changelog.slug}
                  changelog={changelog}
                  className={classes['ChangelogPage_list-post']}
                  isList
                  typeIconClasses={typeIconClasses}
                />
              ))}
            </>
          ) : (
            <>
              {changelogs.map(changelog => (
                <ChangelogExcerpt
                  key={changelog.slug}
                  changelog={changelog}
                  exactDate={showExactDate}
                  typeIconClasses={typeIconClasses}
                />
              ))}
            </>
          )}
          {totalPages > 1 && (
            <div className={classes.ChangelogList_pagination}>
              <PaginationControls
                meta={`${page} of ${totalPages}`}
                next={nextPage}
                pageType="changelog"
                prev={prevPage}
              />
            </div>
          )}
        </div>
      </div>
    </main>
  );
}
