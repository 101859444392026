import { useEffect, useRef, useState } from 'react';

const TIMEOUT = 300;

/**
 * Used to delay setting an `isLoading` state to `true` by a short period of time to prevent UI flicker.
 */
export default function useDebouncedLoadingState(isLoading: boolean) {
  const [debouncedIsLoading, setDebouncedIsLoading] = useState(isLoading);
  const timer = useRef<number>();

  useEffect(() => {
    if (isLoading) {
      // If `isSaving` is true, set a timeout to set `setDebouncedIsLoading` to true after a short delay.
      timer.current = window.setTimeout(() => {
        setDebouncedIsLoading(true);
      }, TIMEOUT);
    } else {
      // If `isLoading` is false, immediately set `setDebouncedIsLoading` to false and clear the timeout.
      setDebouncedIsLoading(false);
      if (timer.current) {
        window.clearTimeout(timer.current);
      }
    }

    return () => {
      if (timer.current) {
        window.clearTimeout(timer.current);
      }
    };
  }, [isLoading]);

  return debouncedIsLoading;
}
