import React from 'react';

import useClassy from '@core/hooks/useClassy';

import Icon from '@ui/Icon';

import Tooltip from '../Tooltip';

import styles from './ErrorBadge.module.scss';

interface ErrorBadgeProps {
  className?: string;
  tooltip?: string;
}

/**
 * Renders an error badge for the sidebar page with an optional `tooltip`. Use
 * this to indicate that a page contains some error and is not renderable.
 */
function PageNavItemErrorBadge({ className, tooltip }: ErrorBadgeProps) {
  const bem = useClassy(styles, 'ErrorBadge');

  return tooltip ? (
    <Tooltip content={tooltip}>
      <Icon className={bem('&', className)} name="alert-triangle" />
    </Tooltip>
  ) : (
    <Icon className={bem('&', className)} name="alert-triangle" />
  );
}

export default PageNavItemErrorBadge;
