import PropTypes from 'prop-types';
import React from 'react';

import classes from './style.module.scss';

class EditorErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    const { sentry } = this.props;

    sentry(error, errorInfo);
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    return hasError ? (
      <div className={classes.ErrorBoundary} data-testid="error-boundary">
        <img alt="Owlbert Ice Cream" src="/public/img/dash/owlbert-ice-cream.png" />
        <h1>Uh oh, something went wrong!</h1>
        <p>Don&apos;t worry, your content is safe.</p>
        <p>Try refreshing the page, or switch to the raw mode editor.</p>
      </div>
    ) : (
      children
    );
  }
}

EditorErrorBoundary.propTypes = {
  sentry: PropTypes.func.isRequired,
};

export default EditorErrorBoundary;
