import React, { useEffect } from 'react';
import { useWatch } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import useClassy from '@core/hooks/useClassy';
import useUniqueId from '@core/hooks/useUniqueId';
import { useSuperHubStore } from '@core/store';
import { isCustomPage, isGuidesPage, isReferencePage } from '@core/store/SuperHub/Document/util';

import { useSuperHubContext } from '@routes/SuperHub/Layout/Context';
import type { SuperHubRouteParams } from '@routes/SuperHub/types';

import Button from '@ui/Button';
import Dropdown from '@ui/Dropdown';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Menu, { MenuDivider, MenuItem } from '@ui/Menu';
import { RHFGroup } from '@ui/RHF';
import Toggle from '@ui/Toggle';

import { useSuperHubEditorFormContext } from '../../Context';

import styles from './index.module.scss';

/**
 * Overflow menu for the SuperHub editor that allows users to access
 * additional actions like page history, metadata, and raw mode.
 */
function PageMenu({ onOpenMetadataModal }: { onOpenMetadataModal: () => void }) {
  const uid = useUniqueId('SuperHubEditorForm');
  const bem = useClassy(styles, 'PageMenu');

  const { slug } = useParams<SuperHubRouteParams>();
  const { browserRouterHistory } = useSuperHubContext();
  const [isRawMode, routeSection, updateRawMode, isCreateNewPage, isRenderable] = useSuperHubStore(s => [
    s.editor.isRawMode,
    s.routeSection,
    s.editor.updateRawMode,
    s.editor.isCreateNewPage,
    (s.document.getGuidesPageData() || s.document.getReferencePageData())?.renderable?.status ?? true,
  ]);
  const {
    control,
    formState: { defaultValues },
  } = useSuperHubEditorFormContext();

  const pageType = useWatch({ control, name: 'type' });
  const isLinkPage = pageType === 'link';

  // For custom pages, keep track of when content type is HTML mode so we can
  // disable raw mode when HTML mode is enabled.
  const isHtmlMode = useWatch({ control, name: 'content.type' }) === 'html';

  // Ensure raw mode and HTML mode are never enabled at the same time.
  useEffect(() => {
    if (isHtmlMode && isRawMode) {
      updateRawMode(false);
    }
  }, [isHtmlMode, isRawMode, updateRawMode]);

  return (
    <>
      <Dropdown justify="start" sticky trigger="click">
        <Button aria-label="Page Menu" ghost kind="minimum" size="sm">
          <Icon name="more-vertical" />
        </Button>
        <Menu className={bem('-menu')}>
          <MenuItem
            disabled={isCreateNewPage || isLinkPage}
            icon={<Icon name="clock" />}
            onClick={() => browserRouterHistory.push(`/compare/${routeSection}/${slug}`)}
          >
            History
          </MenuItem>
          <MenuItem disabled={isLinkPage} icon={<Icon name="globe" />} onClick={() => onOpenMetadataModal?.()}>
            Metadata
          </MenuItem>

          {isCustomPage(defaultValues) ? (
            <>
              <MenuDivider />
              <RHFGroup control={control} id={uid('appearance.fullscreen')} name="appearance.fullscreen">
                {({ field: { value, ...field } }) => (
                  <MenuItem icon={<Icon name="layout" />} TagName="label">
                    <Flex align="center" className={bem('-toggle-item')} gap="sm">
                      Full Width
                      <Toggle {...field} checked={value} type="toggle" />
                    </Flex>
                  </MenuItem>
                )}
              </RHFGroup>
            </>
          ) : isGuidesPage(defaultValues) || isReferencePage(defaultValues) ? (
            <>
              <MenuDivider />
              <RHFGroup control={control} id={uid('state')} name="state">
                {({ field }) => (
                  <MenuItem icon={<Icon name="alert-circle" />} TagName="label">
                    <Flex align="center" gap="sm">
                      Deprecated
                      <Toggle
                        {...field}
                        checked={field.value === 'deprecated'}
                        onChange={event => {
                          field.onChange(event.target.checked ? 'deprecated' : 'current');
                        }}
                        type="toggle"
                      />
                    </Flex>
                  </MenuItem>
                )}
              </RHFGroup>
            </>
          ) : null}

          {
            // Raw mode cannot co-occur with HTML mode (for custom pages). When
            // HTML mode is enabled, hide the raw mode toggle entirely.
            !isHtmlMode && (
              <>
                <MenuDivider />
                <MenuItem
                  description={
                    <>
                      Raw markdown is <strong>not validated</strong>. Invalid markdown could break your page
                    </>
                  }
                  disabled={isLinkPage || !isRenderable}
                  TagName="label"
                >
                  <Flex align="center" className={bem('-toggle-item')} gap="sm">
                    Raw Mode
                    <Toggle
                      checked={isRawMode}
                      disabled={!isRenderable}
                      onChange={event => {
                        updateRawMode(event.target.checked);
                      }}
                      type="toggle"
                    />
                  </Flex>
                </MenuItem>
              </>
            )
          }
        </Menu>
      </Dropdown>
    </>
  );
}

export default PageMenu;
