import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useConfirmationDialog } from '@ui/ConfirmationDialog';

interface RouteChangePromptProps {
  /**
   * Callback function to execute when the user confirms the action.
   */
  onConfirm?: () => void;
  /**
   * Whether to show the prompt or not.
   */
  showWhen: boolean;
  /**
   * Manually specify the color theme for the confirmation dialog.
   */
  theme?: 'dark' | 'light';
}

/**
 * A component that prompts the user with a confirmation dialog when they attempt to navigate away from a form
 * and there are unsaved changes.
 *
 * Similar to `react-router-dom`'s Prompt component, but displays the prompt with our `ConfirmationDialog` component.
 */
export default function RouteChangePrompt({ showWhen, theme, onConfirm }: RouteChangePromptProps) {
  const history = useHistory();
  const { confirm, ConfirmationDialog } = useConfirmationDialog();

  useEffect(() => {
    let unblock = () => {};

    if (showWhen) {
      unblock = history.block(transition => {
        confirm({
          bodyText: 'You have unsaved changes. Are you sure you want to leave without saving?',
          confirmText: 'Leave Without Saving',
          headingText: 'Are You Sure?',
        }).then(confirmed => {
          if (confirmed) {
            onConfirm?.();
            // Allow navigation
            unblock();
            history.push(transition);
          }
        });

        return false; // Prevent navigation
      });
    } else {
      unblock();
    }

    return () => unblock(); // Clean up the history block
  }, [confirm, history, onConfirm, showWhen]);

  return (
    <div {...(theme && { 'data-color-mode': theme })}>
      <ConfirmationDialog />
    </div>
  );
}
