import React from 'react';

import useClassy from '@core/hooks/useClassy';

import styles from './index.module.scss';

interface LayoutTitleProps {
  children: React.ReactNode;
}

/**
 * Common layout component for the SuperHub editor form's title field.
 */
export default function LayoutTitle({ children }: LayoutTitleProps) {
  const bem = useClassy(styles, 'SuperHubEditorFormLayoutTitle');
  return <h1 className={bem('&')}>{children}</h1>;
}
