import type { CategoryClientSide } from '@readme/backend/models/category/types';

import React, { useMemo, useRef } from 'react';
import { useDndScrolling } from 'react-dnd-scrolling';

import useClassy from '@core/hooks/useClassy';

import Flex from '@ui/Flex';

import PageNavCategory from './Category';
import { PageNavCategoriesContext, PageNavContext } from './Context';
import PageNavDivider from './Divider';
import DragDropProvider from './DragDrop';
import styles from './index.module.scss';

export interface PageNavProps {
  /**
   * List of all categories that are nested within this PageNav with the
   * exception of "fixed" categories whose actions have been disabled.
   */
  categories?: Pick<CategoryClientSide, '_id' | 'title'>[];

  children?: React.ReactNode;
  className?: string;

  /**
   * Renders elements into a fixed footer that stays anchored at the very bottom
   * of the container.
   */
  footer?: React.ReactNode;

  style?: React.CSSProperties;

  /**
   * Whether navigation is displaying guides, reference or history. Depending on
   * which is enabled, different sections are toggled on and off.
   */
  type: 'guide' | 'history' | 'reference';
}

/**
 * Internals of the `PageNav` component. We need this in a separate subcomponent
 * because `useDndScrolling()` requires `<DragDropProvider>` to be fully mounted
 * before rendering.
 * @private
 */
function PageNavContent({ children, className, footer, style }: PageNavProps) {
  const bem = useClassy(styles, 'PageNav');

  const ref = useRef<HTMLElement>(null);
  useDndScrolling(ref, {
    strengthMultiplier: 50,
  });

  return (
    <nav className={bem('&', className)} style={style}>
      <Flex ref={ref} align="stretch" className={bem('-content')} gap="0" layout="col">
        <Flex align="stretch" className={bem('-children')} gap="0" justify="start" layout="col" tag="ul">
          {children}
        </Flex>
      </Flex>
      {!!footer && (
        <Flex align="center" className={bem('-footer')} gap="0" justify="start" layout="col" tag="footer">
          {footer}
        </Flex>
      )}
    </nav>
  );
}

/**
 * This is the top-level component that renders the navigation bar that users
 * can use to navigate between different documentation pages in their "Guides"
 * or "API Reference" sections.
 *
 * This component provides necessary wrappers and contexts that are referenced
 * and used by the following composable child elements. Thus, each element must
 * be wrapped by this component.
 *
 * - [`PageNavCategory`](/#/Components/Dash/PageNavCategory)<br>
 * - [`PageNavItem`](/#/Components/Dash/PageNavItem)
 * - [`PageNavItemVersion`](/#/Components/Dash/PageNavItemVersion)
 * - [`PageNavDivider`](/#/Components/Dash/PageNavDivider)
 * - [`PageNavCreateNew`](/#/Components/Dash/PageNavCreateNew)
 */
export default React.memo(function PageNav(props: PageNavProps) {
  const { categories, type } = props;
  const memoContextValue = useMemo(() => ({ type }), [type]);

  return (
    <PageNavContext.Provider value={memoContextValue}>
      <PageNavCategoriesContext.Provider value={categories}>
        <DragDropProvider>
          <PageNavContent {...props} />
        </DragDropProvider>
      </PageNavCategoriesContext.Provider>
    </PageNavContext.Provider>
  );
});

export * from './Category';
export * from './CreateNew';
export * from './Divider';
export * from './Item';
export * from './ItemVersion';
export { PageNavCategory };
export { PageNavDivider };
export { default as PageNavCreateNew } from './CreateNew';
export { default as PageNavItem } from './Item';
export { default as PageNavItemVersion } from './ItemVersion';
