import React from 'react';

import CodeSnippet from '@ui/CodeSnippet';

import classes from './style.module.scss';

interface Props {
  onChange?: (value: string) => void;
  value?: string;
}

const HtmlMode = ({ onChange, value }: Props) => {
  // Custom Pages have an HTML Mode that renders a CodeSnippet for editing
  return (
    <>
      <CodeSnippet
        className={classes.HTMLMode_Snippet}
        code={value ?? ''}
        editorProps={{
          onChange: (_, __, data) => {
            onChange?.(data);
          },
        }}
        language="html"
        options={{
          editable: true,
        }}
      />
      <span className={classes.HTMLMode_Hint}>
        <strong>HTML will be sanitized</strong> If you want to include CSS or JavaScript, do so in{' '}
        <em>Appearance &gt; Custom Javascript/Stylesheet</em>.
      </span>
    </>
  );
};

export default HtmlMode;
