import type { RedirectProps } from 'react-router-dom';

import React, { useMemo } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

/**
 * Issues redirects while preserving the current location hash when redirecting
 * to the new path. If hash segment is provided in the `to` prop, then that hash
 * segment will be used, thus overriding the default behavior.
 *
 * Uses React Router's `Redirect` component and supports all the same props.
 *
 * @example
 * ```tsx
 * // /changelog#/appearance/theme-editor -> /docs/page#/appearance/theme-editor
 * <RedirectWithHash to="/docs/page" />
 * ```
 */
export default function RedirectWithHash({ to, ...props }: RedirectProps) {
  const { hash } = useLocation();

  const patchedTo = useMemo(() => {
    if (typeof to === 'string') {
      // When target is a string, we have to parse out pathname, search and hash
      // segments manually so we can reconstruct the location object.
      const url = new URL(to, 'http://stubble');
      return {
        pathname: url.pathname,
        search: url.search,
        hash: url.hash || hash,
      };
    }

    return {
      ...to,
      hash: to.hash || hash,
    };
  }, [hash, to]);

  return <Redirect {...props} to={patchedTo} />;
}
