import type { OASDocument, ParameterObject } from 'oas/types';
import type { OpenAPIV3 } from 'openapi-types';

import produce from 'immer';
import Oas from 'oas';
import React, { useCallback, useMemo } from 'react';

import useValidateParameter from '@core/hooks/useValidateParameter';
import { upperFirst } from '@core/utils/lodash-micro';

import APISectionHeader from '@ui/API/SectionHeader';
import type { CurrentOperation } from '@ui/APIDesigner/OperationEditor';
import TypeMenu from '@ui/APIDesigner/TypeMenu';
import Button from '@ui/Button';
import Dropdown from '@ui/Dropdown';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';

import apiDesignerClasses from '../style.module.scss';

interface SchemaTypes {
  allOtherParameters: string[];
  id: string;
  onChange: (schema: SchemaTypes['schema'], idx: string) => void;
  remove: (idx: string) => void;
  schema: {
    default?: string;
    description?: string;
    format?: string;
    in: ParameterObject['in'];
    name: string;
    required: boolean;
    type: NonNullable<OpenAPIV3.SchemaObject['type']>;
  };
}

const Schema = ({ allOtherParameters, id, onChange, remove, schema }: SchemaTypes) => {
  // @ts-expect-error `onChange` in useValidateParameter requires a `name` property in the schema, which this technically satisfies
  const { parameterName, error, updateName } = useValidateParameter({ allOtherParameters, id, onChange, schema });

  const handleChange = useCallback(
    (e, kind: keyof SchemaTypes['schema']) => {
      const updatedSchema = { ...schema };
      if (kind === 'name') {
        updateName(e.currentTarget.value);
        return;
      }
      if (kind === 'description') updatedSchema.description = e.currentTarget.value;
      if (kind === 'required') updatedSchema.required = !schema.required;
      if (kind === 'default') updatedSchema.default = e.currentTarget.value;
      if (kind === 'type') {
        updatedSchema.type = e.newType.type;
        updatedSchema.format = e.newType.format;
      }

      onChange(updatedSchema, id);
    },
    [id, onChange, schema, updateName],
  );

  return (
    <Flex align="stretch" className={apiDesignerClasses.Parameter} gap="4px" justify="start" layout="col" tag="form">
      <Flex align="stretch" className={apiDesignerClasses['Parameter-group']} gap="0" layout="col">
        <Flex align="baseline" gap="0">
          <Flex align="baseline" gap="2px" justify="between">
            <input
              className={`${apiDesignerClasses['Parameter-input']} ${apiDesignerClasses['Parameter-input_name']}`}
              data-1p-ignore
              onChange={e => handleChange(e, 'name')}
              placeholder="Name"
              spellCheck="false"
              style={{ width: `${schema.name?.length || '7'}ch` }}
              value={parameterName}
            />

            <Dropdown justify="start">
              <span
                className={`${apiDesignerClasses['Parameter-input']} ${apiDesignerClasses['Parameter-input_type']}`}
              >
                {schema.format ? `${schema.type} (${schema.format})` : schema.type}
                <Icon name="chevron-down" />
              </span>
              <TypeMenu
                format={schema.format}
                setNewType={newType => handleChange({ newType }, 'type')}
                type={schema.type || 'string'}
              />
            </Dropdown>

            {schema.in !== 'path' && (
              <Flex
                className={`${apiDesignerClasses['Parameter-input']} ${
                  apiDesignerClasses['Parameter-input_required']
                } ${schema.required ? apiDesignerClasses['Parameter-input_required_checked'] : ''}`}
                gap="xs"
                tag="label"
              >
                <span>required</span>
                <input
                  checked={schema.required}
                  className={apiDesignerClasses['Parameter-input-checkbox']}
                  onChange={e => handleChange(e, 'required')}
                  type="checkbox"
                />
              </Flex>
            )}
          </Flex>
          <Flex align="center" gap="2px">
            <input
              className={`${apiDesignerClasses['Parameter-input']} ${apiDesignerClasses['Parameter-input_form']}`}
              name="default"
              onChange={e => handleChange(e, 'default')}
              placeholder="Default Value"
              value={schema.default}
            />
            {schema.in !== 'path' && (
              <Button
                className={apiDesignerClasses['Parameter-delete']}
                ghost
                kind="destructive"
                onClick={() => remove(id)}
                size="xs"
              >
                <Icon name="trash" />
              </Button>
            )}
          </Flex>
        </Flex>
        <input
          className={apiDesignerClasses['Parameter-description']}
          onChange={e => handleChange(e, 'description')}
          placeholder="Description"
          value={schema.description || ''}
        />
      </Flex>
      {error ? <span className={apiDesignerClasses['Parameter-error']}>{error}</span> : null}
    </Flex>
  );
};

interface ParameterGroupProps {
  currentOperation: CurrentOperation;
  in: ParameterObject['in'];
  oasJSON: OASDocument;
  setOasJSON: (oas: OASDocument) => void;
}

const ParameterGroup = (props: ParameterGroupProps) => {
  const oas = new Oas(props.oasJSON);
  const operation = oas.operation(props.currentOperation.path, props.currentOperation.method);
  const schemas = useMemo(() => operation.schema.parameters || [], [operation.schema.parameters]) as ParameterObject[];

  const addSchema = useCallback(() => {
    const newSchemas = produce(schemas, draft => {
      draft.push({ in: props.in, name: '', schema: { type: 'string' } });
    });
    const newOas = produce(props.oasJSON, draft => {
      draft.paths![props.currentOperation.path]![props.currentOperation.method]!.parameters = newSchemas;
    });
    props.setOasJSON(newOas);
  }, [props, schemas]);

  const removeSchema = useCallback(
    idx => {
      const index = Number(idx);
      const modifiedSchemas = produce(schemas, draft => {
        draft.splice(index, 1);
      });
      const newOas = produce(props.oasJSON, draft => {
        draft.paths![props.currentOperation.path]![props.currentOperation.method]!.parameters = modifiedSchemas;
      });
      props.setOasJSON(newOas);
    },
    [props, schemas],
  );

  const onChange = useCallback(
    (newSchema: SchemaTypes['schema'], idx: string) => {
      // keeps track of which parameter you are editing within the group.
      // ie the 2nd query parameter, the 3rd header parameter, etc.
      const index = Number(idx);

      // convert newSchema to OpenAPIV3_1.ParameterObject
      const newSchemaObject: ParameterObject = {
        name: newSchema.name,
        in: newSchema.in,
        required: newSchema.required,
        description: newSchema.description,
        schema: {
          type: newSchema.type,
          format: newSchema.format,
          default: newSchema.default,
        } as OpenAPIV3.SchemaObject,
      };

      // Since all types of params are mixed together in oas we need to make sure
      // that we edit the correct one, since the index is based on the location
      const modifiedSchemas = produce(schemas, draft => {
        // `counterInLocation` keeps track of how many times a parameter type (query, header)
        // in the `schemas` array matches the one you are editing
        let counterInLocation = 0;
        draft.forEach((param, i) => {
          if (param.in === newSchema.in) {
            if (counterInLocation === index) {
              draft[i] = newSchemaObject;
            }

            counterInLocation += 1;
          }
        });
      });

      const newOas = produce(props.oasJSON, draft => {
        draft.paths![props.currentOperation.path]![props.currentOperation.method]!.parameters = modifiedSchemas;
      });
      props.setOasJSON(newOas);
    },
    [props, schemas],
  );

  let paramsOfType = schemas.filter(param => 'in' in param && param.in === props.in);

  if (props.in === 'path') {
    const expectedPathParams = Array.from(props.currentOperation.path.matchAll(/({([^}]+)})/g), m => m[2]);
    paramsOfType = expectedPathParams.map(param => {
      const pathParam = paramsOfType.find(p => p.name === param);
      return pathParam || { in: 'path', name: param, schema: { type: 'string' } };
    });
  }

  return (
    <section>
      <APISectionHeader heading={`${upperFirst(props.in)} Parameters`}>
        {/* TODO: disable with a tooltip? */}
        {props.in !== 'path' && (
          <Button kind="secondary" onClick={addSchema} outline size="xs">
            <Icon name="plus" />
          </Button>
        )}
      </APISectionHeader>

      <div>
        {paramsOfType.map((param, idx, parameters) => {
          // TODO: yay refs
          const parameterObject = param;
          const paramSchema: SchemaTypes['schema'] = {
            name: parameterObject.name,
            description: parameterObject.description || '',
            required: parameterObject.required || false,
            in: props.in,
            type:
              (parameterObject.schema && 'type' in parameterObject.schema && parameterObject.schema.type) || 'string',
            default:
              (parameterObject.schema && 'default' in parameterObject.schema && parameterObject.schema.default) || '',
            format:
              (parameterObject.schema && 'format' in parameterObject.schema && parameterObject.schema.format) || '',
          };

          const allOtherParameters = parameters.filter((_, i) => i !== idx).map(p => p.name);

          return (
            <Schema
              key={idx.toString()}
              allOtherParameters={allOtherParameters}
              id={idx.toString()}
              onChange={onChange}
              remove={removeSchema}
              schema={paramSchema}
            />
          );
        })}
      </div>
    </section>
  );
};

export default ParameterGroup;
