import React, { createContext, useContext, useMemo, useState } from 'react';

import type { EditorValueProxy } from '@ui/MarkdownEditor/DashEditor';

export interface EditorValueProxyContextValue {
  /**
   * A reference to the DashEditor component's value proxy which can be used to serialize the editor value.
   */
  editorValueProxy: EditorValueProxy | null;
  setEditorValueProxy: React.Dispatch<React.SetStateAction<EditorValueProxy | null>>;
}

export const EditorValueProxyContext = createContext<EditorValueProxyContextValue | null>(null);

/**
 * Provides `EditorValueProxyContext` and makes it available to all children.
 */
export const EditorValueProxyContextProvider: React.FC = ({ children }) => {
  const [editorValueProxy, setEditorValueProxy] = useState<EditorValueProxyContextValue['editorValueProxy']>(null);

  return (
    <EditorValueProxyContext.Provider
      value={useMemo(
        () => ({
          editorValueProxy,
          setEditorValueProxy,
        }),
        [editorValueProxy],
      )}
    >
      {children}
    </EditorValueProxyContext.Provider>
  );
};

/**
 * Provides a reference to the DashEditor component's value proxy which can be used to serialize the editor value.
 * For performance reasons, the DashEditor value is not serialized on every change, but only when the
 * form is submitted.
 */
export function useEditorValueProxyContext() {
  const value = useContext(EditorValueProxyContext);
  if (!value) throw new Error('EditorValueProxyContext.Provider is missing');
  return value;
}
