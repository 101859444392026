import React, { createContext, useContext } from 'react';

import { useConfirmationDialog } from '@ui/ConfirmationDialog';

type FormConfirmationContextValue = ReturnType<typeof useConfirmationDialog>['confirm'];

const FormConfirmationContext = createContext<FormConfirmationContextValue | null>(null);

/**
 * Provides `FormConfirmationContext` and makes it available to all children.
 */
export const FormConfirmationContextProvider: React.FC = ({ children }) => {
  const { confirm, ConfirmationDialog } = useConfirmationDialog();

  return (
    <FormConfirmationContext.Provider value={confirm}>
      {children}
      <div data-color-mode="dark">
        <ConfirmationDialog />
      </div>
    </FormConfirmationContext.Provider>
  );
};

/**
 * Returns the `confirm` function from `useConfirmationDialog` that when invoked
 * presents the user with a confirmation dialog to continue or cancel the
 * pending action.
 * @example
 * ```ts
 * const confirm = useFormConfirmationContext();
 *
 * await confirm({
 *   bodyText: 'Changes were made in another session since you started editing.',
 *   cancelText: 'Go Back',
 *   confirmText: 'Overwrite Changes',
 *   headingText: 'Overwrite Changes',
 *   onConfirmAction: () => handleUpdate(data),
 * })
 * ```
 */
export function useFormConfirmationContext() {
  const value = useContext(FormConfirmationContext);
  if (!value) throw new Error('FormConfirmationContext.Provider is missing');
  return value;
}
