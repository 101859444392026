import type { PageClientSide } from '@readme/backend/models/page/types';
import type { SuggestedEndpointsResponse, SuggestedEndpoint } from '@readme/iso';

import { availableWebhookTargets } from '@readme/metrics-sdk-snippets';
import React, { useCallback, useContext, useMemo } from 'react';

import type { ProjectContextValue } from '@core/context';
import { ProjectContext } from '@core/context';
import useClassy from '@core/hooks/useClassy';
import { InitializeReferenceStore, useProjectStore } from '@core/store';

import authImg from '@routes/APIConfig/Onboarding/images/Authenticate.png';
import introImg from '@routes/APIConfig/Onboarding/images/Intro.png';
import myRequestsImg from '@routes/APIConfig/Onboarding/images/MyRequests.png';
import WebhookCodeSnippet from '@routes/MyDevelopers/Setup/PersonalizedDocs/WebhookCodeSnippet';
import WebhooksSetupContext from '@routes/MyDevelopers/Setup/PersonalizedDocs/WebhooksSetupContext';

import DesignYourExperience from './DesignYourExperience';
import Intro from './Intro';
import classes from './style.module.scss';

interface Props {
  /**
   * The current page being viewed
   * Note: doc.slug is used to determine which onboarding step to show
   */
  doc: PageClientSide;
  endpoints: SuggestedEndpointsResponse;
  initialStep?: number;
  /** Whether component is being rendered within Hub app */
  isHub?: boolean;
  onComplete: (endpoint: SuggestedEndpoint) => void;
}

function APIConfigOnboarding({ doc, endpoints, isHub = false, initialStep = 0, onComplete }: Props) {
  const { project } = useContext(ProjectContext) as ProjectContextValue;
  const projectStoreParent = useProjectStore(store => store.data.parent);
  const languages = availableWebhookTargets().map(target => target.key);

  const bem = useClassy(classes, 'APIConfigOnboarding');

  // If the project has a parent, it's a group project and Intro links should
  // link to the group personalized docs page
  const isSetupInParent = !!projectStoreParent || !!project.parent;

  const handleFinish = useCallback(
    endpoint => {
      onComplete(endpoint);
    },
    [onComplete],
  );

  const isIntro = initialStep === 0;

  const { title, description, heroImgSrc } = useMemo(() => {
    switch (doc?.slug) {
      case 'getting-started':
        return {
          title: 'Getting Started Setup',
          description:
            'Set up the welcome page for users of your API to make their first API call and the endpoints they use.',
          heroImgSrc: introImg,
        };
      case 'authentication':
        return {
          title: 'Authentication Setup',
          description: 'Set up an authentication page for users of your API to access their API keys.',
          heroImgSrc: authImg,
        };
      case 'my-requests':
        return {
          title: 'My Requests Setup',
          description: 'Set up the dashboard for your developers to view their API usage and debug requests.',
          heroImgSrc: myRequestsImg,
        };
      default:
        return {
          title: '',
          description: '',
          heroImgSrc: '',
        };
    }
  }, [doc?.slug]);

  return (
    <InitializeReferenceStore languages={languages} useAllAvailableLanguages={false}>
      <WebhooksSetupContext>
        <section className={bem('&', !isIntro && '_centered', isHub && '_with-padding')}>
          <div className={bem('-left-col')}>
            {isIntro ? (
              <Intro
                description={description}
                isHub={isHub}
                isSetupInParent={isSetupInParent}
                slug={doc?.slug}
                title={title}
              />
            ) : (
              <DesignYourExperience doc={doc} endpoints={endpoints} handleFinish={handleFinish} isHub={isHub} />
            )}
          </div>

          {!!isIntro && (
            <div className={bem('-right-col')}>
              <img alt="" className={bem('-bg', isIntro && '-bg_initial')} src={heroImgSrc} />
              <div
                className={bem('-snippet', isIntro && '-snippet_initial')}
                data-color-mode="dark"
                inert={!isIntro ? undefined : ''}
              >
                <WebhookCodeSnippet />
              </div>
            </div>
          )}
        </section>
      </WebhooksSetupContext>
    </InitializeReferenceStore>
  );
}

export default APIConfigOnboarding;
