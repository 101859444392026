import React from 'react';

import useClassy from '@core/hooks/useClassy';

import APISectionHeader from '@ui/API/SectionHeader';
import Box from '@ui/Box';
import Button from '@ui/Button';
import Flex from '@ui/Flex';
import HTTPStatus from '@ui/HTTPStatus';
import Icon from '@ui/Icon';
import Skeleton from '@ui/Skeleton';

import classes from './style.module.scss';

// These are CSS variable overrides that we'll cast as React.CSSProperties
const tealBG = { '--Skeleton-bg': 'hsla(180, 100%, 81%, 0.6)' } as React.CSSProperties;
const fuschiaBG = { '--Skeleton-bg': 'hsla(271, 100%, 69%, 0.6)' } as React.CSSProperties;
const limeBG = { '--Skeleton-bg': 'hsla(109, 100%, 78%, 0.6)' } as React.CSSProperties;

function CodeExample() {
  const bem = useClassy(classes, 'ConfigBlockImage');

  return (
    <Flex align="stretch" gap="xs" layout="col">
      <Box className={bem('&', '_dark', '_no-padding')}>
        <Flex align="center" className={bem('-heading', '-heading_dark')}>
          <APISectionHeader heading="CURL" />
          <Icon color="white" name="chevron-down" size="md" />
        </Flex>

        <div className={bem('-section')}>
          <APISectionHeader heading="Request" />
          <div className={bem('-skeleton-row')}>
            <Skeleton style={tealBG} width="30px" />
            <Skeleton style={tealBG} width="75px" />
          </div>
          <div className={bem('-skeleton-row')}>
            <Skeleton style={tealBG} width="64px" />
            <Skeleton style={fuschiaBG} width="125px" />
          </div>
          <div className={bem('-skeleton-row')}>
            <Skeleton style={tealBG} width="50px" />
            <Skeleton style={limeBG} width="116px" />
          </div>
          <div className={bem('-skeleton-row')}>
            <Skeleton style={tealBG} width="50px" />
            <Skeleton style={limeBG} width="146px" />
          </div>
        </div>

        <Flex align="center" className={bem('-footer', '-footer_dark')}>
          <Button ghost kind="minimum" size="sm">
            <Icon name="clipboard" />
          </Button>
          <Button>Try It!</Button>
        </Flex>
      </Box>

      <Box className={bem('&', '_no-padding')} kind="rule">
        <Flex align="center" className={bem('-heading')}>
          <APISectionHeader heading="Response" />
          <Button className={bem('-button')} dropdown ghost kind="secondary" size="xs">
            Examples
          </Button>
        </Flex>

        <Flex align="center" gap="md" justify="center" layout="col">
          <span></span>
          <Skeleton width="180px" />
          <Skeleton width="116px" />
          <Flex gap="sm">
            <HTTPStatus status={200} />
            <HTTPStatus status={400} />
          </Flex>
          <span></span>
        </Flex>
      </Box>
    </Flex>
  );
}

export default CodeExample;
