import React from 'react';

import useClassy from '@core/hooks/useClassy';

import styles from './index.module.scss';

interface LayoutHeaderProps {
  children: React.ReactNode;
}

/**
 * Common layout component for the SuperHub editor form header.
 */
export default function LayoutHeader({ children }: LayoutHeaderProps) {
  const bem = useClassy(styles, 'SuperHubEditorFormLayoutHeader');
  return <header className={bem('&')}>{children}</header>;
}
