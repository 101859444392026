import type { PageClientSide } from '@readme/backend/models/page/types';
import type { SuggestedEndpoint, SuggestedEndpointsResponse } from '@readme/iso';

import { WebhookAction } from '@readme/iso';
import React, { useContext, useMemo, useState } from 'react';
import { useWatch } from 'react-hook-form';

import type { ConfigContextValue } from '@core/context';
import { ConfigContext, VersionContext } from '@core/context';
import useClassy from '@core/hooks/useClassy';
import { useReadmeApiNext } from '@core/hooks/useReadmeApi';
import useUniqueId from '@core/hooks/useUniqueId';
import { useProjectStore, useSuperHubStore } from '@core/store';

import ConfigBlock from '@routes/APIConfig/ConfigBlock';
import APIKeyTable from '@routes/APIConfig/ConfigBlock/APIKeyTable';
import Authentication from '@routes/APIConfig/ConfigBlock/Authentication';
import CodeExample from '@routes/APIConfig/ConfigBlock/CodeExample';
import GettingStarted from '@routes/APIConfig/ConfigBlock/GettingStarted';
import MyRecentActivity from '@routes/APIConfig/ConfigBlock/MyRecentActivity';
import MyRequestsPreview from '@routes/APIConfig/MyRequestsPreview';
import MyRequestsBanner from '@routes/APIConfig/MyRequestsPreview/Banner';
import APIConfigOnboarding from '@routes/APIConfig/Onboarding';
import EndpointPicker from '@routes/APIConfig/Onboarding/EndpointPicker';
import WebhookConfigMenu from '@routes/APIConfig/WebhookConfigMenu';
import { normalizeGitDocToMongo } from '@routes/SuperHub/Reference/util';

import DisableOverlay from '@ui/DisableOverlay';
import Flex from '@ui/Flex';
import { RHFGroup } from '@ui/RHF';

import { useSuperHubEditorFormContext } from '../Context';
import Layout, { LayoutBody, LayoutHeader, LayoutTitle } from '../Layout';
import HeaderInput from '../shared/HeaderInput';
import MarkdownEditor from '../shared/MarkdownEditor';
import useFormSetFocus from '../useFormSetFocus';

import styles from './index.module.scss';

/**
 * Form fields specific to the API Config (`api_config`) page type.
 */
export default function APIConfigPage() {
  const bem = useClassy(styles, 'APIConfigPage');
  const uid = useUniqueId('SuperHubEditorFormEndpointPage');
  const { control } = useSuperHubEditorFormContext();

  const { name } = useContext(ConfigContext) as ConfigContextValue;
  const isHub = name === 'Hub';

  const [document, revalidateDocument] = useSuperHubStore(s => [
    s.document.getReferencePageData(),
    s.document.revalidate,
  ]);
  const [webhooks = []] = useProjectStore(store => [store.data.webhooks]);

  const { version } = useContext(VersionContext);

  const isGettingStarted = document?.api_config === 'getting-started';
  const isAuthentication = document?.api_config === 'authentication';
  const isMyRequests = document?.api_config === 'my-requests';

  // For the sake of making compatible with existing endpoint picker selection, we need to normalize the document
  // to legacy format. Post SuperHub GA, we should be able to remove this and refactor `EndpointPicker`
  const legacyDoc = useMemo(() => normalizeGitDocToMongo(document ?? undefined), [document]);

  const privacy = useWatch({ control, name: 'privacy.view' });
  const documentIsHidden = privacy !== 'public';

  const projectWebhookUrl = useMemo(
    () => webhooks?.find(hook => hook.action === WebhookAction.LOGIN)?.url || '',
    [webhooks],
  );

  const [selectedEndpoint, setSelectedEndpoint] = useState<SuggestedEndpoint | null>(null);

  /**
   * Show onboarding if:
   * - User has gone through onboarding and selected an endpoint
   * - User is on Getting Started or Authentication page and hasn't configured an API yet
   * - User is on My Requests page and hasn't configured a webhook yet
   */
  const showOnboarding = useMemo(() => {
    if (selectedEndpoint) return false;

    if (document) {
      if (isAuthentication || isGettingStarted) {
        return !document?.api?.schema;
      } else if (isMyRequests) {
        return documentIsHidden && !projectWebhookUrl;
      }
    }

    return false;
  }, [
    document,
    documentIsHidden,
    isAuthentication,
    isGettingStarted,
    isMyRequests,
    projectWebhookUrl,
    selectedEndpoint,
  ]);

  const initialStep = useMemo(() => {
    // If project, or parent project has a webhook configured
    // skip ahead to selecting an endpoint
    return projectWebhookUrl ? 1 : 0;
  }, [projectWebhookUrl]);

  useFormSetFocus('title');

  const {
    data: endpoints = {
      all: [],
      recommended: [],
    },
  } = useReadmeApiNext<SuggestedEndpointsResponse>(`/versions/${version}/api_config/suggested_endpoints`);

  return (
    <>
      {!!showOnboarding && !!document && (
        <APIConfigOnboarding
          doc={legacyDoc as unknown as PageClientSide}
          endpoints={endpoints}
          initialStep={initialStep}
          isHub={isHub}
          onComplete={endpoint => {
            setSelectedEndpoint(endpoint);
          }}
        />
      )}
      <DisableOverlay ctaText="Complete Setup to Enable" isDisabled={showOnboarding}>
        <Layout
          sidebar={
            !!(isGettingStarted || isAuthentication) && (
              <>
                {!!isGettingStarted && (
                  <ConfigBlock isPreview label="My Recent Activity" menu={<WebhookConfigMenu />} type="webhook">
                    <MyRecentActivity />
                  </ConfigBlock>
                )}
                {!!isAuthentication && (
                  <Flex align="stretch" layout="col">
                    <ConfigBlock isPreview label="Authentication" menu={<WebhookConfigMenu />} type="webhook">
                      <Authentication />
                    </ConfigBlock>
                    <ConfigBlock
                      isPreview
                      label="Code Example"
                      menu={
                        <EndpointPicker
                          doc={legacyDoc as unknown as PageClientSide}
                          endpoints={endpoints}
                          initialEndpoint={selectedEndpoint ?? undefined}
                          onSave={() => {
                            // Revalidate the document after saving the endpoint so we get updated api data for
                            // code snippets in View Mode
                            revalidateDocument();
                          }}
                        />
                      }
                      type="endpoint"
                    >
                      <CodeExample />
                    </ConfigBlock>
                  </Flex>
                )}
              </>
            )
          }
        >
          <LayoutHeader>
            {!!(isGettingStarted || isAuthentication) && (
              <>
                <LayoutTitle>
                  <RHFGroup control={control} id={uid('title')} name="title" required>
                    {({ field }) => <HeaderInput {...field} />}
                  </RHFGroup>
                </LayoutTitle>
                <RHFGroup control={control} id={uid('content-excerpt')} name="content.excerpt">
                  {({ field }) => <HeaderInput {...field} value={field.value || ''} />}
                </RHFGroup>
              </>
            )}
            {!!isMyRequests && <MyRequestsBanner />}
          </LayoutHeader>
          <LayoutBody>
            {/* Config blocks sit above editor for Getting Started and Authentication */}
            {!!(isGettingStarted || isAuthentication) && (
              <>
                <div className={bem('-config-blocks')}>
                  {!!isGettingStarted && (
                    <ConfigBlock
                      isPreview
                      label="Getting Started"
                      menu={
                        <EndpointPicker
                          doc={legacyDoc as unknown as PageClientSide}
                          endpoints={endpoints}
                          initialEndpoint={selectedEndpoint ?? undefined}
                          onSave={() => {
                            // Revalidate the document after saving the endpoint so we get updated api data for
                            // code snippets in View Mode
                            revalidateDocument();
                          }}
                        />
                      }
                      type="endpoint"
                    >
                      <GettingStarted />
                    </ConfigBlock>
                  )}

                  {!!isAuthentication && (
                    <ConfigBlock isPreview label="API Key Table" menu={<WebhookConfigMenu />} type="webhook">
                      <APIKeyTable />
                    </ConfigBlock>
                  )}
                </div>

                <MarkdownEditor />
              </>
            )}
            {!!isMyRequests && (
              <MyRequestsPreview isDocHidden={documentIsHidden} isHub={isHub} showOnboarding={showOnboarding} />
            )}
          </LayoutBody>
        </Layout>
      </DisableOverlay>
    </>
  );
}
