import lodashGet from 'lodash/get';
import React, { useCallback, useMemo } from 'react';
import { useWatch } from 'react-hook-form';

import useClassy from '@core/hooks/useClassy';
import useEventListener from '@core/hooks/useEventListener';
import useUniqueId from '@core/hooks/useUniqueId';
import { useSuperHubStore } from '@core/store';
import { isChangelog, isGuidesPage, isReferencePage } from '@core/store/SuperHub/Document/util';

import Button from '@ui/Button';
import Flex from '@ui/Flex';
import FormGroup from '@ui/FormGroup';
import Icon from '@ui/Icon';
import ImageUploader from '@ui/ImageUploader';
import Input, { formatDateTimeLocal } from '@ui/Input';
import { ModalBody, ModalFooter, ModalHeader } from '@ui/Modal';
import { RHFGroup } from '@ui/RHF';
import Select from '@ui/Select';
import Textarea from '@ui/Textarea';
import Title from '@ui/Title';

import { useSuperHubEditorFormContext } from '../../Context';

import styles from './index.module.scss';
import SelectAdmin from './SelectAdmin';

export const fieldNames = [
  'author.id',
  'allow_crawlers',
  'created_at',
  'metadata.description',
  'metadata.image',
  'metadata.keywords',
  'metadata.title',
  'slug',
] as const;

interface ContentProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function Content({ isOpen, onClose }: ContentProps) {
  const [isCreateNewPage] = useSuperHubStore(s => [s.editor.isCreateNewPage]);
  const bem = useClassy(styles, 'MetadataModal');
  const uid = useUniqueId('MetadataModal');

  const {
    control,
    setValue,
    getValues,
    trigger,
    clearErrors,
    formState: { defaultValues, errors, dirtyFields },
  } = useSuperHubEditorFormContext();

  const isPagePublic = useWatch({ control, name: 'privacy.view' }) === 'public';

  // The initial values of the fields when the metadata modal opens.
  // This is used to reset the modal fields when the modal is dismissed by cicking "Cancel".
  const initialFieldsValues = useMemo(() => (isOpen ? getValues(fieldNames) : null), [getValues, isOpen]);

  const handleCancel = useCallback(() => {
    // Reset any dirty fields within the modal to their initial values when the modal is cancelled.
    if (initialFieldsValues) {
      fieldNames.forEach((fieldName, index) => {
        if (lodashGet(dirtyFields, fieldName)) {
          try {
            setValue(fieldName, initialFieldsValues[index], {
              shouldDirty: true,
            });

            // Clear any field errors that aren't manually set from a server response.
            const fieldError = lodashGet(errors, fieldName);
            if (fieldError && fieldError.type !== 'manual') clearErrors(fieldName);
          } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
          }
        }
      });
    }

    onClose();
  }, [initialFieldsValues, onClose, dirtyFields, setValue, errors, clearErrors]);

  // Trigger validation on the fields within the modal when the OK button is clicked.
  // If there are no validation errors, close the modal.
  const handleOk = useCallback(async () => {
    const isValid = await trigger(fieldNames);
    if (isValid) onClose();
  }, [onClose, trigger]);

  // Add a custom esc key listener to close the modal. Using this instead of the
  // built-in esc key listener because we want to reset the modal fields before closing.
  useEventListener('keydown', (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      handleCancel();
    }
  });

  return (
    <>
      <ModalHeader className={bem('-header')}>
        <Flex gap={0} layout="col">
          <Title className={bem('-header-title')} level={4}>
            Edit SEO Metadata
          </Title>
          <p className={bem('-header-body')}>
            Customize your page metadata, this affects the way content displays for social media and SEO practices
          </p>
        </Flex>
      </ModalHeader>

      <ModalBody className={bem('-body')}>
        <RHFGroup control={control} id={uid('image')} name="metadata.image">
          {({ field }) => {
            return field?.value?.url ? (
              <>
                <div
                  className={bem('-image')}
                  style={{
                    backgroundImage: `url(${field.value.url})`,
                  }}
                />
                <Flex className={bem('-image-controls')} gap="xs" justify="center">
                  <ImageUploader
                    className={bem('-image-controls-upload')}
                    data={field.value && typeof field.value === 'object' ? field.value : undefined}
                    id={field.id}
                    kind="primary"
                    onFinish={data => field.onChange(data)}
                    outline={false}
                    size="sm"
                  >
                    Replace
                  </ImageUploader>
                  <Button
                    kind="secondary"
                    onClick={() => {
                      field.onChange({ uri: null });
                    }}
                    size="sm"
                  >
                    <Icon color="white" name="x" />
                    Delete
                  </Button>
                </Flex>
              </>
            ) : (
              <div className={bem('-image')}>
                <ImageUploader
                  data={field.value && typeof field.value === 'object' ? field.value : undefined}
                  id={field.id}
                  kind="primary"
                  onFinish={data => field.onChange(data)}
                  outline={false}
                  size="sm"
                >
                  <Icon name="upload" />
                  Upload Display Image
                </ImageUploader>
              </div>
            );
          }}
        </RHFGroup>

        <RHFGroup control={control} id={uid('name')} label="SEO Title" name="metadata.title">
          {({ field }) => <Input {...field} placeholder={defaultValues?.title || ''} />}
        </RHFGroup>

        <RHFGroup control={control} id={uid('description')} label="Description" name="metadata.description">
          {({ field }) => (
            <Textarea
              {...field}
              placeholder={
                isGuidesPage(defaultValues) || isReferencePage(defaultValues)
                  ? defaultValues?.content?.excerpt || undefined
                  : undefined
              }
              rows={3}
            />
          )}
        </RHFGroup>

        {isChangelog(defaultValues) ? (
          <div className={bem('&-form-row')}>
            <RHFGroup control={control} id={uid('created_at')} label="Posted At" name="created_at" required>
              {({ field }) => (
                <Input
                  {...field}
                  onChange={e => field.onChange(new Date(e.target.value).toISOString())}
                  type="datetime-local"
                  value={formatDateTimeLocal(field.value)}
                />
              )}
            </RHFGroup>

            <RHFGroup control={control} id={uid('author.id')} label="Posted By" name="author.id" required>
              {({ field }) => <SelectAdmin {...field} isActive={isOpen} value={field.value || ''} />}
            </RHFGroup>
          </div>
        ) : (
          <div className={bem('&-form-row')}>
            <RHFGroup control={control} id={uid('keywords')} label="Keywords" name="metadata.keywords">
              {({ field }) => <Input {...field} placeholder="comma, separated, keywords" />}
            </RHFGroup>

            {isGuidesPage(defaultValues) || isReferencePage(defaultValues) ? (
              <RHFGroup control={control} id={uid('search-visibility')} label="Search Visibility" name="allow_crawlers">
                {({ field }) => (
                  <div>
                    {isPagePublic ? (
                      <Select
                        {...field}
                        options={[
                          { label: '🔍 Indexed by search engines', value: 'enabled' },
                          { label: '🙈 Not indexed by external search (Google, etc)', value: 'disabled' },
                        ]}
                      />
                    ) : (
                      <div>Hidden pages aren&apos;t indexed by search engines</div>
                    )}
                  </div>
                )}
              </RHFGroup>
            ) : (
              <FormGroup label="Search Visibility">
                {isPagePublic ? <div>🔍 Indexed by search engines</div> : <div>🙈 Hidden from search engines</div>}
              </FormGroup>
            )}
          </div>
        )}

        <hr />
        <RHFGroup control={control} id={uid('slug')} label="Slug" name="slug" required={!isCreateNewPage}>
          {({ field }) => <Input {...field} />}
        </RHFGroup>
      </ModalBody>

      <ModalFooter align="center" className={bem('-footer')} justify="around">
        <Flex gap="xs" justify="around">
          <Button className={bem('-close')} kind="secondary" onClick={handleCancel} size="md">
            Cancel
          </Button>
          <Button onClick={handleOk} size="md">
            OK
          </Button>
        </Flex>
      </ModalFooter>
    </>
  );
}
