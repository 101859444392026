import type { PageClientSide } from '@readme/backend/models/page/types';
import type { SuggestedEndpoint, SuggestedEndpointsResponse } from '@readme/iso';

import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';

import { BaseUrlContext } from '@core/context';
import useClassy from '@core/hooks/useClassy';

import Button from '@ui/Button';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Menu from '@ui/Menu';

import EndpointPicker from './EndpointPicker';
import classes from './style.module.scss';

interface Props {
  doc: PageClientSide;
  endpoints: SuggestedEndpointsResponse;
  handleFinish: (endpoint: SuggestedEndpoint) => void;
  /** Whether component is being rendered within Hub app */
  isHub?: boolean;
}

const DesignYourExperience = ({ doc, endpoints, handleFinish, isHub = false }: Props) => {
  const bem = useClassy(classes, 'APIConfigOnboarding');
  const baseUrl = useContext(BaseUrlContext);

  const [isComplete, setIsComplete] = useState(false);
  const [selectedEndpoint, setSelectedEndpoint] = useState<SuggestedEndpoint | null>(null);

  const hasEndpoints = endpoints?.recommended?.length || endpoints?.all?.length;

  return (
    <div>
      <h1 className={bem('-heading', '-heading_centered')}>Design Your Experience</h1>
      <p className={bem('-subheading')}>
        {hasEndpoints ? (
          <>
            Pick an endpoint from your API definition that developers can use to try making an authenticated request. We
            recommend choosing a GET endpoint that doesn’t require parameters.
          </>
        ) : (
          <>
            Pick an endpoint from your API definition that developers can use to try making an authenticated request. If
            you haven’t imported one yet, you can do so{' '}
            {isHub ? (
              <Link className={bem('-link')} to="/update/reference">
                here
              </Link>
            ) : (
              <a href={`${baseUrl}/reference`}>here</a>
            )}
            .
          </>
        )}
      </p>

      <Flex align="stretch" className={bem('-panel-content')} gap="sm" justify="center" layout="col">
        <Menu className={bem('-endpoint-picker')}>
          <EndpointPicker
            doc={doc}
            endpoints={endpoints}
            onSave={endpoint => {
              setSelectedEndpoint(endpoint);
              setIsComplete(true);
            }}
          />
        </Menu>
        <Flex gap="sm" justify="center">
          <Button
            disabled={!isComplete}
            onClick={() => {
              if (selectedEndpoint) {
                handleFinish(selectedEndpoint);
              }
            }}
            size="sm"
          >
            Finish
            <Icon name="check" />
          </Button>
        </Flex>
      </Flex>
    </div>
  );
};

export default DesignYourExperience;
