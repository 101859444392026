import type { PageNavItemProps } from '.';

import React from 'react';

import useClassy from '@core/hooks/useClassy';
import { useSuperHubStore } from '@core/store';

import Icon from '@ui/Icon';

import Tooltip from '../Tooltip';

import styles from './StatusIcon.module.scss';

export interface StatusIconProps {
  className?: string;
  deprecated?: PageNavItemProps['deprecated'];
  status?: PageNavItemProps['status'];
  type?: PageNavItemProps['type'];
}

const hubIcons = {
  apiDefinitions: 'code',
  deprecated: 'alert-circle',
  realtimeAuthentication: 'key',
  realtimeGettingStarted: 'book',
  realtimeIntroduction: 'book',
  realtimeMyRequests: 'gauge-circle',
} as const;

const icons = {
  ...hubIcons,
  changelog: 'rss',
  link: 'arrow-right',
  linkExternal: 'arrow-up-right',
  page: 'file',
  sync: 'file-sync',
} as const;

function StatusIcon({ className, deprecated, status, type }: StatusIconProps) {
  const bem = useClassy(styles, 'PageNavItemStatusIcon');
  const normalizedType = deprecated ? 'deprecated' : type;
  const isSuperHub = useSuperHubStore(s => s.isSuperHub);

  // In the SuperHub editor, we only show icons that the user would see in the Hub's sidebar nav,
  // but in the Dash we show icons for all page types
  const displayIcons = isSuperHub ? hubIcons : icons;

  if (!normalizedType || !displayIcons[normalizedType]) return null;

  let icon = displayIcons[normalizedType];

  const suffix = {
    none: '',
    created: '-plus',
    modified: '',
    deleted: '-minus',
  }[status || 'none'];

  const description = {
    apiDefinitions: 'API Definitions',
    changelog: 'Changelog',
    deprecated: 'Deprecated',
    link: 'Link',
    linkExternal: 'External link',
    page: 'Page',
    realtimeAuthentication: 'Authentication',
    realtimeGettingStarted: 'Getting Started',
    realtimeIntroduction: 'Getting Started',
    realtimeMyRequests: 'My Requests',
    sync: 'Synced',
  }[normalizedType];

  if (icon === 'file' && suffix) {
    icon += suffix;
  }

  return icon ? (
    <Tooltip content={description}>
      <Icon
        className={bem('&', className, `_${status}`, deprecated && '_deprecated')}
        name={icon}
        tabIndex={-1}
        wrapperClassName={bem('-wrapper', deprecated && '-wrapper_deprecated')}
      />
    </Tooltip>
  ) : null;
}

export default React.memo(StatusIcon);
