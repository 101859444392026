import type { ApiDefinitionProps } from '../ApiDefinition';
import type { ChangelogRouteProps } from '../Changelog';
import type { CustomPageRouteProps } from '../CustomPage';
import type { DocRouteProps } from '../Doc';
import type { ReferenceRouteProps } from '../Reference';
import type { HubResponseProps } from '@readme/iso';

import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import useClassy from '@core/hooks/useClassy';
import {
  ConnectSuperHubDocumentToApi,
  ConnectSuperHubSidebarToApi,
  InitializeSuperHubDocument,
  ConnectSuperHubApiDefinitions,
  useSuperHubStore,
} from '@core/store';

import type { SuperHubRouteParams } from '@routes/SuperHub/types';
import Recipes from '@routes/Tutorials';

import Flex from '@ui/Flex';
import Spinner from '@ui/Spinner';

import ApiDefinition from '../ApiDefinition';
import ConnectRoute from '../ConnectRoute';
import SectionGate from '../Layout/PlanAccess/SectionGate';
import RedirectToSidebarFirstPage from '../RedirectToSidebarFirstPage';

import EmptyEditor from './Empty';
import SuperHubEditorForm from './Form';
import styles from './index.module.scss';
import SectionNav from './SectionNav';
import SidebarNav from './SidebarNav';

type SuperHubEditorProps = HubResponseProps<
  ApiDefinitionProps | ChangelogRouteProps | CustomPageRouteProps | DocRouteProps | ReferenceRouteProps
>;

function Content({ apiDefinitions, document: initialDocument, ...props }: SuperHubEditorProps) {
  const bem = useClassy(styles, 'SuperHubEditor');
  const { action, slug, section } = useParams<SuperHubRouteParams>();
  const [isDocumentLoading, isReferenceDefinitionsRoute] = useSuperHubStore(s => [
    s.document.isLoading && s.document.data !== initialDocument,
    s.isReferenceDefinitionsRoute,
  ]);

  /**
   * Indicates we are rendering the editor in an empty state where there are no
   * categories or pages yet created. Recipes handles its own empty state, so we
   * continue rendering and let the recipes component do that.
   */
  const isEmptyState = useMemo(() => {
    // Never show empty states for any actions other than update.
    if (action !== 'update') return false;
    // Never show empty states for recipes b/c we render the legacy component.
    if (section === 'recipes') return false;
    if (section === 'reference') {
      // For reference sections, only show empty state when sidebar is empty.
      // TODO: Figure out how to do this. Until then, disable it entirely.
      // We have to check if any definitions have been created or if any pages
      // exist in the sidebar. If both are true, then redirect to the full
      // EmptyEditor state to then render the "setup" flow.
      return false;
    }
    return !slug;
  }, [action, section, slug]);

  return (
    <Flex align="stretch" className={bem('&', 'rm-Guides')} gap={0} layout="col">
      <SectionNav className={bem('-nav')} />
      {isEmptyState ? (
        <SectionGate contentClassName={bem('-gate-content')} section={section}>
          <EmptyEditor />
        </SectionGate>
      ) : (
        <div className={bem('-container', 'rm-Container rm-Container_flex')}>
          <SectionGate contentClassName={bem('-gate-content')} section={section}>
            {section === 'recipes' ? (
              <Recipes {...props} />
            ) : (
              <>
                <SidebarNav className={bem('-sidebar')} />
                {isDocumentLoading ? (
                  <div className={bem('-spinner')}>
                    <Spinner size="xxl" />
                  </div>
                ) : (
                  <div className={bem('-content')}>
                    {isReferenceDefinitionsRoute ? (
                      <ApiDefinition apiDefinitions={apiDefinitions} />
                    ) : (
                      <SuperHubEditorForm />
                    )}
                  </div>
                )}
              </>
            )}
          </SectionGate>
        </div>
      )}
    </Flex>
  );
}

/**
 * Renders a page document MD editor that allows users to live-update their doc
 * and persist those changes.
 */
export default function SuperHubEditor(props: SuperHubEditorProps) {
  const { section } = useParams<SuperHubRouteParams>();
  const { apiDefinitions: initialDefinitions, document: initialDocument = null } = props;
  const initialSidebar = 'sidebar' in props ? props.sidebar : null;
  const initialCustomBlocks = 'customBlocks' in props ? props.customBlocks : null;

  return (
    <ConnectSuperHubSidebarToApi initialSidebar={initialSidebar}>
      <RedirectToSidebarFirstPage>
        <InitializeSuperHubDocument customBlocks={initialCustomBlocks || undefined} document={initialDocument}>
          <ConnectSuperHubDocumentToApi
            // When we're ready to turn on live syncing, flip this flag to true.
            revalidateOnFocus={false}
          >
            <ConnectSuperHubApiDefinitions disabled={section !== 'reference'} initialDefinitions={initialDefinitions}>
              <ConnectRoute next={Content} props={props} />
            </ConnectSuperHubApiDefinitions>
          </ConnectSuperHubDocumentToApi>
        </InitializeSuperHubDocument>
      </RedirectToSidebarFirstPage>
    </ConnectSuperHubSidebarToApi>
  );
}
