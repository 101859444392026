import React from 'react';

import { useRdmdStore } from '@core/store';
import classy from '@core/utils/classy';
import { htmlBlock } from '@core/utils/magicBlocks';

import RDMD from '@ui/RDMD';

import styles from '../style.module.scss';

interface HtmlBlockProps {
  className?: string;
  html: string;
}

function HtmlBlock({ html, className = '' }: HtmlBlockProps) {
  const [mdx, rdmdOpts] = useRdmdStore(rdmd => [rdmd.mdx, rdmd.opts]);

  return (
    <RDMD className={classy(styles.LandingBlock, styles['LandingBlock-Html'], className)} mdx={mdx} opts={rdmdOpts}>
      {htmlBlock(html)}
    </RDMD>
  );
}

export default HtmlBlock;
