import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useSuperHubStore } from '@core/store';
import { isApiConfigCategory } from '@core/store/SuperHub/Sidebar/util';

import type { SuperHubRouteParams } from '@routes/SuperHub/types';

import { CollapsedStateProvider } from '../CollapsedState';
import { ConfirmDeletionProvider } from '../ConfirmDeletion';

import { ApiReferencePages } from './ApiReferencePages';
import { SidebarNavCategories } from './Categories';
import { SidebarNavErrorStatus } from './ErrorStatus';
import { SidebarNavRoot } from './Root';

export interface SidebarNavTreeProps {
  className?: string;
}

export const SidebarNavTree = React.memo(function SidebarNavTree({ className }: SidebarNavTreeProps) {
  const { section } = useParams<SuperHubRouteParams>();
  const [categories, routeSection] = useSuperHubStore(s => [s.sidebar.data, s.routeSection]);

  /**
   * We want to split the categories so API Config (`api_config`) categories are rendered separately
   * from user defined categories
   */
  const { apiConfigCategory, userCategories } = useMemo(() => {
    return {
      apiConfigCategory:
        categories.find(category => isApiConfigCategory(routeSection, category.title, category.pages)) || null,
      userCategories: categories.filter(category => !isApiConfigCategory(routeSection, category.title, category.pages)),
    };
  }, [categories, routeSection]);

  return (
    <SidebarNavRoot className={className}>
      <SidebarNavErrorStatus />
      {section === 'reference' && <ApiReferencePages apiConfigCategory={apiConfigCategory} />}
      <ConfirmDeletionProvider>
        <CollapsedStateProvider>
          <SidebarNavCategories categories={userCategories} />
        </CollapsedStateProvider>
      </ConfirmDeletionProvider>
    </SidebarNavRoot>
  );
});
