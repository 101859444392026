import React from 'react';

import useClassy from '@core/hooks/useClassy';
import { useSuperHubStore } from '@core/store';

import PageNavItemErrorBadge from '@ui/Dash/PageNav/Item/ErrorBadge';
import Flex from '@ui/Flex';

import styles from './ErrorStatus.module.scss';

export function SidebarNavErrorStatus() {
  const bem = useClassy(styles, 'SidebarNavErrorStatus');
  const totalUnrenderable = useSuperHubStore(s => s.sidebar.totalUnrenderable);

  return totalUnrenderable ? (
    <Flex align="center" className={bem()} gap="xs" justify="start">
      <PageNavItemErrorBadge />
      Pages with invalid MDX ({totalUnrenderable})
    </Flex>
  ) : null;
}
