import type { $TSFixMe } from '@readme/iso';

/*
 * Quickly construct magic block strings
 * for easy use with the <RDMD> component.
 */

export const htmlBlock = (html: string) =>
  typeof html === 'string' ? `[block:html]\n{\n"html":${JSON.stringify(html)}\n}\n[/block]` : '';

export const codeBlock = (data: { codes?: $TSFixMe[] }) =>
  'codes' in data ? `[block:code]\n${JSON.stringify(data)}\n[/block]` : '';
