import { useCallback, useContext, useEffect, useState } from 'react';

import { UserContext } from '@core/context';
import useIsDocumentReady from '@core/hooks/useIsDocumentReady';
import useLocalStorage from '@core/hooks/useLocalStorage';
import { safelyParseJSON, safelyStringifyJSON } from '@core/utils/json';

function useRecoveryData<T>(pageId: string) {
  // Namespace storage keys with the user id so nothing gets crossed between sessions
  const { id: userId } = useContext(UserContext) as {
    id: string;
    mutateUser: () => void;
    permissions: never[];
    user: boolean;
  };
  const storage = useLocalStorage({ prefix: `recovery:${userId}`, encrypt: true });

  const isDocumentReady = useIsDocumentReady();
  const [recoveryData, setRecoveryData] = useState<T | null>(null);

  const storeRecoveryData = useCallback(
    (data: Partial<T>) => {
      const json = safelyStringifyJSON(data);
      if (json !== null) {
        storage.setItem(pageId, json);
      }
    },
    [pageId, storage],
  );

  const clearRecoveryData = useCallback(() => {
    storage.removeItem(pageId);
  }, [pageId, storage]);

  // Check local storage for any recovery data stored for this page,
  useEffect(() => {
    if (!isDocumentReady) return;
    const storedData = storage.getItem(pageId);
    if (storedData) {
      const json = safelyParseJSON(storedData);
      if (json !== null) {
        setRecoveryData(json);
      }
    }
  }, [isDocumentReady, pageId, storage]);

  return { recoveryData, clearRecoveryData, storeRecoveryData };
}

export default useRecoveryData;
