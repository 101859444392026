import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { v4 as uuid } from 'uuid';

import Button from '@ui/Button';
import Modal, { ModalBody, ModalFooter, ModalHeader } from '@ui/Modal';
import Title from '@ui/Title';

import classes from './style.module.scss';

const ErrorModal = ({ modal }) => {
  const close = useCallback(() => modal?.current?.toggle(false), [modal]);
  const id = useMemo(() => `error-modal-${uuid()}`, []);

  return (
    <div className={`${classes.ErrorModal__Container} ModalWrapper`} id={id}>
      <Modal ref={modal} className={classes.ErrorModal} size="md" target={`#${id}`} verticalCenter>
        <ModalHeader className={classes.ErrorModal__Header} toggleClose={close}>
          <Title level={4} style={{ flexGrow: 1 }}>
            <i className={`icon-alert-triangle ${classes.ErrorModal__Icon}`} />
            {' Validation Error'}
          </Title>
        </ModalHeader>
        <ModalBody>
          We had some trouble parsing the markdown! Please fix it before switching to the new editor or saving.
        </ModalBody>
        <ModalFooter>
          <Button onClick={close}>Close</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

ErrorModal.propTypes = {
  modal: PropTypes.shape({
    current: PropTypes.shape({
      toggle: PropTypes.func,
    }),
  }).isRequired,
};

export default ErrorModal;
