import type { GitSidebarCategory, GitSidebarPage } from '@readme/api/src/routes/sidebar/operations/getSidebar';

import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useSuperHubStore } from '@core/store';

import { useConfirmationDialog } from '@ui/ConfirmationDialog';

import { ConfirmDeletionContext } from './Context';

export function ConfirmDeletionProvider({ children }: { children: React.ReactNode }) {
  const [routeSection, deleteCategory, deletePage, findSidebarFirstPage, currentSlug] = useSuperHubStore(s => [
    s.routeSection,
    s.sidebar.deleteCategory,
    s.sidebar.deletePage,
    s.sidebar.findSidebarFirstPage,
    s.document.data?.slug,
  ]);

  const history = useHistory();
  const [pendingDelete, setPendingDelete] = useState<GitSidebarCategory | GitSidebarPage>();
  const { confirm, ConfirmationDialog } = useConfirmationDialog();

  const isPage = pendingDelete && 'type' in pendingDelete;

  const handleDelete = useCallback(async () => {
    if (!pendingDelete) return;

    try {
      if (isPage) {
        await deletePage(pendingDelete);
      } else {
        await deleteCategory(pendingDelete);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }, [deleteCategory, deletePage, isPage, pendingDelete]);

  const handleConfirm = useCallback(async () => {
    const isDeleted = await confirm({
      bodyText: `"${pendingDelete?.title}" ${
        !isPage ? 'and all of its pages' : ''
      } will be permanently deleted. This cannot be undone.`,
      confirmText: 'Delete',
      headingText: `Permanently Delete ${isPage ? 'Page' : 'Category'}?`,
      onConfirmAction: handleDelete,
    });

    if (isDeleted) {
      const firstPageSlug = findSidebarFirstPage({ includeHidden: true })?.slug;

      if (firstPageSlug) {
        // When editing a page and it's the same one we're deleting, we need
        // to navigate off of it to somewhere else. To do this we redirect to
        // the first page that exists in the section.
        if (isPage && pendingDelete?.slug === currentSlug) {
          history.push(`/update/${routeSection}/${firstPageSlug}`);
        }
      } else {
        // When there is no first page found in the sidebar, we assume that we
        // sidebar is empty. For this case, redirect users to an appropriate
        // empty state where they can create pages again.
        //
        // For custom pages, redirect to the "create" action.
        // For guides/reference pages, redirect to "update" empty state.
        const redirectAction = routeSection === 'page' ? 'create' : 'update';
        history.push(`/${redirectAction}/${routeSection}`);
      }
    }

    setPendingDelete(undefined);
  }, [confirm, currentSlug, findSidebarFirstPage, handleDelete, history, isPage, pendingDelete, routeSection]);

  useEffect(() => {
    if (pendingDelete) {
      handleConfirm();
    }
  }, [handleConfirm, pendingDelete]);

  return (
    <ConfirmDeletionContext.Provider
      value={{
        setPendingDelete,
      }}
    >
      {children}
      <div data-color-mode="dark">
        <ConfirmationDialog />
      </div>
    </ConfirmDeletionContext.Provider>
  );
}
