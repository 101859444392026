import type { ReadReferenceType } from '@readme/api/src/mappings/page/reference/types';

export function normalizeGitDocToMongo(data?: ReadReferenceType['data']) {
  if (!data) return undefined;

  return {
    _id: data.uri,
    api: {
      method: data.api.method,
      url: '',
      params: [],
    },
    body: data.content.body ?? undefined,
    deprecated: data.state === 'deprecated',
    excerpt: data.content.excerpt ?? undefined,
    slug: data.slug,
    swagger: {
      path: data.api.path,
    },
    title: data.title,
    type: data.type,
  };
}

export function normalizeGitOasToMongo(data: ReadReferenceType['data']) {
  return {
    _id: data.uri,
    ...data.api.schema,
  };
}
