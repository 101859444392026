import React from 'react';

import useUniqueId from '@core/hooks/useUniqueId';
import { useSuperHubStore } from '@core/store';

import Button from '@ui/Button';
import Dropdown from '@ui/Dropdown';
import Icon from '@ui/Icon';
import Menu, { MenuItem } from '@ui/Menu';
import { RHFGroup } from '@ui/RHF';
import Tooltip from '@ui/Tooltip';

import { useSuperHubEditorFormContext } from '../../Context';

/**
 * Renders additional form menus for custom page documents.
 */
export default function CustomPageMenu() {
  const uid = useUniqueId('CustomPageMenu');
  const { control } = useSuperHubEditorFormContext();
  const isCreateNewPage = useSuperHubStore(s => s.editor.isCreateNewPage);

  return (
    <RHFGroup control={control} id={uid('content-type')} name="content.type">
      {({ field: { onChange, value, ...field } }) =>
        isCreateNewPage ? (
          <Dropdown clickInToClose justify="start" sticky trigger="click">
            <Button kind="secondary" outline size="sm" {...field} disabled={!isCreateNewPage}>
              <Icon name={value === 'html' ? 'file-html' : 'file-text'} />
              {value === 'html' ? 'HTML' : 'Markdown'}
            </Button>
            <Menu>
              <MenuItem
                active={value === 'markdown'}
                icon={<Icon name="file-text" />}
                onClick={() => onChange('markdown')}
              >
                Markdown
              </MenuItem>
              <MenuItem active={value === 'html'} icon={<Icon name="file-html" />} onClick={() => onChange('html')}>
                HTML Mode
              </MenuItem>
            </Menu>
          </Dropdown>
        ) : (
          <Tooltip arrow={false} content="Only editable during creation." delay={[800, 200]} offset={[0, 5]}>
            <span>
              <Button kind="secondary" outline size="sm" {...field} disabled>
                <Icon name={value === 'html' ? 'file-html' : 'file-text'} />
                {value === 'html' ? 'HTML' : 'Markdown'}
              </Button>
            </span>
          </Tooltip>
        )
      }
    </RHFGroup>
  );
}
