import { HTTP_METHOD } from '@readme/iso';
import React, { useMemo } from 'react';

import useClassy from '@core/hooks/useClassy';

import UserAvatar from '@routes/Reference/Realtime/components/UserAvatar';

import APIMethod from '@ui/API/Method';
import APISectionHeader from '@ui/API/SectionHeader';
import Box from '@ui/Box';
import Button from '@ui/Button';
import Flex from '@ui/Flex';
import HTTPStatus from '@ui/HTTPStatus';
import Icon from '@ui/Icon';
import Skeleton from '@ui/Skeleton';

import classes from './style.module.scss';

function MyEndpoints() {
  const bem = useClassy(classes, 'ConfigBlockImage');

  const rows = useMemo(
    () =>
      new Array(3).fill(
        <Flex align="center">
          <Flex align="center" className={bem('-stretch-col')} gap="sm" justify="start">
            <APIMethod fixedWidth type={HTTP_METHOD.GET} />
            <Skeleton width="80px" />
          </Flex>
          <Flex align="center" className={bem('-stretch-col')} gap="sm" justify="end">
            <Skeleton width="30px" />
            <svg fill="none" height="20" viewBox="0 0 82 20" width="82" xmlns="http://www.w3.org/2000/svg">
              <rect fill="#C6CBD3" height="2" rx="1" width="10" y="18" />
              <rect fill="#C6CBD3" height="2" rx="1" width="10" x="12" y="18" />
              <rect fill="#C6CBD3" height="2" rx="1" width="10" x="24" y="18" />
              <rect fill="#C6CBD3" height="2" rx="1" width="10" x="36" y="18" />
              <rect fill="#C6CBD3" height="2" rx="1" width="10" x="48" y="18" />
              <rect fill="#C6CBD3" height="2" rx="1" width="10" x="60" y="18" />
              <rect fill="#C6CBD3" height="20" rx="2" width="10" x="72" />
            </svg>
          </Flex>
        </Flex>,
      ),
    [bem],
  );

  return (
    <div className={bem('-metric-section')}>
      <Flex align="center" className={bem('-heading', '-heading_borderless')}>
        <Flex align="center" gap="xs">
          <UserAvatar />
          <APISectionHeader heading="My Endpoints" />
        </Flex>

        <Button className={bem('-button')} dropdown ghost kind="secondary" size="xs">
          Past Week
        </Button>
      </Flex>
      <Box className={bem('&', '-list')} kind="rule">
        {rows.map((row, i) => (
          <React.Fragment key={`MyEndpoints-row_${i}`}>{row}</React.Fragment>
        ))}
        <Flex align="center" justify="end">
          <Button className={bem('-button_no-padding')} ghost size="sm">
            All Endpoints <Icon name="arrow-right" />
          </Button>
        </Flex>
      </Box>
    </div>
  );
}

function MyRequests() {
  const bem = useClassy(classes, 'ConfigBlockImage');

  return (
    <div className={bem('-metric-section')}>
      <Flex align="center" className={bem('-heading', '-heading_borderless')}>
        <Flex align="center" gap="xs">
          <UserAvatar />
          <APISectionHeader heading="My Requests" />
        </Flex>
        <Button className={bem('-button')} dropdown ghost kind="secondary" size="xs">
          Past Week
        </Button>
      </Flex>
      <Box className={bem('&', '-list')} kind="rule">
        <Flex align="stretch" gap="xs" layout="col">
          <Box kind="rule">
            <svg className={bem('-image')} fill="none" viewBox="0 0 404 84" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M2.5 46.5L69 65L136.5 29.5L202.5 20.5L269 38.5L336 3L402 12"
                stroke="#12CA93"
                strokeLinecap="round"
                strokeWidth="4"
              />
              <path d="M2 82L402 82" stroke="#E95F6A" strokeLinecap="round" strokeWidth="4" />
            </svg>
          </Box>
          <Flex align="center">
            <Flex align="center" className={bem('-stretch-col')} gap="xs" justify="start">
              <HTTPStatus iconOnly status={200} /> <span className={bem('-label')}>Successes</span>
            </Flex>
            <Skeleton width="60px" />
          </Flex>
          <Flex align="center">
            <Flex align="center" className={bem('-stretch-col')} gap="xs" justify="start">
              <HTTPStatus iconOnly status={400} /> <span className={bem('-label')}>Errors</span>
            </Flex>
            <Skeleton width="60px" />
          </Flex>
        </Flex>
        <Flex align="center" justify="end">
          <Button className={bem('-button_no-padding')} ghost size="sm">
            All Requests <Icon name="arrow-right" />
          </Button>
        </Flex>
      </Box>
    </div>
  );
}

function MyRecentRequests() {
  const bem = useClassy(classes, 'ConfigBlockImage');

  const rows = useMemo(
    () =>
      new Array(4).fill(
        <Flex align="center">
          <Flex align="center" className={bem('-stretch-col')} gap="sm" justify="start">
            <HTTPStatus iconOnly status={400} />
            <Skeleton width="20px" />
            <APIMethod fixedWidth type={HTTP_METHOD.GET} />
            <Skeleton width="60px" />
          </Flex>
          <Flex align="center" className={bem('-stretch-col')} gap="sm" justify="end">
            <Skeleton width="80px" />
          </Flex>
        </Flex>,
      ),
    [bem],
  );

  return (
    <div className={bem('-metric-section')}>
      <Flex align="center" className={bem('-heading', '-heading_borderless')}>
        <Flex align="center" gap="xs">
          <UserAvatar />
          <APISectionHeader heading="My Recent Requests" />
        </Flex>
        <Button className={bem('-button')} dropdown ghost kind="secondary" size="xs">
          400 & 500
        </Button>
      </Flex>
      <Box className={bem('&', '-list')} kind="rule">
        {rows.map((row, i) => (
          <React.Fragment key={`MyRecentRequests-row_${i}`}>{row}</React.Fragment>
        ))}
        <Flex align="center" justify="end">
          <Button className={bem('-button_no-padding')} ghost size="sm">
            All Recent Requests <Icon name="arrow-right" />
          </Button>
        </Flex>
      </Box>
    </div>
  );
}

function MyRecentActivity() {
  const bem = useClassy(classes, 'ConfigBlockImage');

  return (
    <Box className={bem('&', '_no-padding')} kind="rule">
      <MyEndpoints />
      <MyRequests />
      <MyRecentRequests />
    </Box>
  );
}

export default MyRecentActivity;
