import React from 'react';

import useClassy from '@core/hooks/useClassy';

import Box from '@ui/Box';
import Flex from '@ui/Flex';

import Authenticate from './images/Authenticate.png';
import LanguagePicker from './images/LanguagePicker.png';
import TryIt from './images/TryIt.png';
import classes from './style.module.scss';

function APIKeyTable() {
  const bem = useClassy(classes, 'ConfigBlockImage');

  return (
    <Box className={bem('&')} kind="rule">
      <Flex align="stretch" gap="xs" layout="col">
        <div>
          <h2 className={bem('-title')}>
            <span>1</span> Pick a Language
          </h2>
          <img alt={'Language Picker'} className={bem('-image')} src={LanguagePicker} />
        </div>
        <div>
          <h2 className={bem('-title')}>
            <span>2</span> Credentials
          </h2>
          <img alt={'Credentials UI'} className={bem('-image')} src={Authenticate} />
        </div>
        <div>
          <h2 className={bem('-title')}>
            <span>3</span> Try It!
          </h2>
          <img alt={'Try It UI'} className={bem('-image')} src={TryIt} />
        </div>
      </Flex>
    </Box>
  );
}

export default APIKeyTable;
