import type { GitSidebarCategory } from '@readme/api/src/routes/sidebar/operations/getSidebar';

import camelCase from 'lodash/camelCase';
import React from 'react';
import { useParams } from 'react-router-dom';

import { useSuperHubStore } from '@core/store';

import type { SuperHubRouteParams } from '@routes/SuperHub/types';

import { PageNavDivider, PageNavItem } from '@ui/Dash/PageNav';

interface ApiReferencePagesProps {
  apiConfigCategory?: GitSidebarCategory | null;
}

export const ApiReferencePages = React.memo(function ApiReferencePages({ apiConfigCategory }: ApiReferencePagesProps) {
  const { slug } = useParams<SuperHubRouteParams>();

  const [isReferenceDefinitionsRoute, routeSection] = useSuperHubStore(s => [
    s.isReferenceDefinitionsRoute,
    s.routeSection,
  ]);

  return (
    <>
      <PageNavItem
        active={isReferenceDefinitionsRoute}
        label="API Definitions"
        showActions={false}
        to="/update/reference"
        type="apiDefinitions"
      />
      <PageNavDivider collapsible={false} includeLine />
      {!!apiConfigCategory && (
        <>
          {apiConfigCategory.pages.map(page => (
            <PageNavItem
              key={page.uri}
              active={page.slug === slug}
              isPrivate={page.hidden}
              label={page.title}
              showActions={false}
              to={`/update/${routeSection}/${page.slug}`}
              type={
                camelCase(`realtime-${page.slug}`) as
                  | 'realtimeAuthentication'
                  | 'realtimeGettingStarted'
                  | 'realtimeMyRequests'
              }
            />
          ))}
          <PageNavDivider collapsible={false} includeLine />
        </>
      )}
    </>
  );
});
