import React, { useCallback } from 'react';

import useClassy from '@core/hooks/useClassy';

import Button from '@ui/Button';
import Icon from '@ui/Icon';

import classes from './style.module.scss';

// Helper function to find the closest scrollable parent
function findClosestScrollableParent(element) {
  let currentElement = element;

  while (currentElement && currentElement !== document.body) {
    const overflow = window.getComputedStyle(currentElement).overflow;
    if (overflow === 'auto' || overflow === 'scroll') {
      return currentElement;
    }

    currentElement = currentElement.parentElement;
  }

  return document.body;
}

interface Props {
  children: React.ReactNode;
  /**
   * Text label for cta button in overlay
   */
  ctaText: string;
  /**
   * Flag used by parent to control whether section is disabled or not
   */
  isDisabled: boolean;
  /**
   * Optionally pass a selector of the parent that should scroll to top when cta is clicked,
   * defaults to `document.body`.
   */
  scrollParent?: string;
}

const DisableOverlay = ({ children, ctaText, isDisabled = false, scrollParent }: Props) => {
  const bem = useClassy(classes, 'DisableOverlay');

  const handleClick = useCallback(() => {
    const element = document.querySelector('[data-testid="disable-overlay"]');
    const targetElement = scrollParent ? document?.querySelector(scrollParent) : findClosestScrollableParent(element);

    targetElement?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [scrollParent]);

  return (
    <div className={bem('&')} data-testid="disable-overlay">
      <div className={bem('-overlay', !isDisabled && '-overlay_hidden')}>
        <Button circular className={bem('-button')} kind="secondary" onClick={handleClick} outline>
          <Icon name="arrow-up" />
          {ctaText}
          <Icon name="arrow-up" />
        </Button>
      </div>
      <div
        aria-disabled={isDisabled}
        className={bem('-content', isDisabled && '-content_disabled')}
        inert={isDisabled ? '' : undefined}
        tabIndex={isDisabled ? -1 : 0}
      >
        {children}
      </div>
    </div>
  );
};

export default DisableOverlay;
