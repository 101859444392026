import React, { useEffect } from 'react';

import { useSuperHubEditorFormContext } from '@routes/SuperHub/Editor/Form/Context';

import { useAPIDesignerStore } from '.';

/**
 * Connects the API Designer form state to the SuperHub editor form state.
 */
export function ConnectFormToAPIDesignerStore({ children }: { children: React.ReactNode }) {
  const [oas, path, method] = useAPIDesignerStore(s => [s.oas, s.operation?.path, s.operation?.method]);
  const { setValue } = useSuperHubEditorFormContext();

  useEffect(() => {
    if (oas) setValue('api.schema', oas, { shouldDirty: true, shouldValidate: true, shouldTouch: true });
  }, [oas, setValue]);

  useEffect(() => {
    if (method) setValue('api.method', method, { shouldDirty: true, shouldValidate: true, shouldTouch: true });
  }, [method, setValue]);

  useEffect(() => {
    if (path) setValue('api.path', path, { shouldDirty: true, shouldValidate: true, shouldTouch: true });
  }, [path, setValue]);

  return <>{children}</>;
}
