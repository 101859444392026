import { useCallback, useState } from 'react';

import useDebounced from '@core/hooks/useDebounced';

interface SchemaTypes {
  allOtherParameters: string[];
  id: string;
  onChange: (schema: SchemaTypes['schema'], idx: string) => void;
  schema: {
    name?: string;
  };
}

/**
 * Validates that the parameter name is unique before updating the schema.
 * Used in the API Designer components.
 */
export default function useValidateParameter({ allOtherParameters, id, onChange, schema }: SchemaTypes) {
  const [parameterName, setParameterName] = useState(schema?.name || '');
  const [error, setError] = useState<string | null>(null);

  const validateAndUpdate = useCallback(
    (value: string) => {
      const updatedSchema = { ...schema };
      const match = allOtherParameters?.includes(value);

      if (!match) {
        updatedSchema.name = value;
        onChange(updatedSchema, id);
      } else {
        setError(`There is already a parameter with the name '${value}'. Please choose a different name.`);
      }
    },
    [id, allOtherParameters, onChange, schema],
  );

  const debouncedValidation = useDebounced<(name: string) => void>(validateAndUpdate, 500);

  const updateName = useCallback(
    (value: string) => {
      setError(null);
      setParameterName(value);
      debouncedValidation(value);
    },
    [debouncedValidation],
  );

  return {
    parameterName,
    error,
    updateName,
  };
}
