import React from 'react';

import { useProjectStore } from '@core/store';
import RedirectWithHash from '@core/utils/RedirectWithHash';

/**
 * Redirects to the project's "first page" that is currently selected in the
 * `Project > Appearance > Site Navigation` settings. Typically, this is either
 * the landing page, guides section or the reference section.
 *
 * For use only on Hub routes, this is useful when you reach a navigational
 * dead-end with nowhere else to redirect to.
 *
 * @example
 * ```ts
 * // No redirect path exists and there is no last page to return to.
 * if (!redirectPath && !lastPage) {
 *   return <RedirectToProjectFirstPage />;
 * }
 * ```
 */
export default function RedirectToProjectFirstPage() {
  const projectFirstPage = useProjectStore(s => s.data.appearance.navigation.first_page);

  switch (projectFirstPage) {
    case 'documentation':
      return <RedirectWithHash to="/docs" />;
    case 'reference':
      return <RedirectWithHash to="/reference" />;
    case 'landing_page':
      return <RedirectWithHash to="/" />;
    default:
      throw new Error('Missing project navigation first page field');
  }
}
