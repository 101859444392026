/* eslint-disable react/jsx-key */
import React from 'react';

import useClassy from '@core/hooks/useClassy';

import Box from '@ui/Box';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Skeleton from '@ui/Skeleton';
import Table from '@ui/Table';

import classes from './style.module.scss';

const rows = [
  [
    <Flex align="center" gap="sm" justify="start">
      <Skeleton width="80px" />
      <code>39s5</code>
      <Icon name="eye" size="md" />
      <Icon name="clipboard" size="md" />
    </Flex>,
    <Flex align="center" gap="sm">
      <Skeleton width="100px" />
    </Flex>,
  ],
  [
    <Flex align="center" gap="sm" justify="start">
      <Skeleton width="80px" />
      <code>938d</code>
      <Icon name="eye" size="md" />
      <Icon name="clipboard" size="md" />
    </Flex>,
    <Flex align="center" gap="sm">
      <Skeleton width="100px" />
      <Icon name="check" size="md" />
    </Flex>,
  ],
];

function APIKeyTable() {
  const bem = useClassy(classes, 'ConfigBlockImage');

  return (
    <Box className={bem('&', '_no-padding')} kind="rule">
      <Table body={rows} head={[['API Key', 'Label']]} style={{ width: '100%' }} />
    </Box>
  );
}

export default APIKeyTable;
