import type { $TSFixMe } from '@readme/iso';

import React from 'react';

import { useRdmdStore } from '@core/store';
import classy from '@core/utils/classy';
import { codeBlock } from '@core/utils/magicBlocks';

import RDMD from '@ui/RDMD';

import styles from '../style.module.scss';

import HtmlBlock from './Html';
import TextBlock from './Text';

interface TextMediaBlockProps {
  className?: string;
  mediaCode: { codes: $TSFixMe[] };
  mediaHTML: string;
  mediaImage: string[];
  mediaType: string;
  side: 'left' | 'right';
  text: string;
  title: string;
}

function TextMediaBlock({
  className,
  mediaCode,
  mediaHTML,
  mediaImage,
  mediaType,
  side,
  text,
  title,
}: TextMediaBlockProps) {
  const [mdx, rdmdOpts] = useRdmdStore(rdmd => [rdmd.mdx, rdmd.opts]);

  return (
    <div
      className={classy(
        styles.LandingBlock,
        styles['LandingBlock-TextMedia'],
        side === 'right' ? styles['LandingBlock-TextMedia_reverse'] : '',
        className,
      )}
    >
      {mediaType === 'html' && <HtmlBlock className={styles['LandingBlock-TextMedia-media']} html={mediaHTML} />}

      {mediaType === 'image' && mediaImage?.length > 0 && (
        // eslint-disable-next-line jsx-a11y/alt-text
        <img className={styles['LandingBlock-TextMedia-media']} src={mediaImage[0]} />
      )}

      {mediaType === 'code' && (
        <RDMD className={styles['LandingBlock-TextMedia-media']} mdx={mdx} opts={rdmdOpts}>
          {codeBlock(mediaCode)}
        </RDMD>
      )}

      {!(title || text) || <TextBlock text={text} title={title} />}
    </div>
  );
}

export default TextMediaBlock;
