import type { PageNavProps } from '.';

import { createContext } from 'react';

export interface PageNavContextValue {
  /**
   * Indicates whether nav is rendering items related to Guides, API Reference
   * or Page History.
   */
  type?: PageNavProps['type'];
}

/**
 * Contains top-level information shared across all `PageNav` elements.
 */
export const PageNavContext = createContext<PageNavContextValue>({
  type: undefined,
});
PageNavContext.displayName = 'PageNavContext';

export type PageNavCategoriesContextValue = PageNavProps['categories'];

/**
 * Contains list of categories to allow items to move to via context menu.
 */
export const PageNavCategoriesContext = createContext<PageNavCategoriesContextValue>([]);
PageNavCategoriesContext.displayName = 'PageNavCategoriesContext';
