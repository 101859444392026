import type { FieldPath } from 'react-hook-form';

import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import type { SuperHubDocumentData } from '@core/store';

import type { SuperHubRouteParams } from '@routes/SuperHub/types';

import { useSuperHubEditorFormContext } from './Context';

/**
 * Attempts to send focus to one of the editor's form field under certain
 * circumstances like when creating new pages.
 */
export default function useFormSetFocus(name: FieldPath<SuperHubDocumentData>) {
  const { action } = useParams<SuperHubRouteParams>();
  const { setFocus } = useSuperHubEditorFormContext();

  // Always set initial focus on the title field.
  useEffect(() => {
    if (action === 'create') {
      setFocus(name);
    }
  }, [action, name, setFocus]);
}
