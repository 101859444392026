import type { ItemDragObject, ItemDropResult, useItemDragProps } from '.';
import type { PageNavCategoryProps, PageNavItemProps } from '..';
import type { DropTargetMonitor } from 'react-dnd';

import React, { useCallback, useEffect, useRef } from 'react';
import { useDrop } from 'react-dnd';

import useClassy from '@core/hooks/useClassy';
import useDebounced from '@core/hooks/useDebounced';
import useDragAndDropContext from '@core/hooks/useDragAndDropContext';

import styles from './ParentDrop.module.scss';

export interface ParentDropHandlerArgs {
  item: ItemDragObject;
  monitor: DropTargetMonitor<ItemDragObject, ItemDropResult>;
}

export interface ParentDropCollected {
  isActive: boolean;
  isOver: boolean;
}

export interface ParentDropProps {
  accept: useItemDragProps['type'];
  canDrop?: (args: ParentDropHandlerArgs) => boolean;
  className?: string;
  drop: (args: ParentDropHandlerArgs) => ItemDropResult;
  id: PageNavCategoryProps['id'] | PageNavItemProps['id'];
  setToggle?: React.Dispatch<React.SetStateAction<boolean>>;
}

/**
 * Renders a single dropzone target that signifies an action to move the
 * dragging item "into" the current row, making the current row now the parent
 * of the dragging item. When dragging an item over a current row that has
 * children, hovering over the current row acts as a toggle to show or hide
 * children. Hiding children lets the dragging item then be moved "after" the
 * parent instead of inside of it.
 */
export default function ParentDrop({
  accept,
  className,
  canDrop: handleCanDrop = () => true,
  drop,
  id,
  setToggle,
}: ParentDropProps) {
  const { id: dndProviderId } = useDragAndDropContext();
  const [{ isActive, isOver }, dropRef] = useDrop<ItemDragObject, ItemDropResult, ParentDropCollected>(
    () => ({
      accept,
      canDrop: (item, monitor) => {
        const isSameDndProvider = item?.dndProviderId === dndProviderId;
        const isSameComponent = item?.id === id;
        return (
          isSameDndProvider &&
          !isSameComponent &&
          handleCanDrop({
            item,
            monitor,
          })
        );
      },
      drop: (item, monitor) => {
        const result = drop({ item, monitor });
        return {
          ...result,
        };
      },
      collect: (monitor: DropTargetMonitor<ItemDragObject, ParentDropCollected>) => {
        return {
          isActive: monitor.canDrop(),
          isOver: monitor.canDrop() && monitor.isOver(),
        };
      },
    }),
    [accept, id, dndProviderId, handleCanDrop, drop],
  );

  const initialToggle = useRef<boolean | undefined>();
  const toggleDelay = 1000;
  const toggle = useCallback(() => {
    setToggle?.(prev => {
      if (initialToggle.current === undefined) {
        initialToggle.current = prev;
      }
      return !prev;
    });
  }, [setToggle]);
  const debounceToggle = useDebounced(toggle, toggleDelay);

  // When dragging is over this target, flip the provided "toggled" state.
  useEffect(() => {
    if (isOver) {
      debounceToggle();
    } else {
      debounceToggle.cancel();
    }
    return () => debounceToggle.cancel();
  }, [isOver, debounceToggle]);

  // After dragging stops, restore the original "toggled" state.
  useEffect(() => {
    if (!setToggle) return;
    if (!isActive && initialToggle.current !== undefined) {
      setToggle(initialToggle.current);
      initialToggle.current = undefined;
    }
  }, [isActive, setToggle]);

  const bem = useClassy(styles, 'ParentDrop');
  return (
    <span
      ref={dropRef}
      className={bem('&', className, isActive && '_active', isOver && '_over', !!setToggle && '_toggleable')}
      style={{ animationDuration: `${toggleDelay * 1.25}ms` }}
    >
      <span className={bem('-center-target')} />
    </span>
  );
}
