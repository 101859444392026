/**
 * Flattens a collection's paginated API response into a single array of items
 * where each item is a page of data
 *
 * @example
 * const response = [{ data: [1, 2, 3] }, { data: [4, 5, 6] }];
 * flattenPaginatedCollection(response); // [1, 2, 3, 4, 5, 6]
 */
export function flattenPaginatedCollection<T>(response: { [key: string]: unknown; data: T[] }[]): T[] {
  return response.flatMap(page => page.data);
}
