import React from 'react';

import classes from './style.module.scss';

interface Props {
  onBlur: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  onChange: (value: string) => void;
  value: string;
}

const RawMode = ({ onBlur, onChange, value }: Props) => {
  // @note: using the strategy here: https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/
  // to get auto-resizing
  return (
    <div className={classes.Raw_Container} data-value={value}>
      <textarea
        className={classes.Raw}
        onBlur={onBlur}
        onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
          const { value: newValue } = event.target;
          onChange(newValue);
        }}
        value={value}
      />
    </div>
  );
};

export default RawMode;
