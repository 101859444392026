import type { ForwardedRef } from 'react';

import React, { forwardRef } from 'react';

import useClassy from '@core/hooks/useClassy';

import type { InputProps } from '@ui/Input';
import Input from '@ui/Input';

import styles from './index.module.scss';

const placeholderByFieldName = {
  title: 'Title',
  'content.excerpt': 'Description (optional)',
};

/**
 * Form input field for the page header, which adjusts styles and other input
 * attributes based on the field name.
 */
function HeaderInput(props: InputProps, ref: ForwardedRef<HTMLInputElement>) {
  const bem = useClassy(styles, 'HeaderInput');
  const { name } = props;

  return (
    <Input
      {...props}
      ref={ref}
      className={bem('&', name && `_field-${name}`)}
      placeholder={name ? placeholderByFieldName[name] : undefined}
    />
  );
}

export default forwardRef(HeaderInput);
