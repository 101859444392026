import type { CollapsedStateContextValue } from './Context';

import React, { useContext, useMemo, useCallback } from 'react';

import type { ProjectContextValue } from '@core/context';
import { ProjectContext } from '@core/context';
import useLocalStorage from '@core/hooks/useLocalStorage';
import { safelyParseJSON, safelyStringifyJSON } from '@core/utils/json';

import { CollapsedStateContext } from './Context';

export interface CollapsedStateLocalStorage {
  collapsedState: Record<string, boolean> | null;
}

export function CollapsedStateProvider({ children }: { children: React.ReactNode }) {
  const {
    project: { _id: projectId },
  } = useContext(ProjectContext) as ProjectContextValue;

  // Initial value for any preferences of expanded/collapsed state for
  // each category or page item in the sidebar
  const storage = useLocalStorage({ prefix: 'sidebarPreferences' });
  const { collapsedState: collapsedStateById } = useMemo(
    () =>
      (safelyParseJSON(storage.getItem(projectId)) || {
        collapsedState: null,
      }) as CollapsedStateLocalStorage,
    [storage, projectId],
  );

  // When category or page items are expanded/collapsed, save the state to local
  // storage so it is the same on the next page load
  const updateCollapsedStateById = useCallback<CollapsedStateContextValue['updateCollapsedStateById']>(
    (id, isOpen) => {
      const json = safelyParseJSON(storage.getItem(projectId)) as CollapsedStateLocalStorage;
      const previousCollapsedState = json?.collapsedState || {};
      const nextCollapsedState = { [id]: isOpen };
      const update = safelyStringifyJSON({
        ...json,
        collapsedState: { ...previousCollapsedState, ...nextCollapsedState },
      });
      if (update !== null) {
        storage.setItem(projectId, update);
      }
    },
    [projectId, storage],
  );

  const memoizedValue = useMemo(
    () => ({
      collapsedStateById,
      updateCollapsedStateById,
    }),
    [collapsedStateById, updateCollapsedStateById],
  );

  return <CollapsedStateContext.Provider value={memoizedValue}>{children}</CollapsedStateContext.Provider>;
}
