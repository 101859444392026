import type { TextBlockProps } from './Text';

import React from 'react';

import classy from '@core/utils/classy';

import styles from '../style.module.scss';

import TextBlock from './Text';

interface ThreeBlockProps {
  className?: string;
  group0: Omit<TextBlockProps, 'className'>;
  group1: Omit<TextBlockProps, 'className'>;
  group2: Omit<TextBlockProps, 'className'>;
}

function ThreeBlock({ group0, group1, group2, className }: ThreeBlockProps) {
  return (
    <div className={classy(styles.LandingBlock, styles['LandingBlock-Three'], className)}>
      <TextBlock {...group0} />
      <TextBlock {...group1} />
      <TextBlock {...group2} />
    </div>
  );
}

export default ThreeBlock;
