import React from 'react';

import useClassy from '@core/hooks/useClassy';
import { useSuperHubStore } from '@core/store';

import BasePanel from '../BasePanel';
import Transition from '../Transition';

import classes from './index.module.scss';

interface AsidePanelProps {
  children: React.ReactNode;
  className?: string;
  /**
   * Screen reader label for the panel.
   */
  label: string;
}

/**
 * Aside panel that appears on the right side of the SuperHub layout.
 * This panel is used for displaying additional information or actions side-by-side with the Hub content.
 */
export default function AsidePanel({ children, className, label }: AsidePanelProps) {
  const bem = useClassy(classes, 'AsidePanel');
  const layout = useSuperHubStore(s => s.layout);

  return (
    <Transition
      className={bem('&', layout === 'aside-standalone' && '_standalone', className)}
      enter={[{ transform: 'translateX(100%)' }, { transform: 'translateX(0)' }]}
      in={layout === 'aside' || layout === 'aside-standalone'}
    >
      <BasePanel label={label}>{children}</BasePanel>
    </Transition>
  );
}
