import React, { useCallback, useRef, useState } from 'react';

import useClassy from '@core/hooks/useClassy';
import useUniqueId from '@core/hooks/useUniqueId';

import type { ButtonProps } from '@ui/Button';
import Button from '@ui/Button';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Input from '@ui/Input';
import Tooltip from '@ui/Tooltip';

import styles from './index.module.scss';
import { getIconNameByFile, mockUpload } from './util';

/**
 * Contains fields describing the file being uploaded but does not include
 * anything about the file upload response. That type is defined by the
 * `onUpload` request handler.
 */
export interface FileUploaderData {
  fileName: string;
}

export interface FileUploaderProps<UploadResponse> {
  /**
   * Upload button label.
   */
  children?: React.ReactNode;
  className?: string;

  /**
   * Allows consumers to explicitly control what file data is currently loaded.
   * Object must include a `fileName` property that represents the name of the
   * file associated with this data. When `preview` is enabled, assigning data
   * will render the preview panel.
   */
  data?: FileUploaderData & UploadResponse;

  /**
   * Hides the button and makes it entirely inaccessible.
   */
  hidden?: boolean;

  /**
   * Renders the provided icon as part of the button. Defaults to `upload`. Set
   * to `null` to remove the icon.
   */
  icon?: string | null;

  /**
   * Called whenever uploaded data changes or is removed. When removed, handler
   * is invoked with `undefined` to signify that no file is set.
   */
  onChange?: (data?: FileUploaderData & UploadResponse) => void;

  /**
   * Called when the `onUpload` handler returns a rejected Promise with the
   * error argument passed along.
   */
  onError?: (error: Error) => void;

  /**
   * Handles the file upload request to the server and returns a Promise. When
   * resolved, the return value is concatenated with `fileName` and passed along
   * to the `onChange` callback. When rejected, the error object is passed along
   * to the `onError` callback.
   *
   * Defaults to a mock upload handler when omitted for testing and demo
   * purposes but should be replaced in a live app.
   *
   * Types for the returned response object here determines the type used by
   * the `data` prop and the `onChange` callback.
   */
  onUpload?: (file: File) => Promise<UploadResponse>;

  /**
   * Called before upload request is initiated.
   */
  onUploading?: (file: File) => void;

  /**
   * When enabled, renders a preview panel of the current file that was either
   * uploaded or set via `data` prop. Exposes controls to "change" or "remove"
   * the current file.
   */
  preview?: boolean;
}

/**
 * Renders a single button that allows users to upload a file to some arbitrary
 * backend based on the `onUpload` request handler function.
 *
 * The upload handler is stubbed out by default but should be overridden with a
 * function that returns a Promise. Depending on whether the Promise resolves or
 * rejects, the `onChange` or `onError` callbacks are invoked with the response
 * payload or error object.
 *
 * When `preview` is enabled, file upload previews are rendered whenever file
 * upload data is present by either explicitly assigning a `data` prop or after
 * a successful `onUpload` event has occurred. A preview panel displays the file
 * name and an interactive control to either discard the current file or select
 * a new one.
 *
 * @example
 * ```tsx
 * <FileUploader
 *    kind="secondary"
 *    onChange={data => console.log(data)}
 *    onUpload={uploadOasFile}
 * />
 * ```
 */
function FileUploader<UploadResponse>({
  children = 'Upload File',
  className,
  data: propData,
  disabled,
  fullWidth,
  ghost,
  hidden,
  icon = 'upload',
  kind,
  onChange,
  onClick,
  onError,
  onUpload,
  onUploading,
  outline,
  preview,
  size,
  ...inputProps
}: FileUploaderProps<UploadResponse> &
  Pick<ButtonProps, 'disabled' | 'fullWidth' | 'ghost' | 'kind' | 'onClick' | 'outline' | 'size'> &
  Pick<React.InputHTMLAttributes<HTMLInputElement>, 'accept' | 'name' | 'value'>) {
  const uid = useUniqueId('FileUploader');
  const bem = useClassy(styles, 'FileUploader');
  const inputRef = useRef<HTMLInputElement>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [data, setData] = useState(propData);

  const handleUpload = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    async e => {
      e.preventDefault();

      const file = e.target.files?.[0];
      if (!file) return;

      setIsUploading(true);
      setData(undefined);
      onUploading?.(file);

      try {
        const response = await (onUpload?.(file) ?? mockUpload());
        const nextData = { ...response, fileName: file.name };
        setData(nextData);
        onChange?.(nextData);
      } catch (err) {
        // When error occurs, restore original data.
        setData(data);
        onError?.(err);
      } finally {
        setIsUploading(false);
      }
    },
    [data, onError, onChange, onUploading, onUpload],
  );

  const handleFileSelect = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      inputRef.current?.click();
      onClick?.(event);
    },
    [inputRef, onClick],
  );

  const handleRemove = useCallback(() => {
    setData(undefined);
    onChange?.(undefined);
  }, [onChange]);

  return (
    <>
      {!!preview && data ? (
        <Flex
          align="stretch"
          className={bem('-preview', fullWidth && '-preview_full-width', size && `-preview_size-${size}`)}
          data-testid="preview"
          gap="0"
          justify="start"
        >
          <Flex
            align="center"
            aria-label="Change"
            className={bem('-preview-control')}
            gap="sm"
            onClick={handleFileSelect}
            tag="button"
          >
            <Icon color="color-text-minimum" name={getIconNameByFile(data.fileName)} size="md" />
            <Tooltip arrow={false} content={data.fileName} delay={[800, 200]} offset={[0, 5]}>
              <span className={bem('-preview-label')}>{data.fileName}</span>
            </Tooltip>
          </Flex>
          <button className={bem('-preview-control', '-preview-control_remove')} onClick={handleRemove}>
            Remove
          </button>
        </Flex>
      ) : (
        <Button
          className={bem('&', className)}
          disabled={disabled}
          fullWidth={fullWidth}
          ghost={ghost}
          hidden={hidden}
          id={uid('upload-button')}
          kind={kind}
          loading={isUploading}
          onClick={handleFileSelect}
          outline={outline}
          size={size}
        >
          {icon ? <Icon name={icon} /> : null}
          {children}
        </Button>
      )}
      <Input
        {...inputProps}
        key={data?.fileName}
        ref={inputRef}
        aria-labelledby={uid('upload-button')}
        className={bem('-input')}
        data-testid="upload-input"
        disabled={disabled}
        hidden
        onChange={handleUpload}
        type="file"
      />
    </>
  );
}

export default FileUploader;
