import type { ItemDragObject, ItemDropResult, useItemDragProps } from '.';
import type { PageNavCategoryProps } from '../Category';
import type { PageNavItemProps } from '../Item';
import type { DropTargetMonitor } from 'react-dnd';

import { useEffect, useState } from 'react';
import { useDrop } from 'react-dnd';

import useDragAndDropContext from '@core/hooks/useDragAndDropContext';

export interface useIsDragItemHoveredProps<CollectedProps> {
  accept: useItemDragProps['type'] | useItemDragProps['type'][];
  collect?: (monitor: DropTargetMonitor<ItemDragObject, ItemDropResult>) => CollectedProps;
  disabled?: boolean;
  elementRef: React.RefObject<HTMLElement>;
  id: PageNavCategoryProps['id'] | PageNavItemProps['id'];
}

/**
 * Registers a light-weight drop target onto the provided element and updates
 * whenever a dragging item is hovered over it. Optionally, additional
 * information from the drop monitor may be collected if needed.
 */
export default function useIsDragItemHovered<CollectedProps = Record<string, never>>({
  accept,
  collect,
  disabled = false,
  elementRef,
  id,
}: useIsDragItemHoveredProps<CollectedProps>) {
  const { id: dndProviderId } = useDragAndDropContext();
  const [enableDrops, setEnableDrops] = useState(false);

  // Always reset hovered state to false when mouse leaves the element.
  useEffect(() => {
    const element = elementRef.current;
    if (!element) return () => {};

    const handleReset = () => setEnableDrops(false);
    element.addEventListener('mouseleave', handleReset);
    return () => element.removeEventListener('mouseleave', handleReset);
  }, [elementRef]);

  const [collected, connect] = useDrop<ItemDragObject, ItemDropResult, CollectedProps>(
    () => ({
      accept,
      hover: (_, monitor) => {
        if (!monitor.canDrop() || enableDrops) return;
        setEnableDrops(true);
      },
      canDrop: item => {
        // Only enable drop targets when item is from the same dnd context, is not
        // the same item as the dragging item, and when actions are enabled.
        const isSameDndProvider = item.dndProviderId === dndProviderId;
        const isSameComponent = item.id === id;
        return isSameDndProvider && !isSameComponent && !disabled;
      },
      collect,
    }),
    [accept, collect, disabled, dndProviderId, enableDrops, id],
  );
  connect(elementRef);

  return {
    isDragItemHovered: enableDrops,
    ...collected,
  };
}
