import type { RouteComponentProps } from 'react-router-dom';

import React from 'react';
import { Link, withRouter } from 'react-router-dom';

import RDMD from '@ui/RDMD';

interface ContainerProps {
  children: React.ReactNode;
  slug?: string;
}

const WithSuggestEditsLink = ({ slug, children }: ContainerProps) => {
  const LinkProps = { to: `/edit/${slug}` };

  return (
    <div className="row clearfix">
      <div className="col-xs-9">{children}</div>
      <div className="col-xs-3">
        <Link {...LinkProps} className="suggestEdits" rel="nofollow">
          <i aria-hidden="true" className="icon icon-edit" /> Suggest Edits
        </Link>
      </div>
    </div>
  );
};

const NoLinks = ({ children }: ContainerProps) => (
  <div className="row clearfix">
    <div className="col-xs-9">{children}</div>
  </div>
);

interface MatchParams {
  path?: string;
}

interface HeaderProps extends RouteComponentProps<MatchParams> {
  excerpt: string;
  lang: string;
  slugUrl?: string;
  suggestedEdits: boolean;
  title?: string;
}

const Header = ({ excerpt, lang, match, slugUrl, suggestedEdits, title }: HeaderProps) => {
  let Container = NoLinks;

  if (suggestedEdits && lang === 'en' && !match?.path?.endsWith('/edit')) {
    Container = WithSuggestEditsLink;
  }

  return (
    <header id="content-head">
      <Container slug={slugUrl}>
        <h1>{title}</h1>
        {!!excerpt && (
          <div className="excerpt">
            <RDMD>{excerpt}</RDMD>
          </div>
        )}
      </Container>
    </header>
  );
};

export default withRouter(Header);
