import React from 'react';

import Icon from '@ui/Icon';
import Menu, { MenuDivider, MenuHeader, MenuItem } from '@ui/Menu';

export type SchemaType = 'array' | 'integer' | 'number' | 'object' | 'string';

const basicTypes = [
  {
    icon: 'string',
    type: 'string',
    displayName: 'string',
  },
  {
    icon: 'integer',
    type: 'integer',
    displayName: 'integer',
  },
  {
    icon: 'binary',
    type: 'boolean',
    displayName: 'boolean',
  },
  {
    icon: 'brackets',
    displayName: 'array',
    type: 'array',
  },
  {
    icon: 'json',
    displayName: 'object',
    type: 'object',
  },
];

const numberTypes = [
  {
    icon: 'life-buoy',
    displayName: 'float',
    type: 'number',
    format: 'float',
  },
  {
    icon: 'double',
    displayName: 'double',
    type: 'number',
    format: 'double',
  },
  {
    icon: '',
    displayName: 'int32',
    type: 'integer',
    format: 'int32',
  },
  {
    icon: '',
    displayName: 'int64',
    type: 'integer',
    format: 'int64',
  },
];

const stringTypes = [
  {
    icon: 'calendar',
    displayName: 'date',
    type: 'string',
    format: 'date',
  },
  {
    icon: 'clock',
    displayName: 'date-time',
    type: 'string',
    format: 'date-time',
  },
  {
    icon: 'asterisk',
    displayName: 'password',
    type: 'string',
    format: 'password',
  },
  {
    displayName: 'byte',
    type: 'string',
    format: 'byte',
  },
  {
    icon: 'binary',
    displayName: 'binary',
    type: 'string',
    format: 'binary',
  },
];

interface TypeMenuProps {
  format?: string;
  setNewType: ({ type, format }: { format?: string; type: string }) => void;
  type: string;
}

/**
 * Sets the correct type/format for the selected type. For simplicity we
 * combined them into one menu as opposed to making it two different things that
 * user sets.
 */
const TypeMenu = ({ setNewType, type, format }: TypeMenuProps) => {
  return (
    <Menu>
      {basicTypes.map(t => (
        <MenuItem
          key={t.displayName}
          active={type === t.type && !format}
          icon={<Icon name={t.icon || 'blank'} />}
          onClick={() => setNewType({ type: t.displayName })}
        >
          {t.displayName}
        </MenuItem>
      ))}
      <MenuDivider />
      <MenuHeader>Number Format</MenuHeader>
      {numberTypes.map(t => (
        <MenuItem
          key={t.displayName}
          active={type === t.type && format === t.format}
          icon={<Icon name={t.icon || 'blank'} />}
          onClick={() => setNewType({ type: t.type, format: t.format })}
        >
          {t.displayName}
        </MenuItem>
      ))}
      <MenuDivider />
      <MenuHeader>String Format</MenuHeader>
      {stringTypes.map(t => (
        <MenuItem
          key={t.displayName}
          active={type === t.type && format === t.format}
          icon={<Icon name={t.icon || 'blank'} />}
          onClick={() => setNewType({ type: t.type, format: t.format })}
        >
          {t.displayName}
        </MenuItem>
      ))}
    </Menu>
  );
};

export default TypeMenu;
