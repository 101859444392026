import type { ForwardedRef } from 'react';

import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';

import useClassy from '@core/hooks/useClassy';
import useUniqueId from '@core/hooks/useUniqueId';

import Modal from '@ui/Modal';

import { useSuperHubEditorFormContext } from '../../Context';

import Content, { fieldNames } from './Content';
import styles from './index.module.scss';

function MetadataModal(_props: unknown, forwardedRef: ForwardedRef<Modal | null>) {
  const bem = useClassy(styles, 'MetadataModal');
  const uid = useUniqueId('MetadataModal');
  const modalRef = useRef<Modal | null>(null);
  useImperativeHandle(forwardedRef, () => modalRef.current as Modal);

  // Generate a unique ID for the modal root container.
  const modalTargetId = uid('modal-root');

  const [isOpen, setIsOpen] = useState(false);
  const {
    formState: { errors, isSubmitting },
  } = useSuperHubEditorFormContext();

  const handleClose = useCallback(() => {
    modalRef.current?.toggle(false);
  }, []);

  // Intentionally not memoizing this computed value because the form state's
  // "errors" object does not trigger an update when changed.
  const hasMetadataModalError = Object.keys(errors).some(key =>
    fieldNames.includes(key as (typeof fieldNames)[number]),
  );

  // Opens the modal if a metadata field contains an error.
  useEffect(() => {
    if (hasMetadataModalError) {
      modalRef.current?.toggle(true);
    }
  }, [hasMetadataModalError]);

  // Ensure modal closes when form is submitted via hotkey while modal is open.
  useEffect(() => {
    if (isSubmitting) {
      modalRef.current?.toggle(false);
    }
  }, [isSubmitting]);

  return (
    <>
      <Modal
        ref={modalRef}
        className={bem()}
        noDismiss
        onClose={() => setIsOpen(false)}
        onOpen={() => setIsOpen(true)}
        size="lg"
        target={`#${modalTargetId}`}
        verticalCenter
      >
        <Content isOpen={isOpen} onClose={handleClose} />
      </Modal>
      <div className="ModalWrapper" id={modalTargetId} />
    </>
  );
}

export default forwardRef(MetadataModal);
export { fieldNames as metadataFieldNames } from './Content';
