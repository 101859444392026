import type { OnPageFormDataChange } from '../ContentEditor';

import React from 'react';

import Button from '@ui/Button';
import Dropdown from '@ui/Dropdown';
import Icon from '@ui/Icon';
import Menu, { MenuItem } from '@ui/Menu';

interface Props {
  isHidden: boolean;
  isParentHidden?: boolean;
  updateFormData: OnPageFormDataChange;
}

const PublishMenu = ({ isHidden, isParentHidden, updateFormData }: Props) => {
  return (
    <Dropdown clickInToClose justify="start" sticky trigger="click">
      <Button data-testid="hidden-toggle" disabled={isParentHidden} dropdown kind="secondary" outline size="sm">
        <Icon name={isHidden ? 'eye-off' : 'eye'} />
        <span>{isHidden ? 'Hidden' : 'Public'}</span>
      </Button>
      <Menu>
        <MenuItem
          active={!isHidden}
          data-testid="public-menuitem"
          onClick={() => {
            updateFormData('hidden', false);
          }}
          role="menuitem"
        >
          Public
        </MenuItem>
        <MenuItem
          active={isHidden}
          data-testid="hidden-menuitem"
          description="Anyone with link can access"
          onClick={() => {
            updateFormData('hidden', true);
          }}
          role="menuitem"
        >
          Hidden
        </MenuItem>
      </Menu>
    </Dropdown>
  );
};

const MemoizedPublishMenu = React.memo(PublishMenu);

export default MemoizedPublishMenu;
