import React, { useContext } from 'react';

import { ProjectContext } from '@core/context';
import useClassy from '@core/hooks/useClassy';
import useUserPermissions from '@core/hooks/useUserPermissions';

import Button from '@ui/Button';
import Dropdown from '@ui/Dropdown';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Menu from '@ui/Menu';

import classes from './style.module.scss';

interface Props {
  className?: string;
  /** Optional prop that adds a Preview and eye icon for indicating this is a preview of real component */
  isPreview?: boolean;
  /** Optional prop that adds a label to the top of the block */
  label?: string;
  /** Menu component that will be rendered in the top right corner of the block */
  menu: React.ReactNode;
  /** Type of configuration block */
  type?: 'endpoint' | 'webhook';
}

const ConfigBlock: React.FC<Props> = ({ children, className, label, menu, isPreview, type }) => {
  const bem = useClassy(classes, 'ConfigBlock');

  const { project } = useContext(ProjectContext);
  const { isGroupAdminUser } = useUserPermissions();

  return (
    <div className={bem('&', className)} data-testid="config-block">
      <Flex align="center">
        <Dropdown justify="start">
          <Button
            disabled={!!project.parent && !isGroupAdminUser && type === 'webhook'}
            kind="minimum"
            outline
            size="sm"
          >
            <Icon name="settings" />
          </Button>
          {(!project?.parent || !!isGroupAdminUser || type === 'endpoint') && (
            <Menu className={bem('-menu')}>{menu}</Menu>
          )}
        </Dropdown>

        {!!label && <span className={bem('-label')}>{label}</span>}
      </Flex>

      {isPreview ? (
        <div className={bem('-overlayContainer')} data-testid="config-block-overlay">
          {/* Overlay to indicate this is a preview */}
          <div className={bem('-overlay')}>
            <Button circular className={bem('-overlayPreviewBtn')} kind="secondary">
              <Icon name="eye" />
              Preview
            </Button>
          </div>
          <figure inert="">{children}</figure>
        </div>
      ) : (
        <figure inert="">{children}</figure>
      )}
    </div>
  );
};

export default ConfigBlock;
