import React, { useContext } from 'react';

import type { ProjectContextValue } from '@core/context';
import { ProjectContext } from '@core/context';
import useClassy from '@core/hooks/useClassy';
import { useSuperHubStore } from '@core/store';

import PageNavItemErrorBadge from '@ui/Dash/PageNav/Item/ErrorBadge';
import Flex from '@ui/Flex';

import styles from './index.module.scss';

interface LayoutBodyProps {
  children: React.ReactNode;
}

/**
 * Common layout component for the SuperHub editor form body.
 */
export default function LayoutBody({ children }: LayoutBodyProps) {
  const bem = useClassy(styles, 'SuperHubEditorFormLayoutBody');
  const { project } = useContext(ProjectContext) as ProjectContextValue;
  const [isRenderable, documentData] = useSuperHubStore(s => [
    (s.document.getGuidesPageData() || s.document.getReferencePageData())?.renderable?.status ?? true,
    s.document.data,
  ]);

  return (
    <main className={bem()}>
      {!isRenderable && !!documentData && (
        <Flex align="center" className={bem('-error-status')} gap="xs" justify="start">
          <PageNavItemErrorBadge />
          <span>
            <strong>Error Rendering Page: </strong>
            Check MDX for JSX syntax errors. Need help?{' '}
            <a
              className={bem('-error-status-link')}
              href={`mailto:support+mdx@readme.com?subject=MDX Migration Issue [page:${documentData.slug}:${project._id}]&body=We're having trouble fixing the page "${documentData.title}" after attempting to migrate the project "${project.name}" to MDX.`}
              rel="noreferrer"
              target="_blank"
            >
              Ask support
            </a>
          </span>
        </Flex>
      )}
      {children}
    </main>
  );
}
