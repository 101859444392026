import React, { useContext, useMemo } from 'react';

import { ProjectContext } from '@core/context';
import useClassy from '@core/hooks/useClassy';

import Button from '@ui/Button';
import Icon from '@ui/Icon';

import myRequestsPreviewImg from '../Onboarding/images/MyRequestsPreview.png';

import styles from './style.module.scss';

interface Props {
  /** Aligns with doc.hidden (published state) */
  isDocHidden?: boolean;
  /** Whether component is being rendered within Hub app */
  isHub?: boolean;
  showOnboarding: boolean;
}

export default function MyRequestsPreview({ isDocHidden = false, isHub = false, showOnboarding = false }: Props) {
  const bem = useClassy(styles, 'MyRequestsPreview');
  const { project } = useContext(ProjectContext);

  const linkProps = useMemo(() => {
    if (isDocHidden) return {};

    // In Hub, this component will only ever be shown in SuperHub Edit Mode so we can route based on that routing
    if (isHub) {
      return {
        href: '/reference/my-requests',
        target: '_self',
      };
    }

    // For non-SuperHub projects, this component should (eventually) only ever be shown in Dash so we can use the /go/ route to old route
    return {
      target: '_blank',
      href: `/go/${project.subdomain}?redirect=/reference/intro/my-requests`,
    };
  }, [isDocHidden, isHub, project?.subdomain]);

  return (
    <div className={bem('&')}>
      <img alt="Example of My Requests" src={myRequestsPreviewImg} />

      {!showOnboarding && !isHub && (
        <Button circular className={bem('-btn')} disabled={isDocHidden} kind="secondary" outline {...linkProps}>
          {!isDocHidden ? 'View in Your Docs' : 'Publish to view in Your Docs'}
          <Icon name="arrow-up-right" />
        </Button>
      )}
    </div>
  );
}
