import { useContext } from 'react';

import { ConfirmDeletionContext } from './Context';

export function useConfirmDeletionContext() {
  const value = useContext(ConfirmDeletionContext);
  if (!value) {
    throw new Error('ConfirmDeletionProvider is missing.');
  }
  return value;
}

export * from './Provider';
