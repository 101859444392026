import loadable from '@loadable/component';
import React, { useContext, useEffect } from 'react';

import { AppMetaContext, ProjectContext } from '@core/context';
import useClassy from '@core/hooks/useClassy';

import logoDefault from './logo.svg';
import classes from './style.module.scss';

const CustomPage = loadable(() => import('@routes/CustomPage'), { ssr: true });

export default function PageNotFound(props) {
  const { project } = useContext(ProjectContext);
  const { updateAppMeta, ...AppMeta } = useContext(AppMetaContext);
  const { error404, appearance } = project;
  const [logo] = appearance?.logo || [logoDefault];
  const bem = useClassy(classes, 'PageNotFound');

  useEffect(() => {
    if (!error404) {
      updateAppMeta({ ...AppMeta, title: '404 Not Found', type: '404' });
    }
  }, [error404, updateAppMeta]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!error404) {
    return (
      <div className={bem('&', 'rm-Container')}>
        <img alt="" className={bem('-logo')} src={logo} />
        <h2 className={bem('-title')}>Page Not Found</h2>
      </div>
    );
  }
  return <CustomPage {...props} />;
}
