import type { GitSidebarCategory, GitSidebarPage } from '@readme/api/src/routes/sidebar/operations/getSidebar';
import type React from 'react';

import { createContext } from 'react';

export interface ConfirmDeletionContextValue {
  setPendingDelete: React.Dispatch<React.SetStateAction<GitSidebarCategory | GitSidebarPage | undefined>>;
}

/**
 * Exposes a setter that allows consumers of this context to set a Category or
 * Page model as being in a pending delete status.
 */
export const ConfirmDeletionContext = createContext<ConfirmDeletionContextValue | undefined>(undefined);
ConfirmDeletionContext.displayName = 'ConfirmDeletionContext';
