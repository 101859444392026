import React from 'react';
import { useParams } from 'react-router-dom';

import { useSuperHubStore } from '@core/store';

import type { SuperHubRouteParams } from '@routes/SuperHub/types';

import { PageNavItem } from '@ui/Dash/PageNav';

import { changelogCategory, customPageCategory } from './Category';

export interface SidebarNavNewPageProps {
  /**
   * Parent category title or page slug of this ephemeral page.
   */
  parent: string;
}

export const SidebarNavNewPage = React.memo(function SidebarNavNewPage({ parent }: SidebarNavNewPageProps) {
  const { routeParentCategory, routeParentPage, section } = useParams<SuperHubRouteParams>();
  const isCreateNewPage = useSuperHubStore(s => s.editor.isCreateNewPage);

  // Only render when new page flag is enabled.
  if (!isCreateNewPage) return null;

  // Only render if parent category or page matches at least one valid parent.
  if (![routeParentCategory, routeParentPage, customPageCategory, changelogCategory].includes(parent)) return null;

  // When parent is a category, only render if no parent page segment exists
  // because that would signify a new sub-page instead.
  const parentIsCategory = [routeParentCategory, customPageCategory, changelogCategory].includes(parent);
  if (parentIsCategory && routeParentPage) return null;

  const subject = section === 'changelog' ? 'Post' : 'Page';

  return (
    <PageNavItem
      active
      isPrivate
      label={parentIsCategory ? `New ${subject}` : `New Sub${subject.toLowerCase()}`}
      showActions={false}
      type="changelog"
    />
  );
});
