import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';

import type { ConfigContextValue } from '@core/context';
import { BaseUrlContext, ConfigContext, ProjectContext } from '@core/context';
import useClassy from '@core/hooks/useClassy';

import Icon from '@ui/Icon';
import { MenuItem, MenuHeader } from '@ui/Menu';

import classes from './style.module.scss';

function WebhookConfigMenu() {
  const bem = useClassy(classes, 'WebhookConfigMenu');

  const baseUrl = useContext(BaseUrlContext);
  const { project } = useContext(ProjectContext);
  const { name } = useContext(ConfigContext) as ConfigContextValue;
  const isHub = name === 'Hub';

  return (
    <>
      <MenuHeader>Webhooks Configuration</MenuHeader>
      {!project?.parent ? (
        <MenuItem
          className={bem('-label')}
          color="blue"
          {...(isHub
            ? {
                onClick: () => {
                  window.location.hash = '/content/personalized-docs';
                },
              }
            : {
                TagName: NavLink,
                to: `${baseUrl}/metrics/setup-api-keys`,
              })}
        >
          <span>Change Server URL</span>
          <Icon name="arrow-right" />
        </MenuItem>
      ) : (
        <MenuItem
          className={bem('-label')}
          color="blue"
          href={`/group/${project.parent.subdomain}/personalized-docs`}
          TagName="a"
          target="_blank"
        >
          Change Server URL
        </MenuItem>
      )}
    </>
  );
}

export default WebhookConfigMenu;
