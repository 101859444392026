import React from 'react';
import { useParams } from 'react-router-dom';

import type { SuperHubRouteParams } from '@routes/SuperHub/types';

export const DisabledStateContext = React.createContext<boolean>(false);

export interface DisabledStateContextProviderProps {
  children: React.ReactNode;
}

/**
 * Provides a context for the disabled state of the sidebar.
 */
export default function DisabledStateContextProvider({ children }: DisabledStateContextProviderProps) {
  const { action } = useParams<SuperHubRouteParams>();
  const isDisabled = action === 'compare';
  return <DisabledStateContext.Provider value={isDisabled}>{children}</DisabledStateContext.Provider>;
}

export function useDisabledStateContext() {
  return React.useContext(DisabledStateContext);
}
