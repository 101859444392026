import type { CollapsedStateLocalStorage } from './Provider';

import { createContext } from 'react';

export interface CollapsedStateContextValue {
  collapsedStateById: CollapsedStateLocalStorage['collapsedState'];
  updateCollapsedStateById: (id: string, isOpen: boolean) => void;
}

/**
 * Captures information about whether categories or parent pages are collapsed
 * or not. Saved in local storage, we use this remember and restore the user's
 * last collapsed states.
 */
export const CollapsedStateContext = createContext<CollapsedStateContextValue | undefined>(undefined);
CollapsedStateContext.displayName = 'CollapsedStateContext';
