import React from 'react';

import useClassy from '@core/hooks/useClassy';

import Icon from '@ui/Icon';
import Tooltip from '@ui/Tooltip';

import classes from './index.module.scss';

interface DisabledSectionBadgeProps {
  className?: string;
}

/**
 * Used on a Hub section nav item to indicate when the Hub section is disabled.
 */
export default function DisabledSectionBadge({ className }: DisabledSectionBadgeProps) {
  const bem = useClassy(classes, 'DisabledSectionBadge');
  return (
    <Tooltip arrow={false} content="Only visible to admins" placement="bottom">
      <span aria-label="Section Disabled" className={bem('&', className)}>
        <Icon color="white" name="eye-off" />
      </span>
    </Tooltip>
  );
}
