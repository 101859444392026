import { superHubStore } from '@core/store';

/**
 * Creates a key which is used to store and retrieve a document's recovery data in local storage.
 * The key will be unique to the current project, parent category/page and whether the document is being
 * created or updated.
 *
 * @example
 * createRecoveryKey('category', 'page');
 * // => 'subdomain-category-page-create'
 * createRecoveryKey();
 * // => 'subdomain-slug-update'
 */
export function createRecoveryKey(parentCategory?: string, parentPage?: string) {
  const { subdomain, editor, document } = superHubStore.getState();
  const slug = document.data?.slug;
  const createOrUpdate = editor.isCreateNewPage ? 'create' : 'update';

  return [subdomain, parentCategory, parentPage, slug, createOrUpdate].filter(Boolean).join('-');
}
