import React, { useContext, useMemo } from 'react';

import type { ProjectContextValue } from '@core/context';
import { ProjectContext, VersionContext } from '@core/context';
import useClassy from '@core/hooks/useClassy';
import useUserPermissions from '@core/hooks/useUserPermissions';

import Badge from '@ui/Badge';
import Button from '@ui/Button';
import InfoBert from '@ui/Dash/InfoBert';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import { MenuItem } from '@ui/Menu';

import classes from './style.module.scss';

interface Props {
  description: string;
  /** Whether component is being rendered within Hub app */
  isHub?: boolean;
  isSetupInParent: boolean;
  slug: string;
  title: string;
}

const Intro = ({ title, description, isHub, slug, isSetupInParent }: Props) => {
  const bem = useClassy(classes, 'APIConfigOnboarding');
  const { project } = useContext(ProjectContext) as ProjectContextValue;
  const { version } = useContext(VersionContext);
  const { isGroupAdminUser } = useUserPermissions();

  const isMyRequests = slug === 'my-requests';

  const externalSetupBtnAttrs = useMemo(() => {
    // For group projects, disable the button if the user is not a group admin
    // or link to the group personalized docs page
    if (isSetupInParent) {
      return !isGroupAdminUser
        ? { disabled: true }
        : { href: `/group/${project?.parent?.subdomain}/personalized-docs`, target: '_blank' };
    }

    // In SuperHub, link to Setup API Keys (Personalized Docs) page
    if (isHub) {
      return {
        onClick: () => {
          window.location.hash = '/content/personalized-docs';
        },
      };
    }

    return { to: `/project/${project?.subdomain}/v${version}/metrics/setup-api-keys` };
  }, [isHub, isSetupInParent, project, version, isGroupAdminUser]);

  const exampleURL = useMemo(() => {
    return slug ? `https://docs.readme.com/main/reference/intro/${slug}` : 'https://docs.readme.com/main/reference';
  }, [slug]);

  return (
    <div className={bem('-intro')}>
      <div>
        {isHub ? (
          <Badge allCaps className={bem('-badge', '-badge_my-developers')} kind="success">
            <Icon className={bem('-badge-icon')} name="users" /> My Developers
          </Badge>
        ) : (
          <Badge allCaps className={bem('-badge')} kind="callout">
            Code
          </Badge>
        )}
        <h1 className={bem('-heading')}>
          {title}
          {!!(isHub || !!isMyRequests) && (
            <Badge allCaps ghost kind="info">
              <Icon className={bem('-badge-icon')} color="yellow" name="sparkles" />
              New
            </Badge>
          )}
        </h1>
        <p className={bem('-subheading')}>{description}</p>
        <Flex align="stretch" className={bem('-panel-content')} gap="sm" justify="start" layout="col">
          <Flex gap="sm">
            <Button fullWidth size="sm" {...externalSetupBtnAttrs}>
              {isSetupInParent ? 'Configure' : 'Start Setup'}
              {!!isSetupInParent && <Icon name="arrow-up-right" />}
            </Button>
            <Button
              fullWidth
              href={
                isSetupInParent
                  ? 'https://docs.readme.com/ent/docs/getting-started-authentication-setup'
                  : 'https://docs.readme.com/docs/reference-core-pages'
              }
              outline
              size="sm"
              target="_blank"
            >
              Read Docs
              <Icon name="arrow-up-right" />
            </Button>
          </Flex>
          {!isMyRequests && (
            <InfoBert>
              {!isSetupInParent || !!isGroupAdminUser ? (
                <span>ReadMe uses webhooks to display important information to your users when they log in.</span>
              ) : (
                <span>
                  Your <b>Group Admins</b> can help set up{' '}
                  <a
                    href="https://docs.readme.com/docs/personalized-docs#hooking-it-all-up-%F0%9F%AA%9D"
                    rel="noreferrer"
                    target="_blank"
                  >
                    webhooks
                  </a>
                  .
                </span>
              )}
            </InfoBert>
          )}
        </Flex>
      </div>
      <MenuItem
        className={bem('-intro-footer')}
        color="blue"
        href={exampleURL}
        icon="icon icon-globe"
        TagName="a"
        target="_blank"
      >
        See example on ReadMe’s docs
      </MenuItem>
    </div>
  );
};

export default Intro;
