import { format } from 'date-fns';
import React from 'react';

import useClassy from '@core/hooks/useClassy';

import Flex from '@ui/Flex';
import Timestamp from '@ui/Timestamp';

import Tooltip from '../Tooltip';

import styles from './index.module.scss';
import StatusIcon from './StatusIcon';

export interface PageNavItemVersionProps {
  /**
   * Marks the item as active or selected. This typically means the current item
   * is what's currently navigated to and being displayed.
   */
  active?: boolean;

  /**
   * Full name of the user who made changes in this version.
   */
  author?: string;

  /**
   * Additional class names to tack onto the root element.
   */
  className?: string;

  /**
   * Called when navigational link is clicked on. Event is cancelable to prevent
   * default behavior.
   */
  onNavigate?: (e: React.MouseEvent<HTMLButtonElement>) => void;

  /**
   * Status indicates whether this version contains changes that either created,
   * deleted or modified content. When left `undefined`, the type of changes
   * made are left "unknown".
   */
  status?: 'created' | 'deleted' | 'modified' | 'none';

  /**
   * Timestamp indicates when this version was created. When left `undefined`,
   * the current timestamp (i.e. "Now") is used.
   */
  timestamp?: Date | string;
}

export default React.memo(function PageNavItemVersion({
  active = false,
  author,
  className,
  onNavigate,
  status = 'none',
  timestamp,
}: PageNavItemVersionProps) {
  const bem = useClassy(styles, 'PageNavItemVersion');

  return (
    <Flex align="stretch" className={bem('&', className, active && '_active')} gap="0" layout="col" tag="li">
      <Flex align="center" className={bem('-link')} gap="0" justify="start" onClick={onNavigate} tag="button">
        <StatusIcon status={status} />
        <span className={bem('-timestamp')}>
          <Timestamp
            className={bem('-timestamp')}
            formatter={date => (timestamp ? format(date, 'MM/dd hh:mmaaa') : 'Now')}
            value={timestamp ?? new Date()}
          />
        </span>
        <Tooltip content={author}>
          <span className={bem('-author')}>{author}</span>
        </Tooltip>
      </Flex>
    </Flex>
  );
});
