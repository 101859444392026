import type { HomeRouteProps } from '..';
import type { HubResponseProps } from '@readme/iso';

import startCase from 'lodash/startCase';
import React, { useContext, useEffect } from 'react';

import type { ProjectContextValue } from '@core/context';
import { EnterpriseParentContext, ProjectContext } from '@core/context';
import useRoute from '@core/hooks/useRoute';
import classy from '@core/utils/classy';

import * as blocks from './Blocks';
import styles from './style.module.scss';

const LandingPageBlocks = blocks;
const getBlock = ({ type }: ProjectContextValue['project']['landing_bottom'][0]) =>
  LandingPageBlocks?.[`${startCase(type).replace(' ', '')}Block`];

function LandingPage(props: HubResponseProps<HomeRouteProps>) {
  const { state, loading } = useRoute(props);
  const {
    project: { landing_bottom: sections = [] },
  } = useContext(ProjectContext) as ProjectContextValue;
  const enterprise = useContext(EnterpriseParentContext);

  useEffect(() => {
    const $body = document.getElementsByTagName('body')[0];
    const cname = 'hub-is-home';
    $body.classList.add(cname);
    return () => $body.classList.remove(cname);
  }, []);

  // Render the GLP
  if (enterprise.isParent) {
    return <div dangerouslySetInnerHTML={{ __html: enterprise.lpMarkup }} />;
  }

  // Render nothing when in a loading state.
  if (loading) return null;

  // Render the LP CMS Template
  return (
    <main className={classy(styles.LandingWrap, 'rm-LandingPage')} id="content">
      {sections.map((section, index) => {
        const BlockType = getBlock(section);
        const alignment = section?.alignment ? styles[`LandingBlock_align=${section.alignment}`] : '';
        return BlockType ? (
          <BlockType key={`${section.type}-${index}`} className={alignment} {...state} {...section} />
        ) : null;
      })}
    </main>
  );
}

export default LandingPage;
