import useProjectPlan from '@core/hooks/useProjectPlan';

import type { SuperHubRouteParams } from '@routes/SuperHub/types';

type ProjectModuleSection = 'graphql' | 'landing';

/**
 * Determines if the specified route section is allowed on the current plan.
 * @example
 * ```ts
 * const isGuidesAllowed = useSectionFeature('docs');
 * ```
 */
export default function useSectionFeature(section: ProjectModuleSection | SuperHubRouteParams['section']) {
  const { planFeatures } = useProjectPlan();
  if (!section) return false;

  /** Hash map indicating whether a route section is allowed access. */
  const planAccessMap = {
    changelog: planFeatures.changelogs,
    discuss: planFeatures.discussions,
    docs: planFeatures.guides,
    home: planFeatures.landing_page,
    landing: planFeatures.landing_page,
    page: planFeatures.custom_pages,
    recipes: planFeatures.recipes,
    reference: true,
    graphql: true,
  } as const satisfies Record<typeof section, boolean>;

  return planAccessMap[section];
}
