import type { Path } from 'react-hook-form';

import React, { useEffect } from 'react';

import { useSuperHubStore, type SuperHubDocumentData } from '@core/store';

import { useSuperHubEditorFormContext } from './Context';

/**
 * Connects the SuperhubEditor RHF to our SuperHubEditor store by propagating
 * any errors in the store into the form.
 */
export default function ConnectFormToSuperHubEditorStore({ children }: { children?: React.ReactNode }) {
  const saveError = useSuperHubStore(s => s.document.saveError);
  const { setError } = useSuperHubEditorFormContext();

  useEffect(() => {
    // Manually set errors from the API response to the form state.
    saveError?.errors?.forEach(({ key, message }) => {
      const name = key as unknown as Path<SuperHubDocumentData>;
      setError(name, { type: 'manual', message });
    });
  }, [saveError, setError]);

  return <>{children}</>;
}
