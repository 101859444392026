import type { SuperHubRouteParams } from './types';

import { findFirstContentfulChild, isEmptyParentPage } from '@readme/iso';
import React from 'react';
import { useParams } from 'react-router-dom';

import { superHubSidebarTypes, useSuperHubStore } from '@core/store';
import RedirectWithHash from '@core/utils/RedirectWithHash';

/**
 * Determines if the current page is an empty parent page. If so, attempts to
 * redirect to the first contentful child page. This logic replicates what we do
 * in our hub controllers.
 */
const RedirectToEmptyParentChild: React.FC = ({ children }) => {
  const { action, section, slug } = useParams<SuperHubRouteParams>();
  const [findSidebarPageBySlug, isLoading, sidebarKey] = useSuperHubStore(s => [
    s.sidebar.findSidebarPageBySlug,
    s.sidebar.isLoading,
    s.sidebar.swrKey,
  ]);

  // Only redirect to empty parent child pages when in "view" mode.
  const isViewMode = !action;
  const isPage = section && slug;

  if (isViewMode && isPage) {
    /**
     * Indicates whether the sidebar for the current section has been loaded.
     * Only then, can we proceed to find the first page to redirect to.
     */
    const isSidebarLoaded = !!sidebarKey?.[0].endsWith(superHubSidebarTypes[section] || '') && !isLoading;

    if (isSidebarLoaded) {
      const page = findSidebarPageBySlug(slug);
      if (page && isEmptyParentPage(page)) {
        const firstChild = findFirstContentfulChild(page.pages);
        if (firstChild) {
          return <RedirectWithHash to={`/${section}/${firstChild.slug}`} />;
        }
      }
    }
  }

  return <>{children}</>;
};

export default RedirectToEmptyParentChild;
