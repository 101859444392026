import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import useClassy from '@core/hooks/useClassy';
import useDebouncedLoadingState from '@core/hooks/useDebouncedLoadingState';
import useEnvInfo from '@core/hooks/useEnvInfo';
import useUniqueId from '@core/hooks/useUniqueId';
import type { ProjectState } from '@core/store';
import { useProjectStore } from '@core/store';

import { getDirtyValues } from '@routes/SuperHub/Settings/Form/Project/utils';

import Button from '@ui/Button';
import type { DropdownRef } from '@ui/Dropdown';
import Dropown from '@ui/Dropdown';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Input from '@ui/Input';
import Menu, { MenuDivider, MenuItem } from '@ui/Menu';
import { RHFGroup } from '@ui/RHF';
import Spinner from '@ui/Spinner';
import Toggle from '@ui/Toggle';
import Truncate from '@ui/Truncate';

import classes from './index.module.scss';

const MAX_ALIAS_LENGTH = 25;

interface DisplaySettingsFormProps {
  /**
   * Whether the form appears in an active section
   */
  isActive: boolean;
  /**
   * The section to configure settings for
   */
  section: 'changelog' | 'docs' | 'recipes' | 'reference';
}

export default function DisplaySettingsForm({ section, isActive }: DisplaySettingsFormProps) {
  const bem = useClassy(classes, 'SectionNav');
  const uid = useUniqueId('SectionNav');
  const dropdownRef = React.useRef<DropdownRef>(null);
  const [sections, save, isSaving] = useProjectStore(s => [s.data.appearance.navigation.links, s.save, s.isSaving]);
  const { isClient } = useEnvInfo();

  const {
    control,
    handleSubmit,
    formState: { dirtyFields, isSubmitting, isValidating, errors },
    reset,
  } = useForm<ProjectState['appearance']['navigation']['links']>({
    defaultValues: sections,
    mode: 'onChange',
  });

  const debouncedIsSaving = useDebouncedLoadingState(isSaving);
  const projectSection = section === 'docs' ? 'guides' : section;
  const { label, alias } = sections[projectSection];

  const onSubmit = handleSubmit(async data => {
    const dirtyValues = getDirtyValues(dirtyFields, data);
    await save({
      appearance: {
        navigation: {
          links: {
            ...dirtyValues,
          },
        },
      },
    });
    reset(data);
  });

  // Automatically save when the visibility changes
  useEffect(() => {
    if (dirtyFields[projectSection]?.visibility && !isSubmitting && !isValidating) {
      onSubmit();
    }
  }, [dirtyFields, projectSection, onSubmit, isSaving, isSubmitting, isValidating]);

  return (
    <Dropown
      ref={dropdownRef}
      appendTo={isClient ? document.body : 'parent'}
      className={bem('-itemSettings', isActive && '-itemSettings_active')}
      justify="center"
      onBlur={() => onSubmit()}
      sticky
    >
      <Button circular kind={isActive ? 'primary' : 'minimum'} size="xs" text>
        <Icon name="more-vertical" />
      </Button>
      <form onSubmit={onSubmit}>
        <Menu className={bem('-itemSettings-menu')} data-color-mode="dark" theme="dark">
          {debouncedIsSaving ? <Spinner className={bem('-itemSettings-loading')} /> : null}

          <MenuItem disabled={debouncedIsSaving} focusable={false}>
            <RHFGroup
              control={control}
              helpMessage="Customize this section’s name across your site."
              id={uid('displayName')}
              label={<Flex>Display Name </Flex>}
              maxLength={MAX_ALIAS_LENGTH}
              name={`${projectSection}.alias`}
              size="sm"
            >
              {({ field }) => (
                <Input
                  {...field}
                  autoComplete="off"
                  disabled={debouncedIsSaving}
                  onKeyDown={event => {
                    if (event.key === 'Escape') {
                      reset();
                    }
                    if (event.key === 'Enter' && !errors[projectSection]) {
                      event.preventDefault();
                      dropdownRef.current?.toggle(false);
                    }
                  }}
                  placeholder={label}
                  size="sm"
                />
              )}
            </RHFGroup>
          </MenuItem>
          <MenuDivider />
          <MenuItem disabled={debouncedIsSaving}>
            <div className={bem('-itemSettings-toggle')}>
              <Icon name="eye" />
              <Truncate>Show {alias || label}</Truncate>
              <RHFGroup control={control} id={uid('visibility')} name={`${projectSection}.visibility`}>
                {({ field }) => (
                  <Toggle
                    {...field}
                    checked={field.value === 'enabled'}
                    disabled={debouncedIsSaving}
                    onChange={event => {
                      field.onChange(event.target.checked ? 'enabled' : 'disabled');
                    }}
                    type="toggle"
                  />
                )}
              </RHFGroup>
            </div>
          </MenuItem>
        </Menu>
      </form>
    </Dropown>
  );
}
