import type { PageNavItemVersionProps } from '.';

import React from 'react';

import useClassy from '@core/hooks/useClassy';

import Icon from '@ui/Icon';

import Tooltip from '../Tooltip';

import styles from './StatusIcon.module.scss';

export interface PageNavItemVersionStatusIconProps {
  className?: string;
  status: NonNullable<PageNavItemVersionProps['status']>;
}

/**
 * Icon names hash with status as its key.
 */
const iconsByStatus = {
  none: 'circle',
  created: 'plus-circle',
  deleted: 'minus-circle',
  modified: 'circle',
};

/**
 * Tooltip descriptions hash with status as its key.
 */
const descriptionsByStatus = {
  none: 'Contains changes',
  created: 'Contains additions',
  deleted: 'Contains deletions',
  modified: 'Contains additions and deletions',
};

export default function StatusIcon({ className, status }: PageNavItemVersionStatusIconProps) {
  const bem = useClassy(styles, 'PageNavItemVersionStatusIcon');

  const icon = iconsByStatus[status];
  const description = descriptionsByStatus[status];

  return (
    <Tooltip content={description}>
      <Icon className={bem('&', className, status && `_${status}`)} name={icon} />
    </Tooltip>
  );
}
