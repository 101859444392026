import type { ForwardedRef } from 'react';

import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';

import useClassy from '@core/hooks/useClassy';
import useUniqueId from '@core/hooks/useUniqueId';

import type { ButtonProps } from '@ui/Button';
import Button from '@ui/Button';
import Modal, { ModalBody } from '@ui/Modal';
import Title from '@ui/Title';

import styles from './style.module.scss';

interface ConfirmationDialogProps {
  /**
   * Callback to be called when the user clicks the cancel button.
   */
  onCancel?: () => void;
  /**
   * Callback to be called when the user clicks the confirm button.
   * If this callback returns a promise, the modal will not close until the promise resolves.
   */
  onConfirm?: () => Promise<void> | void;
}

export interface ConfirmationDialogRef {
  isOpen?: boolean;
}

/**
 * A subset of the ButtonProps that can be used to modify the appearance of the
 * confirm and cancel buttons in the ConfirmationDialog component.
 */
type ButtonConfigurationProps = Pick<ButtonProps, 'ghost' | 'kind' | 'outline' | 'text'>;

/**
 * Props that can be passed to the ConfirmationDialog component
 * via the useConfirmationDialog hook.
 */
export interface BoundConfirmationDialogProps {
  /**
   * Text rendered in the body of the dialog.
   */
  bodyText?: string;
  /**
   * Props to be passed to the cancel button.
   */
  cancelButtonProps?: ButtonConfigurationProps;
  /**
   * Text rendered in the cancel button label.
   */
  cancelText?: string;
  /**
   * Props to be passed to the confirm button.
   */
  confirmButtonProps?: ButtonConfigurationProps;
  /**
   * Text rendered in the confirm button label.
   */
  confirmText?: string;
  /**
   * Text rendered in the header of the dialog.
   */
  headingText?: string;
  /**
   * Whether the dialog should be open initially.
   */
  initialOpen?: boolean;

  /**
   * Optional target ID for the modal to attach dialog to.
   */
  modalTargetId?: string;
}

/**
 * A dialog that can be used to allow a user to confirm/cancel before performing an action.
 */
function ConfirmationDialog(
  {
    headingText = 'Are you sure?',
    cancelText = 'Cancel',
    confirmText = 'Yes',
    bodyText,
    confirmButtonProps = {
      kind: 'destructive',
    },
    cancelButtonProps = {
      kind: 'minimum',
      text: true,
    },
    onCancel,
    onConfirm,
    initialOpen = false,
    modalTargetId,
  }: BoundConfirmationDialogProps & ConfirmationDialogProps,
  forwardedRef: ForwardedRef<ConfirmationDialogRef>,
) {
  const bem = useClassy(styles, 'ConfirmationDialog');
  // Generate a unique ID for the modal root container.
  const uid = useUniqueId('ConfirmationDialog');

  const modalRef = useRef<Modal>(null);
  useImperativeHandle(forwardedRef, () => ({
    get isOpen() {
      return modalRef.current?.state.open;
    },
  }));

  const defaultModalTargetId = uid('modal-root');

  const [isConfirming, setIsConfirming] = useState(false);

  useEffect(() => {
    modalRef.current?.toggle(initialOpen);
  }, [modalRef, initialOpen]);

  const handleConfirm = useCallback(async () => {
    setIsConfirming(true);
    await onConfirm?.();
    modalRef.current?.toggle(false);
    setIsConfirming(false);
  }, [onConfirm]);

  const handleCancel = useCallback(() => {
    onCancel?.();
    modalRef.current?.toggle(false);
  }, [onCancel]);

  return (
    <>
      <Modal
        ref={modalRef}
        className={bem()}
        data-testid="confirmation-dialog"
        noDismiss={!isConfirming}
        size="sm"
        target={`#${modalTargetId || defaultModalTargetId}`}
        verticalCenter
      >
        <ModalBody className={bem('-body')}>
          <Title as={2} className={bem('-header')} level={4}>
            {headingText}
          </Title>
          {!!bodyText && <p className={bem('-message')}>{bodyText}</p>}
        </ModalBody>
        <div className={bem('-footer')}>
          <Button
            {...cancelButtonProps}
            className={bem('-footer-button')}
            disabled={isConfirming}
            onClick={handleCancel}
          >
            {cancelText}
          </Button>
          <Button
            {...confirmButtonProps}
            className={bem('-footer-button')}
            disabled={isConfirming}
            loading={isConfirming}
            onClick={handleConfirm}
            size="md"
            type="button"
          >
            {confirmText}
          </Button>
        </div>
      </Modal>
      {!modalTargetId && <div className="ModalWrapper" id={defaultModalTargetId} />}
    </>
  );
}

export default forwardRef(ConfirmationDialog);

export { default as useConfirmationDialog } from './useConfirmationDialog';
